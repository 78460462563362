import { borders } from "../borders.ts"
import { forms } from "../forms.ts"
import { spacing } from "../spacing.ts"
import { typography } from "../typography.ts"
import { ui } from "../ui.ts"

export default {
	Button: {
		fontFamily: typography.fontFamily,
		borderRadius: borders.radiusXLarge,

		smallHeight: forms.inputHeightSmallMedium,
		smallFontSize: typography.fontSizeMedium,
		smallPaddingHorizontal: spacing.mediumSmall,
		smallPaddingTop: spacing.xSmall,
		smallPaddingBottom: spacing.xSmall,

		mediumHeight: forms.inputHeightMedium,
		mediumFontSize: typography.fontSizeMedium,
		mediumPaddingHorizontal: spacing.mediumSmall,
		mediumPaddingTop: spacing.smallMedium,
		mediumPaddingBottom: spacing.smallMedium,

		lineHeight: typography.lineHeight,

		iconSizeMedium: typography.fontSizeXLarge,
		iconTextGap: spacing.xxSmall,

		primaryColor: ui.textSurfaceColored,
		primaryBorderColor: "transparent",
		primaryBackground: ui.surfaceInversePrimary,
		primaryHoverBackground: ui.surfaceInverseSecondary,
		primaryActiveBackground: ui.surfaceInverseSecondary,
		primaryActiveBoxShadow: null,

		secondaryColor: ui.textSurfaceColored,
		secondaryBorderColor: "transparent",
		secondaryBackground: ui.surfaceAttention,
		secondaryHoverBackground: ui.surfaceAttentionSecondary,
		secondaryActiveBackground: ui.surfaceAttentionSecondary,
		secondaryActiveBoxShadow: null,
	},
}
