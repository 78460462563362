import { CONVERSATION_SCOPES } from "@/features/inbox/constants.ts"
import {
	Flex,
	RadioInput,
	RadioInputGroup,
	ScreenReaderContent,
	View,
} from "@instructure/ui"

type ScopeSelectorProps = {
	scope: CONVERSATION_SCOPES
	setScope: (scope: CONVERSATION_SCOPES) => void
}

export const ScopeSelector = ({ scope, setScope }: ScopeSelectorProps) => {
	const capitalizeFirstLetter = (word: string) =>
		`${word[0].toUpperCase()}${word.slice(1)}`
	return (
		<View
			as="div"
			className="flex-container flex-align-center"
			width="100%"
			height="100%"
		>
			<Flex.Item>
				<RadioInputGroup
					data-testid="mailbox-select"
					onChange={(_event, data) => setScope(data as CONVERSATION_SCOPES)}
					name="scopeSelectorRadio"
					description={
						<ScreenReaderContent>Mailbox selector</ScreenReaderContent>
					}
					variant="toggle"
					value={scope}
				>
					{Object.entries(CONVERSATION_SCOPES).map(([key, scope]) => (
						<RadioInput
							label={capitalizeFirstLetter(scope)}
							id={key}
							key={key}
							value={scope}
						/>
					))}
				</RadioInputGroup>
			</Flex.Item>
		</View>
	)
}
