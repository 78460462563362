import {
	IconAssignmentLine,
	IconDiscussionLine,
	IconDocumentLine,
	IconExternalLinkLine,
	IconLtiLine,
	IconPaperclipLine,
	IconQuizLine,
} from "@instructure/ui"

export const TYPE_DETAILS = {
	Assignment: {
		icon: <IconAssignmentLine />,
		description: "Assignment",
	},
	Discussion: {
		icon: <IconDiscussionLine />,
		description: "Discussion",
	},
	Page: {
		icon: <IconDocumentLine />,
		description: "Page",
	},
	File: {
		icon: <IconPaperclipLine />,
		description: "File",
	},
	Quiz: {
		icon: <IconQuizLine />,
		description: "Quiz",
	},
	ExternalUrl: {
		icon: <IconExternalLinkLine />,
		description: "External Link",
	},
	ExternalTool: {
		icon: <IconLtiLine />,
		description: "External Tool",
	},
}
