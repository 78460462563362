import { QUERY_KEYS } from "@/api/queryKeys.ts"
import { ZSubmission, ZSubmissionTypes } from "@/api/types.ts"
import { useQuery } from "@tanstack/react-query"
import { gql } from "graphql-request"
import { z } from "zod"
import { gqlClient } from "../api.ts"
import useValidateResponse from "../useValidateResponse.ts"

const ZAssignment = z
	.object({
		_id: z.string(),
		name: z.string(),
		description: z.string().nullable(),
		allowedAttempts: z.number().nullable(),
		dueAt: z.string().nullable(),
		pointsPossible: z.number().nullable(),
		submissionTypes: z.array(ZSubmissionTypes),
		submissionsConnection: z.object({
			nodes: z.array(ZSubmission.merge(z.object({ id: z.string() }))),
		}),
		courseName: z.string(),
	})
	.strict()

const ZAssignmentResponse = z
	.object({
		legacyNode: ZAssignment,
		course: z.object({
			name: z.string(),
		}),
	})
	.strict()

type AssignmentResponse = z.infer<typeof ZAssignmentResponse>

const query = gql`
	query GetAssignment($courseId: ID!, $assignmentId: ID!) {
		legacyNode(_id: $assignmentId, type: Assignment) {
			... on Assignment {
				_id
				name
				description
				allowedAttempts
				dueAt(applyOverrides: true)
				pointsPossible
				submissionTypes
				submissionsConnection(
					orderBy: {field: submitted_at, direction: descending}
					first: 1
				) {
          nodes {
            id
            submittedAt
            body
            grade
            score
            submissionType
            attempt
            attachment {
					  	...SubmissionFile
            }
            attachments {
              ...SubmissionFile
            }
            
					}
				}
			}
		}
		course(id: $courseId) {
			name
		}
}
fragment SubmissionFile on File {
  displayName
  id
  mimeClass
  url
  contentType
}
`

export type AssignmentProperties = { courseId: string; assignmentId: string }

export const useGetAssignment = (props: AssignmentProperties) => {
	const queryKey = QUERY_KEYS.ASSIGNMENT.item(props)
	const queryResult = useQuery({
		queryKey,
		queryFn: async (): Promise<AssignmentResponse> =>
			(await gqlClient()).request(query, props),
		select: (data) => ({
			...data.legacyNode,
			courseName: data.course.name,
		}),
	})

	useValidateResponse(queryKey.toString(), queryResult, ZAssignment)
	return queryResult
}
