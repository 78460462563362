import NotFound from "@/features/errors/NotFound.tsx"
import { Assignment } from "@/features/learning_content/Assignment.tsx"
import { ExternalItem } from "@/features/learning_content/ExternalItem.tsx"
import { FileContent } from "@/features/learning_content/FileContent.tsx"
import { Page } from "@/features/learning_content/Page.tsx"
import { Quiz } from "@/features/learning_content/Quiz.tsx"
import { Notebook } from "@/features/notebook/Notebook.tsx"
import Notification from "@/features/notification/Notification.tsx"
import { PreCourseSurvey } from "@/features/pre_course_survey/PreCourseSurvey.tsx"
import { Program } from "@/features/programs/Program.tsx"
import { Outlet } from "react-router-dom"
import Unauthorized from "./features/errors/Unauthorized.tsx"
import Home from "./features/home/Home.tsx"
import { Inbox } from "./features/inbox/Inbox.tsx"
import { Journey } from "./features/journey/Journey.tsx"
import { Login } from "./features/login/Login.tsx"
import { Logout } from "./features/login/Logout.tsx"
import { OAuth2Response } from "./features/login/OAuth2Response.tsx"
import ProtectedRoute from "./features/login/ProtectedRoute.tsx"
import { MainNavigation } from "./features/navigation/MainNavigation.tsx"
import { Programs } from "./features/programs/Programs.tsx"
import { sentryCreateBrowserRouter } from "./instrument.ts"
import { PAGEROUTE } from "./shared/router/index.ts"

const router = sentryCreateBrowserRouter([
	{
		path: PAGEROUTE.HOME,
		element: <ProtectedRoute element={<MainNavigation />} />,
		errorElement: <NotFound />,
		children: [
			{
				index: true,
				element: <Home />,
			},
			{
				path: PAGEROUTE.LEARN,
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <Programs />,
					},
					{
						path: PAGEROUTE.COURSE,
						element: <Program />,
					},
					{
						path: PAGEROUTE.PRECOURSESURVEY,
						element: <PreCourseSurvey />,
					},
					{
						path: PAGEROUTE.ASSIGNMENT,
						element: <Assignment />,
					},
					{
						path: PAGEROUTE.QUIZ,
						element: <Quiz />,
					},
					{
						path: PAGEROUTE.PAGE,
						element: <Page />,
					},
					{
						path: PAGEROUTE.EXTERNALITEM,
						element: <ExternalItem />,
					},
					{
						path: PAGEROUTE.FILECONTENT,
						element: <FileContent />,
					},
				],
			},

			{
				path: PAGEROUTE.CAREER,
				element: <Journey />,
			},
			{
				path: PAGEROUTE.INBOX,
				element: <Inbox />,
			},
			{
				path: PAGEROUTE.NOTEBOOK,
				element: <Notebook />,
			},
			{
				path: PAGEROUTE.NOTIFICATION,
				element: <Notification />,
			},
			{
				path: PAGEROUTE.LOGOUT,
				element: <Logout />,
			},
		],
	},
	{
		path: PAGEROUTE.LOGIN,
		element: <Login />,
	},
	{
		path: PAGEROUTE.OAUTH2RESPONSE,
		element: <OAuth2Response />,
	},
	{
		path: PAGEROUTE.UNAUTHORIZED,
		element: <Unauthorized />,
	},
])

export default router
