import { borders } from "../borders.ts"
import { primitives } from "../primitives.ts"
import { spacing } from "../spacing.ts"
import { typography } from "../typography.ts"
import { ui } from "../ui.ts"

export default {
	Tag: {
		fontFamily: typography.fontFamily,
		heightSmall: ui.heightSmall,
		heightMedium: ui.heightMedium,
		heightLarge: ui.heightLarge,
		padding: `${spacing.xSmall} ${spacing.small}`,
		paddingSmall: `${spacing.xxSmall} ${spacing.xSmall}`,
		focusOutlineColor: primitives.grey14,
		focusOutlineWidth: borders.widthSmall,
		iconMargin: spacing.xxSmall,
		defaultBackgroundHover: primitives.white,
		defaultBackground: primitives.grey12,
		defaultBorderColor: primitives.grey14,
		defaultBorderRadius: borders.radiusLarge,
		defaultBorderWidth: borders.widthSmall,
		defaultColor: primitives.grey125,
		defaultIconColor: primitives.grey125,
		defaultIconHoverColor: primitives.grey125,
		inlineBackgroundHover: primitives.grey12,
		inlineBackground: primitives.white,
		inlineBorderColor: primitives.grey14,
		inlineBorderRadius: borders.radiusSmall,
		inlineBorderWidth: borders.widthSmall,
		inlineColor: primitives.grey125,
		inlineIconColor: primitives.blue45,
		inlineIconHoverColor: primitives.blue45,
	},
}
