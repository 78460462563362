import { useGetNotification } from "@/api/queries/useGetNotification"
import {
	AccessibleContent,
	Badge,
	IconAlertsLine,
	IconButton,
} from "@instructure/ui"
import { Link } from "react-router-dom"
import { getUserId } from "../../token-storage.ts"

function NotificationIcon() {
	const { data, isError, isLoading } = useGetNotification(getUserId())

	if (isLoading || isError || !data) {
		return (
			<Link to="/notification">
				<IconButton
					screenReaderLabel="Notification"
					renderIcon={IconAlertsLine}
					withBackground={false}
					withBorder={false}
				/>
			</Link>
		)
	}

	return (
		<Link to="/notification">
			<Badge
				count={data.unReadNotifications.length}
				pulse
				formatOutput={(formattedCount) => (
					<AccessibleContent
						alt={`You have ${formattedCount} new edits to review`}
					>
						{formattedCount}
					</AccessibleContent>
				)}
			>
				<IconButton
					screenReaderLabel="Notification"
					renderIcon={IconAlertsLine}
					withBackground={false}
					withBorder={false}
				/>
			</Badge>
		</Link>
	)
}

export default NotificationIcon
