import { Flex, IconButton, IconXLine, Text, View } from "@instructure/ui"
import { type ReactNode, useState } from "react"
import { useAppContext } from "src/AppContext"

export const Alert = ({
	type,
	children,
}: { type: "info"; children: ReactNode }) => {
	const { desktopMode } = useAppContext()
	const [isShowing, setShowing] = useState(true)

	if (!isShowing) {
		return null
	}

	return (
		<View
			as="div"
			background="secondary"
			margin={desktopMode ? "medium medium 0 0" : "medium"}
			padding="medium"
		>
			<Flex as="div" margin="0 0 x-small">
				<Flex.Item shouldGrow={true} shouldShrink={true}>
					<Text transform="uppercase" weight="bold" size="small">
						{type}
					</Text>
				</Flex.Item>
				<Flex.Item>
					<IconButton
						screenReaderLabel="Close"
						size="small"
						renderIcon={IconXLine}
						withBackground={false}
						withBorder={false}
						onClick={() => setShowing(false)}
					/>
				</Flex.Item>
			</Flex>
			{children}
		</View>
	)
}
