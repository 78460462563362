import type { NotedCourse } from "@/api/db/db"
import { useGetNotedCourses } from "@/api/queries/useGetNotedCourses"
import { FetchingError } from "@/shared/loading/FetchingError"
import { GlobalLoader } from "@/shared/loading/GlobalLoader"
import {
	Flex,
	IconButton,
	IconSearchLine,
	IconXSolid,
	ScreenReaderContent,
	Text,
	TextInput,
	View,
} from "@instructure/ui"
import type { UseQueryResult } from "@tanstack/react-query"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useAppContext } from "src/AppContext"
import { getUserId } from "src/token-storage"

const NoteList = ({
	noteSearchQuery,
}: { noteSearchQuery: UseQueryResult<NotedCourse[], Error> }) => {
	const [_, setSearchParams] = useSearchParams()
	if (noteSearchQuery.isLoading) {
		return <GlobalLoader title="Loading" />
	}
	if (noteSearchQuery.isError || !noteSearchQuery.data) {
		return <FetchingError />
	}

	return noteSearchQuery.data?.map((note) => {
		return (
			<View
				as="div"
				key={note.courseId}
				background="primary"
				padding="medium"
				borderRadius="large"
				margin="small 0"
			>
				<Flex
					onClick={() => setSearchParams({ course: note.courseId })}
					direction="column"
					gap="small"
				>
					<Text size="small">{note.institutionName}</Text>
					<Text>{note.courseName}</Text>
				</Flex>
			</View>
		)
	})
}

export const MainNotebook = () => {
	const { setSearchText, noteSearchQuery } = useGetNotedCourses(getUserId())
	const navigate = useNavigate()
	const { desktopMode } = useAppContext()

	return (
		<View
			as="div"
			height={desktopMode ? "100vh" : "calc(100vh - 5.6rem)"}
			background="primary"
			padding="large 0 0 0"
		>
			<View
				as="div"
				background="secondary"
				padding="large medium medium"
				borderRadius="large large 0 0"
				minHeight={desktopMode ? "100vh" : "calc(100vh - 7.8rem)"}
				themeOverride={{
					borderRadiusLarge: "2rem",
				}}
			>
				<Flex justifyItems="space-between">
					<Text size="large">Notebook</Text>
					<IconButton
						withBackground={false}
						withBorder={false}
						screenReaderLabel="Delete tag"
						onClick={() => navigate("/")}
					>
						<IconXSolid />
					</IconButton>
				</Flex>
				<View as="div" margin="medium 0 medium 0">
					<TextInput
						renderLabel={<ScreenReaderContent>Search</ScreenReaderContent>}
						type="search"
						placeholder="Search..."
						renderBeforeInput={IconSearchLine}
						themeOverride={{
							borderRadius: "1rem",
						}}
						onChange={(event) => setSearchText(event.target.value)}
					/>
					<View as="div" margin="medium 0 0 0">
						<NoteList noteSearchQuery={noteSearchQuery} />
					</View>
				</View>
			</View>
		</View>
	)
}
