import { useGetNotification } from "@/api/queries/useGetNotification"
import { FetchingError } from "@/shared/loading/FetchingError"
import { GlobalLoader } from "@/shared/loading/GlobalLoader"
import {
	Flex,
	Heading,
	IconArrowStartLine,
	IconButton,
	Tabs,
	View,
} from "@instructure/ui"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { getUserId } from "../../token-storage.ts"
import NotificationList from "./NotificationList.tsx"

function Notification() {
	const { data, isError, isLoading } = useGetNotification(getUserId())
	const [selectedTabIndex, setSelectedTabIndex] = useState(0)
	const router = useNavigate()

	if (isLoading) {
		return <GlobalLoader title="Loading..." renderInContent={true} />
	}

	if (isError || !data) {
		return <FetchingError />
	}

	return (
		<View as="div" margin="medium" height="100%">
			<Flex justifyItems="center" margin="0 0 medium 0">
				<div style={{ position: "absolute", left: "1.5rem" }}>
					<IconButton
						screenReaderLabel="Notification"
						renderIcon={<IconArrowStartLine />}
						onClick={() => router("/")}
						color="primary-inverse"
						withBorder={false}
					/>
				</div>
				<Flex.Item>
					<Heading level="h1">Notifications</Heading>
				</Flex.Item>
			</Flex>

			<Tabs
				variant="secondary"
				onRequestTabChange={(_, { index }) => setSelectedTabIndex(+index)}
			>
				<Tabs.Panel
					renderTitle="New"
					isSelected={selectedTabIndex === 0}
					padding="0"
				>
					<NotificationList notifications={data.unReadNotifications} />
				</Tabs.Panel>
				<Tabs.Panel
					renderTitle="Read"
					isSelected={selectedTabIndex === 1}
					padding="0"
				>
					<NotificationList notifications={data.readNotifications} />
				</Tabs.Panel>
			</Tabs>
		</View>
	)
}

export default Notification
