import { primitives } from "./primitives.ts"

export const ui = {
	surfacePagePrimary: primitives.beige11,
	surfacePageSecondary: primitives.white,
	surfaceCardPrimary: primitives.white,
	surfaceCardSecondary: primitives.beige10,
	surfaceAttention: primitives.blue45,
	surfaceAttentionSecondary: primitives.blue70,
	surfaceSuccess: primitives.green45,
	surfaceWarning: primitives.orange45,
	surfaceError: primitives.red57,
	surfaceDivider: primitives.grey12,
	surfaceOverlayGrey: primitives.grey57,
	surfaceOverlayWhite: primitives.white,
	surfaceInversePrimary: primitives.grey125,
	surfaceInverseSecondary: primitives.black174,
	surfaceHover: primitives.grey10,

	textTitle: primitives.grey125,
	textBody: primitives.grey125,
	textTimestamp: primitives.grey57,
	textAuthor: primitives.grey57,
	textDatapoint: primitives.grey57,
	textPlaceholder: primitives.grey45,
	textBeigePrimary: primitives.beige11,
	textBeigeSecondary: primitives.beige10,
	textSurfaceColored: primitives.white,
	textSurfaceInverseSecondary: primitives.grey125,
	textLink: primitives.blue57,
	textLinkSecondary: primitives.blue70,
	textSuccess: primitives.green57,
	textWarning: primitives.orange57,
	textError: primitives.red57,

	iconDefault: primitives.grey125,
	iconLight: primitives.grey24,
	iconMedium: primitives.grey45,
	iconAction: primitives.blue57,
	iconActionSecondary: primitives.blue70,
	iconSuccess: primitives.green45,
	iconWarning: primitives.orange45,
	iconError: primitives.red57,
	iconBeigePrimary: primitives.beige11,
	iconBeigeSecondary: primitives.beige10,
	iconSurfaceColored: primitives.white,
	iconSurfaceInverseSecondary: primitives.black174,

	containerStroke: primitives.grey45,
	lineStroke: primitives.grey14,
	lineDivider: primitives.grey12,
	lineConnector: primitives.grey14,

	heightSmall: "1rem", //16px
	heightMedium: "1.188rem", //19px
	heightLarge: "1.357rem", //22px
} as const

// default ui colors removed: surfaceDark, textDescription
