import { useMutation } from "@tanstack/react-query"
import { z } from "zod"
import { restClient } from "../api.ts"
import useValidateResponse from "../useValidateResponse.ts"

const ZUploadUrlData = z
	.object({
		upload_url: z.string(),
		// any since Canvas will return different params depending on configuration, and it simply
		// expects us to send them back in useUploadFileSubmission
		upload_params: z.record(z.any()),
		file_param: z.string(),
	})
	.strict()

type UploadUrlData = z.infer<typeof ZUploadUrlData>

const mutationFn = ({
	courseId,
	assignmentId,
	name,
}: {
	courseId: string
	assignmentId: string
	name: string
}): Promise<UploadUrlData> => {
	return restClient(
		`api/v1/courses/${courseId}/assignments/${assignmentId}/submissions/self/files`,
		{
			method: "POST",
			body: JSON.stringify({
				name,
				no_redirect: true,
				submit_assignment: true,
			}),
		},
	)
}

export const useCreateFileUploadUrl = () => {
	const mutationResult = useMutation({
		mutationFn,
	})

	useValidateResponse("useCreateFileUploadUrl", mutationResult, ZUploadUrlData)
	return mutationResult
}
