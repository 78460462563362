import type { Module as ModuleType } from "@/api/queries/useGetModules"
import { ModuleItems } from "@/features/programs/ModuleItems.tsx"
import {
	Flex,
	IconArrowOpenDownSolid,
	IconArrowOpenUpSolid,
	View,
} from "@instructure/ui"
import { useState } from "react"

export const Module = ({
	module,
	defaultExpanded = false,
}: { module: ModuleType; defaultExpanded?: boolean }) => {
	const [expanded, setExpanded] = useState(defaultExpanded)

	return (
		<View
			key={module._id}
			as="div"
			background="primary"
			margin="medium"
			padding="medium"
		>
			<Flex
				as="div"
				justifyItems="space-between"
				gap="small"
				margin="small 0"
				onClick={() => setExpanded(!expanded)}
				tabIndex={0}
			>
				<Flex.Item shouldGrow={true} shouldShrink={true}>
					{module.name || "Module"}
				</Flex.Item>
				<Flex.Item>
					<Flex alignItems="center" gap="x-small">
						<Flex.Item>{module.moduleItems.length}</Flex.Item>
						<Flex.Item>
							<span style={{ position: "relative", bottom: "0.1em" }}>
								{expanded ? (
									<IconArrowOpenUpSolid />
								) : (
									<IconArrowOpenDownSolid />
								)}
							</span>
						</Flex.Item>
					</Flex>
				</Flex.Item>
			</Flex>

			{expanded && <ModuleItems module={module} />}
		</View>
	)
}
