import { useGetModuleItems } from "@/api/queries/useGetModules.ts"
import type { Module } from "@/api/queries/useGetModules.ts"
import type { ModuleItem, ModuleItemType } from "@/api/types.ts"
import { LearningObjectIcon } from "@/shared/learning_object_metadata/LearningObjectIcon.tsx"
import { LearningObjectName } from "@/shared/learning_object_metadata/LearningObjectName.tsx"
import { FetchingError } from "@/shared/loading/FetchingError.tsx"
import { GlobalLoader } from "@/shared/loading/GlobalLoader.tsx"
import { PAGEROUTE, generateRoute } from "@/shared/router"
import {
	Flex,
	IconCheckSolid,
	IconClockLine,
	Text,
	View,
} from "@instructure/ui"
import { useLayoutEffect, useRef, useState } from "react"
import { Link, useParams } from "react-router-dom"

const Checkbox = ({ checked }: { checked: boolean }) => (
	<View
		as="div"
		borderRadius="circle"
		borderWidth="small"
		themeOverride={{
			borderColorPrimary: "black",
			backgroundPrimary: checked ? "black" : undefined,
		}}
		background="primary"
		textAlign="center"
		width="1.35em"
		height="1.35em"
		padding="auto"
	>
		<Text size="x-small" color="primary-inverse">
			<span style={{ position: "relative", bottom: "0.35em" }}>
				<IconCheckSolid />
			</span>
		</Text>
	</View>
)

const getLearningObjectLink = (
	type: ModuleItemType,
	quizLti: boolean,
	courseId: string,
	item: ModuleItem,
	module: Module,
) => {
	switch (type) {
		case "Assignment":
			return generateRoute(
				quizLti ? PAGEROUTE.QUIZ : PAGEROUTE.ASSIGNMENT,
				{
					courseId,
					assignmentId: String(item.content_id),
				},
				[{ item_id: item.id }],
			)
		case "Page":
			// the REST API doesn't include the content ID in the module item response, so for now get it from the module graphql query
			return generateRoute(
				PAGEROUTE.PAGE,
				{
					courseId,
					pageId: String(
						module.moduleItems.find((mi) => mi._id === item.id.toString())
							?.content._id,
					),
				},
				[{ item_id: item.id }],
			)
		case "ExternalUrl":
		case "ExternalTool":
			return generateRoute(PAGEROUTE.EXTERNALITEM, {
				courseId,
				moduleId: module._id,
				itemId: String(item.id),
			})
		case "File":
			return generateRoute(
				PAGEROUTE.FILECONTENT,
				{
					courseId,
					fileId: String(item.content_id),
				},
				[{ module_item_id: item.id }],
			)
		default:
			return ""
	}
}

const SUPPORTED_TYPES: ModuleItemType[] = [
	"File",
	"Page",
	"Assignment",
	"ExternalUrl",
	"ExternalTool",
]

export const ModuleItems = ({ module }: { module: Module }) => {
	const { courseId = "" } = useParams()
	const itemsQuery = useGetModuleItems({ courseId, moduleId: module._id })
	const itemWrapperRefs = useRef<(HTMLDivElement | null)[]>([])
	const [trackerLineHeights, setTrackerLineHeights] = useState<number[]>([])

	const updateTrackerLineHeights = () => {
		setTrackerLineHeights(
			itemWrapperRefs.current.map((wrapper, index) => {
				const nextWrapper = itemWrapperRefs.current[index + 1]
				if (!wrapper || !nextWrapper) {
					return 0
				}
				return wrapper.offsetHeight / 2 + nextWrapper.offsetHeight / 2 + 2
			}),
		)
	}

	// biome-ignore lint/correctness/useExhaustiveDependencies: run once on mount
	useLayoutEffect(() => {
		window.addEventListener("resize", updateTrackerLineHeights)
		return () => {
			window.removeEventListener("resize", updateTrackerLineHeights)
		}
	}, [])

	// biome-ignore lint/correctness/useExhaustiveDependencies: run when we get data
	useLayoutEffect(() => {
		updateTrackerLineHeights()
	}, [itemsQuery.isSuccess])

	if (itemsQuery.isLoading) {
		return <GlobalLoader title="Loading..." renderInContent={true} />
	}

	if (itemsQuery.isError || !itemsQuery.data) {
		return <FetchingError />
	}

	return itemsQuery.data
		.filter((item) => SUPPORTED_TYPES.includes(item.type))
		.map((item, index) => (
			<div
				key={item.id}
				style={{ position: "relative" }}
				ref={(el) => {
					itemWrapperRefs.current[index] = el
				}}
			>
				<Flex
					as="div"
					justifyItems="space-between"
					gap="small"
					margin="medium 0"
				>
					<Flex.Item>
						<Checkbox
							checked={item.completion_requirement?.completed || false}
						/>
						{index < itemsQuery.data.length - 1 && (
							<div
								style={{
									position: "absolute",
									left: "10px",
									borderLeft: `1px ${item.completion_requirement?.completed ? "solid" : "dashed"} black`,
									height: `${trackerLineHeights[index]}px`,
								}}
							/>
						)}
					</Flex.Item>
					<Flex.Item shouldGrow={true} shouldShrink={true}>
						<Link
							to={getLearningObjectLink(
								item.type,
								item.quiz_lti,
								courseId,
								item,
								module,
							)}
							style={{ textDecoration: "none" }}
						>
							<View
								as="div"
								background="secondary"
								padding="small"
								overflowX="hidden"
							>
								<Text>{item.title}</Text>
								<Flex as="div" justifyItems="space-between" margin="small 0 0">
									<Flex.Item>
										<Text color="secondary" size="small">
											<LearningObjectIcon
												type={item.quiz_lti ? "Quiz" : item.type}
											/>{" "}
											<LearningObjectName
												type={item.quiz_lti ? "Quiz" : item.type}
											/>
										</Text>
									</Flex.Item>
									<Flex.Item>
										<Text color="secondary" size="small">
											<IconClockLine /> 20 Mins
										</Text>
									</Flex.Item>
								</Flex>
							</View>
						</Link>
					</Flex.Item>
				</Flex>
			</div>
		))
}
