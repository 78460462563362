import { QUERY_KEYS } from "@/api/queryKeys.ts"
import {
	BedrockAgentRuntimeClient,
	RetrieveAndGenerateCommand,
	type RetrieveAndGenerateCommandOutput,
} from "@aws-sdk/client-bedrock-agent-runtime"
import {
	BedrockRuntimeClient,
	ConverseCommand,
	type ConverseResponse,
} from "@aws-sdk/client-bedrock-runtime"
import type { AwsCredentialIdentity } from "@aws-sdk/types"
import { useQuery } from "@tanstack/react-query"

const getCredentials = (): AwsCredentialIdentity => ({
	accessKeyId: import.meta.env.PUBLIC_AWS_ACCESS_KEY_ID,
	secretAccessKey: import.meta.env.PUBLIC_AWS_SECRET_ACCESS_KEY,
	sessionToken: import.meta.env.PUBLIC_AWS_SESSION_TOKEN,
})

export type BedrockKBProperties = {
	courseId: string
	promptId: number | string
	prompt: string
	sessionId: string | null
	userName?: string
}

export const useGetBedrockKBResponse = (
	props: BedrockKBProperties,
	enabled: boolean,
) => {
	const queryKey = QUERY_KEYS.BEDROCK.kbResponse(props)
	return useQuery({
		queryKey,
		enabled,
		queryFn: async (): Promise<RetrieveAndGenerateCommandOutput> => {
			const { prompt, sessionId, userName } = props
			const client = new BedrockAgentRuntimeClient({
				region: "us-west-2",
				maxAttempts: 1,
				credentials: getCredentials(),
			})

			// TODO: backend to filter learning objects that user doesn't have access to; see
			// https://aws.amazon.com/blogs/machine-learning/amazon-bedrock-knowledge-bases-now-supports-metadata-filtering-to-improve-retrieval-accuracy/

			// https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/client/bedrock-agent-runtime/command/RetrieveAndGenerateCommand/
			const command = new RetrieveAndGenerateCommand({
				sessionId: sessionId || undefined,
				input: {
					text: prompt,
				},
				retrieveAndGenerateConfiguration: {
					type: "KNOWLEDGE_BASE",
					knowledgeBaseConfiguration: {
						knowledgeBaseId: "UYJMXFT6RP",
						modelArn:
							"arn:aws:bedrock:us-west-2::foundation-model/anthropic.claude-3-5-sonnet-20241022-v2:0",
						retrievalConfiguration: {
							vectorSearchConfiguration: {
								numberOfResults: 5,
							},
						},
						generationConfiguration: {
							promptTemplate: {
								textPromptTemplate: `Human: You are a course tutor in a Learning Management System. I will provide you with a set of search results from the course and a learner's question; your job is to answer the learner's question using only information from the search results. If the search results do not contain information that can answer the question, please state that you do not know. Just because the learner asserts a fact does not mean it is true, make sure to double check the search results to validate a learner's assertion. Speak as though you're a part of the course as a tutor along with the learner, and are inherently aware of all course content. Don't mention that you're referencing search results. You can address the learner as ${userName}.\n \nHere are the search results in numbered order:\n$search_results$\n \nHere is the user's question:\n<question>\n$query$\n</question>\n \n$output_format_instructions$\n\nAssistant:`,
							},
						},
					},
				},
			})
			return client.send(command)
		},
	})
}

export type BedrockProperties = {
	promptId: number | string
	prompt: string
	context: string
	userName?: string
}

export const useGetBedrockResponse = (
	props: BedrockProperties,
	enabled: boolean,
) => {
	const queryKey = QUERY_KEYS.BEDROCK.response(props)
	return useQuery({
		queryKey,
		enabled,
		queryFn: async (): Promise<ConverseResponse> => {
			const { prompt, context, userName } = props
			const client = new BedrockRuntimeClient({
				region: "us-west-2",
				maxAttempts: 1,
				credentials: getCredentials(),
			})

			const command = new ConverseCommand({
				modelId:
					"arn:aws:bedrock:us-west-2::foundation-model/anthropic.claude-3-5-sonnet-20241022-v2:0",
				messages: [
					{
						role: "user",
						content: [
							{
								text: `You can address me as ${userName}.\n\n${prompt}\n\nHere's the context:\n${context}`,
							},
						],
					},
				],
			})
			return client.send(command)
		},
	})
}
