import {
	type ConversationConnection,
	useGetConversations,
} from "@/api/queries/useGetConversations.ts"
import { DesktopView } from "@/features/inbox/DesktopView.tsx"
import { MobileView } from "@/features/inbox/MobileView.tsx"
import { ScopeSelector } from "@/features/inbox/ScopeSelector.tsx"
import {
	CONVERSATION_SCOPES,
	SCOPE_SELECTOR_HEIGHT,
} from "@/features/inbox/constants.ts"
import type { ViewProps } from "@/features/inbox/types.ts"
import { FetchingError } from "@/shared/loading/FetchingError.tsx"
import { GlobalLoader } from "@/shared/loading/GlobalLoader.tsx"
import { View } from "@instructure/ui"
import { useEffect, useState } from "react"
import { useAppContext } from "../../AppContext.tsx"
import { getUserId } from "../../token-storage.ts"
import "./InboxStyle.css"

export const Inbox = () => {
	const userId = getUserId() || ""
	const { desktopMode } = useAppContext()
	const [scope, setScope] = useState<CONVERSATION_SCOPES>(
		CONVERSATION_SCOPES.INBOX,
	)
	const [filter, setFilter] = useState<string>("")
	const [selectedConversation, setSelectedConversation] =
		useState<ConversationConnection | null>(null)

	const conversationsQuery = useGetConversations({
		userId,
		filter: [filter],
		scope,
	})

	if (
		conversationsQuery.isError ||
		(!conversationsQuery.isLoading && !conversationsQuery.data)
	) {
		return <FetchingError />
	}

	const conversations = conversationsQuery.data?.conversations || []

	useEffect(() => {
		if (desktopMode && conversations.length) {
			setSelectedConversation(conversations[0])
		} else {
			setSelectedConversation(null)
		}
	}, [conversations, desktopMode])

	const viewProps: ViewProps = {
		filterValue: filter,
		setFilter,
		conversations,
		selectedConversation,
		setSelectedConversation,
	}

	return (
		<View
			as="div"
			className="flex-container flex-direction-column"
			height="100%"
			width="100%"
			overflowY="hidden"
			overflowX="hidden"
		>
			<View as="div" height={SCOPE_SELECTOR_HEIGHT}>
				<ScopeSelector scope={scope} setScope={setScope} />
			</View>
			{conversationsQuery.isLoading ? (
				<GlobalLoader title="Loading..." />
			) : (
				<View
					as="div"
					className="flex-item-grow"
					overflowY="hidden"
					height="100%"
				>
					{desktopMode ? (
						<DesktopView {...viewProps} />
					) : (
						<MobileView {...viewProps} />
					)}
				</View>
			)}
		</View>
	)
}
