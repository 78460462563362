import { useGetSessionlessLaunchUrl } from "@/api/queries/useGetSessionlessLaunchUrl.ts"
import { FetchingError } from "@/shared/loading/FetchingError.tsx"
import { GlobalLoader } from "@/shared/loading/GlobalLoader.tsx"
import { Flex } from "@instructure/ui"
import { useParams } from "react-router-dom"

type ExternalToolAssignmentProps = {
	title: string
}

export const ExternalToolAssignment = ({
	title,
}: ExternalToolAssignmentProps) => {
	const { courseId = "", assignmentId = "" } = useParams()
	const sessionlessLaunchUrlQuery = useGetSessionlessLaunchUrl({
		courseId,
		assignmentId,
	})

	if (sessionlessLaunchUrlQuery.isLoading) {
		return <GlobalLoader title="Loading..." />
	}

	if (sessionlessLaunchUrlQuery.isError || !sessionlessLaunchUrlQuery.data) {
		return <FetchingError />
	}

	return (
		<Flex.Item shouldGrow={true}>
			<iframe
				title={title}
				src={sessionlessLaunchUrlQuery.data.url}
				style={{
					border: "none",
					width: "100%",
					height: "100%",
				}}
			/>
		</Flex.Item>
	)
}
