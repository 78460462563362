import { createRedirectUrl } from "@/features/login/pkce-utils.ts"
import { useOnMount } from "@/shared/hooks/useOnMount.ts"
import { useState } from "react"
import { GlobalLoader } from "../../shared/loading/GlobalLoader.tsx"
import { LoginError } from "./LoginError.tsx"

export const Login = () => {
	const [isError, setError] = useState(false)

	useOnMount(() => {
		createRedirectUrl()
			.then((url) => {
				window.location.href = url
			})
			.catch((err) => {
				setError(true)
				throw err
			})
	})

	return isError ? (
		<LoginError />
	) : (
		<GlobalLoader title="Redirecting to login..." />
	)
}
