import { QUERY_KEYS } from "@/api/queryKeys.ts"
import { type ModuleItem, ZModuleItem, ZModuleItemType } from "@/api/types.ts"
import { APIROUTE, generateRoute } from "@/shared/router"
import { useQuery } from "@tanstack/react-query"
import { gql } from "graphql-request"
import { z } from "zod"
import { gqlClient, restClient } from "../api.ts"
import useValidateResponse from "../useValidateResponse.ts"
import { ZCourseProgression } from "./useGetCourses.ts"

export const ZModule = z.object({
	_id: z.string(),
	name: z.string().nullable(),
	moduleItems: z.array(
		z.object({
			_id: z.string(),
			content: z.object({
				__typename: ZModuleItemType,
				_id: z.string(),
				name: z.string(),
			}),
		}),
	),
})

const ZCourseModules = z.object({
	courseName: z.string(),
	modules: z.array(ZModule),
	courseProgression: ZCourseProgression,
})

const ZModulesResponse = z
	.object({
		course: z.object({
			name: z.string(),
			account: z.object({
				name: z.string(),
			}),
			modulesConnection: z.object({
				nodes: z.array(ZModule),
			}),
			usersConnection: z.object({
				nodes: z.array(
					z.object({
						courseProgression: ZCourseProgression,
					}),
				),
			}),
		}),
	})
	.strict()

type ModulesResponse = z.infer<typeof ZModulesResponse>
export type Module = z.infer<typeof ZModule>

const query = gql`
	query GetCourseModules($id: ID!, $userId: ID!) {
		course(id: $id) {
			name
			account {
				name
			}
			modulesConnection {
				nodes {
					_id
					name
					moduleItems {
						_id
						content {
							... on Assignment {
								__typename
								_id
								name
							}
							... on Discussion {
								__typename
								_id
								name: title
							}
							... on Page {
								__typename
								_id
								name: title
							}
							... on File {
								__typename
								_id
								name: displayName
							}
						}
					}
				}
			}
			usersConnection(filter: { userIds: [$userId] }) {
				nodes {
					courseProgression {
						requirements {
							completed
							completionPercentage
							total
						}
					}
				}
			}
		}
	}
`

export type ModuleByUserProperties = { courseId: string; userId?: string }

export const useGetModules = (props: ModuleByUserProperties) => {
	const queryKey = QUERY_KEYS.MODULE.byUser(props)
	const queryResult = useQuery({
		queryKey,
		queryFn: async (): Promise<ModulesResponse> =>
			(await gqlClient()).request(query, {
				id: props.courseId,
				userId: props.userId,
			}),
		select: (data) => ({
			courseName: data.course.name,
			modules: data.course.modulesConnection.nodes.map((module) => ({
				...module,
				moduleItems: module.moduleItems.filter(
					(item) => item.content.__typename,
				),
			})),
			courseProgression: data.course.usersConnection.nodes[0].courseProgression,
			accountName: data.course.account.name,
		}),
		enabled: !!props.userId,
	})

	useValidateResponse(queryKey.toString(), queryResult, ZCourseModules)
	return queryResult
}

const ZModuleItemsResponse = z.array(ZModuleItem)

type ModuleItemsResponse = z.infer<typeof ZModuleItemsResponse>

export type ModuleItemsProperties = {
	courseId: string
	moduleId: string
}

export const useGetModuleItems = (props: ModuleItemsProperties) => {
	const queryKey = QUERY_KEYS.MODULE.items(props)
	const queryResult = useQuery({
		queryKey,
		queryFn: async (): Promise<ModuleItemsResponse> => {
			const itemApiRoute = generateRoute(
				APIROUTE.ITEMS,
				{
					courseId: props.courseId,
					moduleId: props.moduleId,
				},
				[{ per_page: 100 }],
			)

			return restClient(itemApiRoute)
		},
	})

	useValidateResponse(queryKey.toString(), queryResult, ZModuleItemsResponse)
	return queryResult
}

export type ModuleItemProperties = ModuleItemsProperties & {
	itemId: string
}

export const useGetModuleItem = (props: ModuleItemProperties) => {
	const queryKey = QUERY_KEYS.MODULE.item(props)
	const queryResult = useQuery({
		queryKey,
		queryFn: async (): Promise<ModuleItem> => {
			const itemApiRoute = generateRoute(APIROUTE.ITEM, props)
			return restClient(itemApiRoute)
		},
	})

	useValidateResponse(queryKey.toString(), queryResult, ZModuleItem)
	return queryResult
}
