import { useExchangeOAuthCode } from "@/api/mutations/useExchangeOAuthCode.ts"
import {
	clearCodeVerifier,
	getCodeVerifier,
} from "@/features/login/pkce-verifier-storage.ts"
import { useOnMount } from "@/shared/hooks/useOnMount.ts"
import { setTokenData } from "@/token-storage.ts"
import { useState } from "react"
import { Navigate } from "react-router-dom"
import { useSearchParams } from "react-router-dom"
import { GlobalLoader } from "../../shared/loading/GlobalLoader.tsx"
import { LoginError } from "./LoginError.tsx"

export const OAuth2Response = () => {
	const [isError, setError] = useState(false)
	const [searchParams] = useSearchParams()
	const mutation = useExchangeOAuthCode({
		onSuccess: (tokenData) => {
			if (
				!tokenData.access_token ||
				!tokenData.refresh_token ||
				!tokenData.user.id
			) {
				return reportError(
					"OAuth2Response: did not receive expected data from token request",
				)
			}

			setTokenData({
				userId: tokenData.user.id.toString(),
				accessToken: tokenData.access_token,
				refreshToken: tokenData.refresh_token,
				isMasquerading: !!tokenData.real_user,
				isTestStudent: !!tokenData.user.fake_student,
			})
		},
		onError: (error) => {
			reportError(
				`OAuth2Response: received an error from token request: ${error.message}`,
			)
		},
	})

	const reportError = (message: string) => {
		setError(true)
		console.error(message)
	}

	useOnMount(() => {
		const error = searchParams.get("error")
		const code = searchParams.get("code")

		if (error) {
			return reportError(
				`OAuth2Response received an error from authorization: ${error}`,
			)
		}

		if (!code) {
			return reportError("OAuth2Response received no code from authorization")
		}

		const verifier = getCodeVerifier()
		clearCodeVerifier()
		if (!verifier) {
			return reportError("OAuth2Response: no code verifier found")
		}

		mutation.mutate({ code, verifier })
	})

	if (mutation.isSuccess) {
		return <Navigate to="/" />
	}

	return isError ? <LoginError /> : <GlobalLoader title="Logging in..." />
}
