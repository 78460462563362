import { Flex, Spinner } from "@instructure/ui"

export const GlobalLoader = ({
	title,
	renderInContent = false,
}: { title: string; renderInContent?: boolean }) => {
	return (
		<Flex
			as="div"
			width="100%"
			height={renderInContent ? "100%" : "100vh"}
			justifyItems="center"
		>
			<Flex.Item>
				<Spinner renderTitle={title} size="large" />
			</Flex.Item>
		</Flex>
	)
}
