import type { ModuleItemType } from "@/api/types.ts"
import {
	Actor,
	type Chat,
	type Citation,
} from "@/shared/learning_assist/types.ts"
import { PAGEROUTE, generateRoute } from "@/shared/router"
import type { RetrieveAndGenerateCommandOutput } from "@aws-sdk/client-bedrock-agent-runtime"
import type { ConverseResponse } from "@aws-sdk/client-bedrock-runtime"

export const makeLink = (
	courseId: string,
	objectType: ModuleItemType,
	objectId: string,
) => {
	switch (objectType) {
		case "Page":
			return generateRoute(PAGEROUTE.PAGE, {
				courseId,
				pageId: objectId,
			})
		case "Assignment":
			return generateRoute(PAGEROUTE.ASSIGNMENT, {
				courseId,
				assignmentId: objectId,
			})
		default:
			return PAGEROUTE.HOME
	}
}

export const transformBedrockKbResponseToChat = (
	newId: number,
	response: RetrieveAndGenerateCommandOutput,
): Chat => {
	const references = response.citations?.flatMap(
		(citation) => citation.retrievedReferences,
	)
	return {
		id: newId,
		actor: Actor.Assistant,
		message: response.output?.text || "",
		citations: references?.reduce<Citation[]>((acc, reference) => {
			const objectName = reference?.metadata?.object_name
			const objectType = reference?.metadata?.object_type
			const objectId = reference?.metadata?.object_id
			// citation doesn't have required metadata
			if (!objectName || !objectType || !objectId) {
				return acc
			}
			// same citation already exists in citations
			if (
				acc.some(
					(item) =>
						item.objectId === objectId.toString() &&
						item.objectType === objectType,
				)
			) {
				return acc
			}
			acc.push({
				objectName: objectName as string,
				objectType: objectType as ModuleItemType,
				objectId: objectId.toString(),
			})
			return acc
		}, []),
	}
}

export const transformBedrockResponseToChat = (
	newId: number,
	response: ConverseResponse,
): Chat => ({
	id: newId,
	actor: Actor.Assistant,
	htmlMessage:
		response.output?.message?.content?.[0]?.text ??
		"I'm not able to help with that.",
})
