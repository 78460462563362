import { useMutation } from "@tanstack/react-query"
import { z } from "zod"
import useValidateResponse from "../useValidateResponse.ts"

const ZFinalizeFileUploadResponse = z
	.object({
		id: z.number(),
		filename: z.string(),
		size: z.number(),
	})
	.strict()

type FinalizeFileUploadResponse = z.infer<typeof ZFinalizeFileUploadResponse>

const mutationFn = async ({
	finalizeUploadUrl,
}: {
	finalizeUploadUrl: string
}): Promise<FinalizeFileUploadResponse> => {
	// Calling fetch directly since Canvas builds this url for us
	const response = await fetch(finalizeUploadUrl)
	if (!response.ok) {
		throw new Error("Failed to finalize file upload")
	}
	const data = await response.json()
	return { id: data.id, filename: data.filename, size: data.size }
}

export const useFinalizeFileUpload = () => {
	const mutationResult = useMutation({
		mutationFn,
	})

	useValidateResponse(
		"useFinalizeFileUpload",
		mutationResult,
		ZFinalizeFileUploadResponse,
	)
	return mutationResult
}
