import { NoteTag } from "@/api/db/db"
import {
	Billboard,
	Flex,
	FlexItem,
	IconFlagLine,
	IconLikeLine,
	IconQuestionLine,
	Text,
	canvas,
} from "@instructure/ui"
import type { Dispatch, ReactElement, SetStateAction } from "react"

export const tagIconMap = [
	{
		tag: NoteTag.Confusing,
		renderIcon: () => <IconQuestionLine size="x-small" />,
	},
	{
		tag: NoteTag.Important,
		renderIcon: () => <IconFlagLine size="x-small" />,
	},
	{
		tag: NoteTag.Interesting,
		renderIcon: () => <IconLikeLine size="x-small" />,
	},
]

const TagBillBoard = ({
	renderIcon,
	tag,
	currentTags: currentTag,
	setTags: setTag,
	isMultiSelect,
}: {
	renderIcon: () => ReactElement
	tag: NoteTag
	currentTags: Set<NoteTag>
	setTags: Dispatch<SetStateAction<Set<NoteTag>>>
	isMultiSelect: boolean
}) => {
	const selected = currentTag.has(tag)
	return (
		<FlexItem shouldGrow>
			<Billboard
				size="small"
				hero={renderIcon}
				message={tag}
				selected={selected}
				onClick={() => {
					if (isMultiSelect) {
						if (selected) {
							setTag((state: Set<NoteTag>) => {
								state.delete(tag)
								return new Set(state)
							})
						} else {
							setTag((state: Set<NoteTag>) => {
								state.add(tag)
								return new Set(state)
							})
						}
					} else {
						if (selected) {
							setTag(new Set())
						} else {
							setTag(new Set<NoteTag>([tag]))
						}
					}
				}}
				themeOverride={{
					messageColorClickable: canvas.colors.primitives.grey125,
					iconHoverColor: canvas.colors.primitives.grey125,
					iconColor: canvas.colors.primitives.grey125,
					clickableActiveBg: "#a9a9a9a",
					backgroundColor: !selected ? "#a9a9a9" : "",
				}}
			/>
		</FlexItem>
	)
}

export const TagBlock = ({
	currentTags,
	setTags,
	title,
	isMultiSelect,
}: {
	currentTags: Set<NoteTag>
	setTags: Dispatch<SetStateAction<Set<NoteTag>>>
	title: string
	isMultiSelect: boolean
}) => {
	return (
		<>
			<Text>{title}</Text>
			<Flex
				as="div"
				justifyItems="space-between"
				gap="x-small"
				margin="small 0 medium 0"
			>
				{tagIconMap.map(({ tag, renderIcon }) => (
					<TagBillBoard
						key={tag}
						tag={tag}
						currentTags={currentTags}
						setTags={setTags}
						renderIcon={renderIcon}
						isMultiSelect={isMultiSelect}
					/>
				))}
			</Flex>
		</>
	)
}
