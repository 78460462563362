import type { ZMimeTypesType } from "@/api/types"
import { Flex } from "@instructure/ui"

export const AudioContent = ({
	url,
	content_type,
}: { url: string; content_type: ZMimeTypesType }) => {
	return (
		<Flex as="div" margin="medium auto" justifyItems="center">
			{/* biome-ignore lint/a11y/useMediaCaption: */}
			<audio controls>
				<source src={url} type={content_type} />
			</audio>
		</Flex>
	)
}
