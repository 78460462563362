export const primitives = {
	white: "rgb(255, 255, 255)",

	grey10: "rgb(244, 244, 244)",
	grey11: "rgb(242, 244, 244)",
	grey12: "rgb(232, 234, 236)",
	grey14: "rgb(215, 218, 222)",
	grey24: "rgb(158, 166, 173)",
	grey45: "rgb(106, 120, 131)",
	grey57: "rgb(88, 104, 116)",
	grey70: "rgb(74, 91, 104)",
	grey82: "rgb(63, 81, 94)",
	grey100: "rgb(51, 68, 81)",
	grey125: "rgb(39, 53, 64)",

	black174: "rgb(10, 27, 42)",

	blue12: "rgb(224, 235, 245)",
	blue45: "rgb(43, 122, 188)",
	blue57: "rgb(14, 104, 179)",
	blue70: "rgb(10, 90, 158)",
	blue82: "rgb(9, 80, 140)",

	green12: "rgb(220, 238, 228)",
	green45: "rgb(3, 137, 61)",
	green57: "rgb(2, 118, 52)",
	green70: "rgb(2, 103, 45)",
	green82: "rgb(1, 91, 40)",

	orange12: "rgb(252, 229, 217)",
	orange30: "rgb(240, 110, 38)",
	orange45: "rgb(207, 74, 0)",
	orange57: "rgb(179, 64, 0)",
	orange70: "rgb(156, 56, 0)",
	orange82: "rgb(139, 50, 0)",

	red12: "rgb(252, 228, 229)",
	red45: "rgb(230, 36, 41)",
	red57: "rgb(199, 31, 35)",
	red70: "rgb(174, 27, 31)",
	red82: "rgb(155, 24, 28)",

	beige10: "rgb(255, 253, 250)",
	beige11: "rgb(251, 245, 237)",
	beige12: "rgb(253, 234, 204)",
	beige15: "rgb(227, 208, 178)",
	beige19: "rgb(202, 183, 154)",
	beige26: "rgb(176, 159, 131)",
	beige35: "rgb(151, 135, 109)",
	beige49: "rgb(125, 111, 88)",
	beige69: "rgb(100, 88, 68)",
	beige100: "rgb(74, 65, 49)",
	beige147: "rgb(47, 39, 27)",
}

// default primitives removed: white10op75, grey100op75
// TODO: add "additional primitives" (see CLX design system)
