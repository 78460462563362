export const spacing = {
	xxxSmall: "0.125rem", // 2px
	xxSmall: "0.25rem", // 4px
	xSmall: "0.5rem", // 8px
	smallMedium: "0.625rem", // 10px
	small: "0.75rem", // 12px
	mediumSmall: "1rem", // 16px
	medium: "1.5rem", // 24px
	large: "2.25rem", // 36px
	xLarge: "3rem", // 48px
}

// default spacing removed: xxLarge
