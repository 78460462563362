import { useDeleteConversation } from "@/api/mutations/conversation/useDeleteConversation.ts"
import { MESSAGE_HEADER_HEIGHT } from "@/features/inbox/constants.ts"
import { ConfirmationModal } from "@/shared/components/ConfirmationModal.tsx"
import { useToast } from "@/shared/components/Toast/ToastContext.tsx"
import { CLXTruncateText } from "@/shared/components/TruncateText/TruncateText.tsx"
import {
	IconArrowOpenStartLine,
	IconButton,
	IconReplyLine,
	IconTrashLine,
	Text,
	View,
} from "@instructure/ui"
import { useState } from "react"

type MessageHeaderProps = {
	onBack?: () => void
	onReply: () => void
	conversationId: string
	subject?: string
}

const BACK_BUTTON_WIDTH = 44
const ACTION_BUTTONS_WIDTH = 2 * 44

export const MessageHeader = ({
	onBack,
	onReply,
	conversationId,
	subject,
}: MessageHeaderProps) => {
	const [isDeleteConversationModalOpen, setIsDeleteConversationModalOpen] =
		useState<boolean>(false)
	const { mutate: deleteConversation } = useDeleteConversation()
	const sendToast = useToast()

	return (
		<View
			as="div"
			className="flex-container flex-align-center"
			width="100%"
			padding="small"
			height={MESSAGE_HEADER_HEIGHT}
		>
			{onBack && (
				<View as="div" width={BACK_BUTTON_WIDTH}>
					<IconButton
						screenReaderLabel="Back"
						renderIcon={IconArrowOpenStartLine}
						shape="circle"
						size="small"
						margin="x-small"
						withBackground={false}
						withBorder={false}
						onClick={onBack}
					/>
				</View>
			)}
			<View
				as="div"
				width={`calc(100% - ${(onBack ? BACK_BUTTON_WIDTH : 0) + ACTION_BUTTONS_WIDTH}px)`}
			>
				{subject ? (
					<CLXTruncateText as="h2" size="medium" text={subject} />
				) : (
					<Text as="h2" size="medium">
						Messages
					</Text>
				)}
			</View>
			<View as="div" className="flex-container" width={ACTION_BUTTONS_WIDTH}>
				<IconButton
					screenReaderLabel="Reply"
					size="small"
					margin="x-small"
					renderIcon={IconReplyLine}
					withBackground={false}
					shape="circle"
					withBorder={false}
					onClick={onReply}
				/>
				<IconButton
					screenReaderLabel="Delete conversation"
					renderIcon={IconTrashLine}
					withBackground={false}
					shape="circle"
					withBorder={false}
					size="small"
					margin="x-small"
					onClick={() => setIsDeleteConversationModalOpen(true)}
				/>
				<ConfirmationModal
					isOpen={isDeleteConversationModalOpen}
					titleText="Delete conversation"
					bodyText="Are you sure that you want to delete the selected conversation with all the messages?"
					onConfirm={() => {
						deleteConversation(
							{ ids: [conversationId] },
							{
								onSuccess: () => {
									sendToast({
										message: "Conversation deleted successfully.",
										variant: "success",
									})
								},
								onError: () =>
									sendToast({
										message: "Conversation delete failed, please try again!",
										variant: "error",
									}),
							},
						)
						setIsDeleteConversationModalOpen(false)
					}}
					onCancel={() => setIsDeleteConversationModalOpen(false)}
				/>
			</View>
		</View>
	)
}
