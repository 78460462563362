import ConversationList from "@/features/inbox/ConversationList.tsx"
import { InboxHeader } from "@/features/inbox/InboxHeader.tsx"
import { SelectedConversation } from "@/features/inbox/SelectedConversation.tsx"
import {
	INBOX_HEADER_HEIGHT,
	MESSAGE_HEADER_HEIGHT,
	SCOPE_SELECTOR_HEIGHT,
} from "@/features/inbox/constants.ts"
import type { ViewProps } from "@/features/inbox/types.ts"
import { Text, View } from "@instructure/ui"
import { useAppContext } from "../../AppContext.tsx"

export const DesktopView = ({
	conversations,
	setFilter,
	filterValue,
	setSelectedConversation,
	selectedConversation,
}: ViewProps) => {
	const { maxHeight } = useAppContext()

	return (
		<View
			as="div"
			className="flex-container flex-direction-column"
			height="100%"
		>
			<View as="div" height={INBOX_HEADER_HEIGHT}>
				<InboxHeader filterValue={filterValue} setFilter={setFilter} />
			</View>
			{conversations.length < 1 ? (
				<View as="div" width="100%" textAlign="center">
					<Text>No messages found</Text>
				</View>
			) : (
				<View
					as="div"
					className="flex-item-grow"
					height={maxHeight - INBOX_HEADER_HEIGHT - SCOPE_SELECTOR_HEIGHT}
				>
					<View as="div" height="100%">
						<View
							as="div"
							className="flex-container flex-align-item-start"
							height="100%"
						>
							<View
								as="div"
								className="flex-item-30"
								height="100%"
								borderWidth="none small none none"
								overflowY="auto"
							>
								<ConversationList
									conversations={conversations}
									selectedId={selectedConversation?.conversation.id}
									setSelectedConversation={setSelectedConversation}
								/>
							</View>
							<View
								as="div"
								className="flex-item-70"
								height="100%"
								overflowY="auto"
								overflowX="hidden"
								margin="none small"
							>
								{selectedConversation && (
									<SelectedConversation
										selectedConversation={selectedConversation}
										messageBodyHeight={
											maxHeight -
											INBOX_HEADER_HEIGHT -
											SCOPE_SELECTOR_HEIGHT -
											MESSAGE_HEADER_HEIGHT
										}
									/>
								)}
							</View>
						</View>
					</View>
				</View>
			)}
		</View>
	)
}
