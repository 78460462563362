import { Text, Tooltip } from "@instructure/ui"
import type React from "react"
import {
	type ComponentProps,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react"

type TooltipProps = React.ComponentProps<typeof Tooltip>
export type TruncatedTextProps = Omit<
	ComponentProps<typeof Text>,
	"themeOverride"
> & {
	text: string
	maxLines?: number
	shouldShowTooltip?: boolean
	tooltipColor?: TooltipProps["color"]
	tooltipPlacement?: TooltipProps["placement"]
}

const TruncateText = ({
	text,
	maxLines,
	onTruncate,
}: {
	text: string
	maxLines?: number
	onTruncate: (hasOverflow: boolean) => void
}) => {
	const textRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		const checkOverflow = () => {
			if (text && textRef.current && onTruncate) {
				const hasOverflow = maxLines
					? textRef.current.scrollHeight > textRef.current.clientHeight
					: textRef.current.scrollWidth > textRef.current.clientWidth
				onTruncate(hasOverflow)
			}
		}

		checkOverflow()
		window.addEventListener("resize", checkOverflow)

		return () => {
			window.removeEventListener("resize", checkOverflow)
		}
	}, [onTruncate, text, maxLines])

	return (
		<div
			ref={textRef}
			style={{
				overflow: "hidden",
				textOverflow: maxLines ? "clip" : "ellipsis",
				whiteSpace: maxLines ? "normal" : "nowrap",
				display: maxLines ? "-webkit-box" : "block",
				WebkitBoxOrient: maxLines ? "vertical" : undefined,
				WebkitLineClamp: maxLines || undefined,
			}}
		>
			{text}
		</div>
	)
}

export const CLXTruncateText = ({
	text,
	maxLines,
	shouldShowTooltip = true,
	tooltipColor = "primary",
	tooltipPlacement = "bottom center",
	...props
}: TruncatedTextProps) => {
	const [isTruncated, setIsTruncated] = useState<boolean>(false)

	const updateIsTruncatedIfChanged = useCallback(
		(truncated: boolean) => {
			if (isTruncated !== truncated) {
				setIsTruncated(truncated)
			}
		},
		[isTruncated],
	)

	const renderText = () => {
		return (
			<Text {...props}>
				<TruncateText
					text={text}
					onTruncate={updateIsTruncatedIfChanged}
					maxLines={maxLines}
				/>
			</Text>
		)
	}
	return (
		<>
			{shouldShowTooltip && isTruncated ? (
				<Tooltip
					renderTip={text}
					color={tooltipColor}
					placement={tooltipPlacement}
					constrain="parent"
				>
					{renderText()}
				</Tooltip>
			) : (
				renderText()
			)}
		</>
	)
}
