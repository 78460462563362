import { type RefObject, useEffect } from "react"

export function useOutsideClick(
	ref: RefObject<HTMLDivElement>,
	handleOutsideClick: (event: Event) => void,
) {
	function handleClickOutside(event: Event) {
		if (ref.current && !event.composedPath().includes(ref.current)) {
			handleOutsideClick(event)
		}
	}
	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		document.addEventListener("click", handleClickOutside)
		return () => {
			document.removeEventListener("click", handleClickOutside)
		}
	}, [ref])
}
