import { PAGEROUTE } from "@/shared/router"
import { Heading, View } from "@instructure/ui"
import { Link } from "react-router-dom"

export const LoginError = () => {
	return (
		<View as="div" padding="medium">
			<Heading level="h1" margin="small 0">
				Login Failed
			</Heading>
			<Link to={PAGEROUTE.LOGIN}>Try again</Link>
		</View>
	)
}
