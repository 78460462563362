import { useDeleteConversationMessages } from "@/api/mutations/conversation/useDeleteConversationMessage.ts"
import type { ConversationConnection } from "@/api/queries/useGetConversations.ts"
import { ConfirmationModal } from "@/shared/components/ConfirmationModal.tsx"
import { useToast } from "@/shared/components/Toast/ToastContext.tsx"
import { CLXTruncateText } from "@/shared/components/TruncateText/TruncateText.tsx"
import { formatDateForUser } from "@/shared/utils"
import { Flex, IconButton, IconTrashLine, Text, View } from "@instructure/ui"
import { useState } from "react"

type MessageBodyProps = {
	conversation: ConversationConnection
}

export const MessageBody = ({ conversation }: MessageBodyProps) => {
	const [selectedMessageIdForDelete, setSelectedMessageIdForDelete] = useState<
		string | null
	>(null)
	const participantNames =
		conversation.conversation.conversationParticipantsConnection.nodes.map(
			(participant) => participant.user.name,
		)
	const messages =
		conversation.conversation.conversationMessagesConnection.nodes

	const { mutate: deleteMessage } = useDeleteConversationMessages()
	const sendToast = useToast()

	return (
		<Flex direction="column" gap="small">
			<ConfirmationModal
				isOpen={selectedMessageIdForDelete !== null}
				titleText="Delete message"
				bodyText="Are you sure that you want to delete the selected message?"
				onConfirm={() => {
					if (selectedMessageIdForDelete == null) {
						return
					}
					deleteMessage(
						{ ids: [selectedMessageIdForDelete] },
						{
							onSuccess: () =>
								sendToast({
									message: "Message deleted successfully.",
									variant: "success",
								}),
							onError: () =>
								sendToast({
									message: "Message delete failed, please try again!",
									variant: "error",
								}),
						},
					)
					setSelectedMessageIdForDelete(null)
				}}
				onCancel={() => setSelectedMessageIdForDelete(null)}
			/>
			{messages.map((message) => {
				const recipients = participantNames.filter(
					(participantName) => participantName !== message.author.name,
				)
				return (
					<Flex.Item key={message.id}>
						<View as="div" borderWidth="small" borderRadius="large">
							<View as="div" padding="small" borderWidth="none none small none">
								<Flex justifyItems="space-between">
									<CLXTruncateText
										as="h2"
										size="small"
										text={message.author.name}
									/>
									<IconButton
										screenReaderLabel="Delete message"
										renderIcon={IconTrashLine}
										withBackground={false}
										shape="circle"
										withBorder={false}
										size="small"
										onClick={() => setSelectedMessageIdForDelete(message._id)}
									/>
								</Flex>
								<Text size="small">
									<Flex justifyItems="space-between">
										<CLXTruncateText size="small" text={`To: ${recipients}`} />
										<Text size="small">
											{formatDateForUser(message.createdAt)}
										</Text>
									</Flex>
								</Text>
							</View>
							<View as="div" padding="small">
								<Text>{message.body}</Text>
							</View>
						</View>
					</Flex.Item>
				)
			})}
		</Flex>
	)
}
