import { primitives } from "./primitives"

export const contrast = {
	white1010: primitives.white,
	grey1111: primitives.grey11,
	grey1214: primitives.grey12,
	grey1424: primitives.grey14,
	grey2424: primitives.grey24,
	grey4570: primitives.grey45,
	grey5782: primitives.grey57,
	grey100100: primitives.grey100,
	grey125125: primitives.grey125,
	blue1212: primitives.blue12,
	blue4570: primitives.blue45,
	blue5782: primitives.blue57,
	green1212: primitives.green12,
	green4570: primitives.green45,
	green5782: primitives.green57,
	orange1212: primitives.orange12,
	orange3045: primitives.orange30,
	orange4570: primitives.orange45,
	orange5782: primitives.orange57,
	red1212: primitives.red12,
	red4570: primitives.red45,
	red5782: primitives.red57,
}

// default contrasts removed: white1010op75, grey100100op75
// still not sure if we even need contrasts
