import type { Note } from "@/api/db/db"
import { useGetCourseName } from "@/api/queries/useGetCourses"
import { useSearchNotesByTextAndTag } from "@/api/queries/useSearchNotesByTextAndTag"
import { TagBlock, tagIconMap } from "@/shared/components/Notebook/TagBlock"
import { CLXPill } from "@/shared/components/Pill/Pill.tsx"
import { CLXTruncateText } from "@/shared/components/TruncateText/TruncateText.tsx"
import { FetchingError } from "@/shared/loading/FetchingError"
import { GlobalLoader } from "@/shared/loading/GlobalLoader"
import { SearchParams } from "@/shared/router"
import { PAGEROUTE } from "@/shared/router"
import { formatDateForNotes } from "@/shared/utils"
import {
	Flex,
	FlexItem,
	IconArrowStartSolid,
	IconButton,
	IconSearchLine,
	IconXSolid,
	ScreenReaderContent,
	Text,
	TextInput,
	View,
} from "@instructure/ui"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useAppContext } from "src/AppContext"
import { getUserId } from "src/token-storage"

const CourseNoteList = ({ notes }: { notes: Note[] }) => {
	const navigate = useNavigate()
	return (
		<Flex
			justifyItems="space-between"
			alignItems="center"
			wrap="wrap"
			gap="small small"
		>
			{notes.map((note) => {
				return (
					<FlexItem key={`${note.id}${note.createdAt}`} width={"48%"}>
						<div
							style={{
								overflow: "auto",
							}}
						>
							<View
								as="div"
								background="primary"
								borderRadius="large"
								padding="small"
								onClick={() => {
									navigate(
										`${note.itemUrl}?${SearchParams.VIEW_NOTE}=${note.id}`,
									)
								}}
							>
								<View as="div" height={"5rem"}>
									<CLXTruncateText
										size="small"
										text={note.text}
										maxLines={4}
										shouldShowTooltip={false}
									/>
								</View>
								<View
									as="div"
									borderColor="secondary"
									borderWidth="small 0 0 0"
									padding="small 0 0 0"
								>
									<Text>{formatDateForNotes(note.createdAt)}</Text>

									{note.tags.length ? (
										note.tags.map((tag) => (
											<CLXPill
												margin="xx-small"
												renderIcon={tagIconMap
													.find((e) => e.tag === tag)
													?.renderIcon()}
												key={tag}
												isBorderVisible={false}
											>
												{tag}
											</CLXPill>
										))
									) : (
										<View as="div" height={"2.2rem"} />
									)}
								</View>
							</View>
						</div>
					</FlexItem>
				)
			})}
		</Flex>
	)
}

export const CourseNotebook = ({ courseId }: { courseId: string }) => {
	const [searchParams, setSearchParams] = useSearchParams()
	const { setSearchText, tags, setTags, noteSearchQuery } =
		useSearchNotesByTextAndTag(getUserId() as string, courseId)
	const courseNameQuery = useGetCourseName(courseId)
	const navigate = useNavigate()
	const { desktopMode } = useAppContext()

	if (courseNameQuery.isLoading) {
		return <GlobalLoader title="Loading..." />
	}

	if (courseNameQuery.isError || !courseNameQuery.data) {
		return <FetchingError />
	}

	return (
		<View
			as="div"
			height={desktopMode ? "100vh" : "calc(100vh - 5.6rem)"}
			background="primary"
			padding="large 0 0 0"
		>
			<View
				as="div"
				background="secondary"
				padding="large medium medium"
				borderRadius="large large 0 0"
				minHeight={desktopMode ? "100vh" : "calc(100vh - 7.8rem)"}
				themeOverride={{
					borderRadiusLarge: "2rem",
				}}
			>
				<Flex justifyItems="space-between">
					<IconButton
						withBackground={false}
						withBorder={false}
						screenReaderLabel="Go back"
						onClick={() => {
							searchParams.delete("course")
							setSearchParams(searchParams)
						}}
					>
						<IconArrowStartSolid />
					</IconButton>
					<View as="div" textAlign="center">
						<Text size="large">{courseNameQuery.data?.courseName}</Text>
					</View>
					<IconButton
						withBackground={false}
						withBorder={false}
						screenReaderLabel="Go to homepage"
						onClick={() => navigate(PAGEROUTE.HOME)}
					>
						<IconXSolid />
					</IconButton>
				</Flex>
				<View as="div" margin="medium 0 medium 0">
					<TextInput
						renderLabel={<ScreenReaderContent>Search</ScreenReaderContent>}
						type="search"
						placeholder="Search..."
						renderBeforeInput={IconSearchLine}
						themeOverride={{
							borderRadius: "1rem",
						}}
						onChange={(event) => setSearchText(event.target.value)}
					/>
					<View as="div" margin="medium 0 0 0">
						<TagBlock
							currentTags={tags}
							setTags={setTags}
							title="Filter"
							isMultiSelect
						/>
					</View>
					<View as="div" margin="medium 0 0 0">
						<View as="div" margin="0 0 small 0">
							<Text>Notes</Text>
						</View>
						{noteSearchQuery.data && (
							<CourseNoteList notes={noteSearchQuery.data} />
						)}
					</View>
				</View>
			</View>
		</View>
	)
}
