import { useGetCourses } from "@/api/queries/useGetCourses.ts"
import { useGetUser } from "@/api/queries/useGetUser.ts"
import { FetchingError } from "@/shared/loading/FetchingError.tsx"
import { GlobalLoader } from "@/shared/loading/GlobalLoader.tsx"

import { useAppContext } from "src/AppContext.tsx"
import { getUserId } from "../../token-storage.ts"
import { DesktopHome } from "./DesktopHome.tsx"
import { MobileHome } from "./MobileHome.tsx"

const Home = () => {
	const userQuery = useGetUser(getUserId())
	const coursesQuery = useGetCourses(getUserId())
	const { desktopMode } = useAppContext()

	if (userQuery.isLoading || coursesQuery.isLoading) {
		return <GlobalLoader title="Loading..." renderInContent={true} />
	}

	if (userQuery.isError || coursesQuery.isError || !userQuery.data) {
		return <FetchingError />
	}

	return desktopMode ? (
		<DesktopHome
			userData={userQuery.data}
			coursesData={coursesQuery.data ?? []}
		/>
	) : (
		<MobileHome
			userData={userQuery.data}
			coursesData={coursesQuery.data ?? []}
		/>
	)
}

export default Home
