import { Heading, View } from "@instructure/ui"

const Unauthorized = () => (
	<View as="div" padding="medium">
		<Heading as="h1" margin="small 0">
			Unauthorized
		</Heading>
	</View>
)

export default Unauthorized
