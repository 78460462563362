import { Icon } from "@/icons/Icon.tsx"
import { generateComponentTheme } from "@/shared/components/IconButton/theme.ts"
import type { BaseButtonProps } from "@/shared/themes/generateButtonTheme.ts"
import type { BaseButtonTheme } from "@instructure/shared-types"
import { IconButton } from "@instructure/ui"
import type React from "react"

interface ButtonProps extends BaseButtonProps {
	screenReaderLabel: string
	shape?: "circle"
	clxIconName?: string
	children?: React.ReactNode
}

export const CLXIconButton = ({
	level,
	variant,
	size = "medium",
	shape = "circle",
	...props
}: ButtonProps) => (
	<IconButton
		{...props}
		size={size}
		shape={shape}
		color={level}
		themeOverride={
			generateComponentTheme(level, variant) as () => Partial<BaseButtonTheme>
		}
	>
		{props.children || <Icon name={props.clxIconName} />}
	</IconButton>
)
