import type { LinkTheme } from "@instructure/shared-types"
import { borders } from "../borders"
import { spacing } from "../spacing"
import { typography } from "../typography"
import { ui } from "../ui"

const LinkThemeOverride: Partial<LinkTheme> = {
	fontFamily: typography.fontFamily,
	fontWeight: typography.fontWeightNormal,
	color: ui.surfaceInversePrimary,

	textDecorationWithinText: "underline",
	hoverTextDecorationWithinText: "underline",
	textDecorationOutsideText: "none",
	hoverTextDecorationOutsideText: "none",

	focusOutlineWidth: borders.widthMedium,
	focusOutlineColor: ui.surfaceInversePrimary,
	focusOutlineStyle: borders?.style,
	focusOutlineBorderRadius: borders?.radiusSmall,

	hoverColor: ui.surfaceInverseSecondary,

	iconSize: "1.225em",
	iconPlusTextMargin: spacing.xxSmall,
}

export default {
	Link: LinkThemeOverride,
}
