import { OptionalLink } from "@/shared/components/OptionalLink.tsx"
import {
	Actor,
	type Chat,
	type Prompt,
} from "@/shared/learning_assist/types.ts"
import { makeLink } from "@/shared/learning_assist/utils.ts"
import { LearningObjectIcon } from "@/shared/learning_object_metadata/LearningObjectIcon.tsx"
import { createMarkup } from "@/shared/utils"
import { IconButton, IconLikeLine, Text, View } from "@instructure/ui"
import type { CSSProperties } from "react"

const CHAT_STYLES: Record<Actor, CSSProperties> = {
	[Actor.User]: {
		display: "inline-block",
		margin: "0 0 0 2.25rem",
		backgroundColor: "white",
		mixBlendMode: "screen",
	},
	[Actor.Assistant]: {
		display: "inline-block",
		margin: "0 2.25rem 0 0",
		backgroundColor: "rgba(249, 245, 239, 0.3)",
		color: "white",
	},
}

const PROMPT_STYLES: CSSProperties = {
	display: "block",
	backgroundColor: "rgba(249, 245, 239, 0.3)",
	color: "white",
}

export const ChatEntry = ({
	courseId,
	chat,
	prompt,
	showFeedbackControls = false,
}: {
	courseId: string
	chat?: Chat
	prompt?: Prompt
	showFeedbackControls?: boolean
}) => {
	let computedEntryStyles: CSSProperties = {}
	if (chat) {
		computedEntryStyles = CHAT_STYLES[chat.actor]
	}
	if (prompt) {
		computedEntryStyles = PROMPT_STYLES
	}

	return (
		<View
			as="div"
			margin="0 0 small 0"
			textAlign={chat?.actor === Actor.User ? "end" : "start"}
		>
			<div
				style={{
					padding: "1.5rem",
					borderRadius: "1rem",
					textAlign: "start",
					...computedEntryStyles,
				}}
			>
				{chat?.actor === Actor.Assistant && (
					<View as="div">
						<Text size="x-small">AI Assist</Text>
					</View>
				)}

				<View as="div" margin="xx-small 0">
					{chat?.htmlMessage ? (
						// biome-ignore lint/security/noDangerouslySetInnerHtml: sanitized
						<div dangerouslySetInnerHTML={createMarkup(chat.htmlMessage)} />
					) : (
						<Text>{chat?.message || prompt?.longMessage}</Text>
					)}
				</View>

				{chat?.citations && (
					<View as="div">
						{chat?.citations.map((citation) => (
							<View
								as="div"
								key={`${citation.objectType}_${citation.objectId}`}
							>
								<OptionalLink
									href={
										citation.objectType === "Syllabus"
											? undefined
											: makeLink(
													courseId,
													citation.objectType,
													citation.objectId,
												)
									}
									target="_blank"
									isWithinText={false}
								>
									<Text
										color="secondary-inverse"
										size="small"
										fontStyle="italic"
									>
										{citation.objectType !== "Syllabus" && (
											<LearningObjectIcon type={citation.objectType} />
										)}{" "}
										{citation.objectName}
									</Text>
								</OptionalLink>
							</View>
						))}
					</View>
				)}
			</div>
			{showFeedbackControls && (
				<View as="div" margin="xxx-small small">
					<IconButton
						screenReaderLabel="Bad response"
						color="primary-inverse"
						withBorder={false}
						withBackground={false}
						size="small"
					>
						<IconLikeLine rotate="180" />
					</IconButton>
					<IconButton
						screenReaderLabel="Good response"
						color="primary-inverse"
						withBorder={false}
						withBackground={false}
						size="small"
					>
						<IconLikeLine />
					</IconButton>
				</View>
			)}
		</View>
	)
}
