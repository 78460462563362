import type { ConversationConnection } from "@/api/queries/useGetConversations.ts"
import { MessageBody } from "@/features/inbox/MessageBody.tsx"
import { MessageHeader } from "@/features/inbox/MessageHeader.tsx"
import { SendMessageModal } from "@/features/inbox/SendMessageModal.tsx"
import { getUserId } from "@/token-storage.ts"
import { View } from "@instructure/ui"
import { useMemo, useState } from "react"

type SelectedConversationProps = {
	onBack?: () => void
	selectedConversation: ConversationConnection
	messageBodyHeight: number
}

export const SelectedConversation = ({
	selectedConversation,
	onBack,
	messageBodyHeight,
}: SelectedConversationProps) => {
	const userId = getUserId() || ""
	const [isSendMessageModalOpen, setIsSendMessageModalOpen] =
		useState<boolean>(false)
	const { currentRecipients, includedMessages } = useMemo(() => {
		const participants =
			selectedConversation.conversation.conversationParticipantsConnection.nodes
				.map((participant) => participant.user)
				.filter((user) => user._id !== userId)
		const messageIds =
			selectedConversation.conversation.conversationMessagesConnection.nodes.map(
				(message) => message._id,
			)
		return { includedMessages: messageIds, currentRecipients: participants }
	}, [selectedConversation, userId])

	return (
		<View as="div">
			<MessageHeader
				onBack={onBack}
				onReply={() => setIsSendMessageModalOpen(true)}
				conversationId={selectedConversation.conversation._id}
				subject={selectedConversation.conversation.subject}
			/>
			<View
				as="div"
				padding="small"
				height={messageBodyHeight}
				overflowX="auto"
			>
				<MessageBody conversation={selectedConversation} />
			</View>
			<SendMessageModal
				isOpen={isSendMessageModalOpen}
				conversationId={selectedConversation.conversation._id}
				currentSubject={selectedConversation.conversation.subject}
				closeModal={() => setIsSendMessageModalOpen(false)}
				currentRecipients={currentRecipients}
				includedMessages={includedMessages}
			/>
		</View>
	)
}
