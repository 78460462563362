import { type UpdateNote, updateNote } from "@/api/db/db"
import { QUERY_KEYS } from "@/api/queryKeys"
import { useMutation, useQueryClient } from "@tanstack/react-query"

export const useUpdateNote = () => {
	const queryClient = useQueryClient()
	const mutationResult = useMutation({
		mutationFn: ({ id, note }: { id: number; note: UpdateNote }) =>
			updateNote(id, note),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: QUERY_KEYS.NOTE.all })
		},
	})

	return mutationResult
}
