import type { ModuleItemType } from "@/api/types.ts"
import { NoteTaking } from "@/shared/components/Notebook/NoteTaking"
import { LearningAssist } from "@/shared/learning_assist/LearningAssist.tsx"
import { LearningObjectIcon } from "@/shared/learning_object_metadata/LearningObjectIcon.tsx"
import { LearningObjectName } from "@/shared/learning_object_metadata/LearningObjectName.tsx"
import { SearchParams } from "@/shared/router"
import {
	Flex,
	IconAiLine,
	IconAudioLine,
	IconBookmarkLine,
	IconButton,
	IconClockLine,
	ProgressBar,
	Text,
	View,
	canvas,
} from "@instructure/ui"
import { type ReactNode, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { useAppContext } from "src/AppContext"
import {
	AI_GRADIENT,
	CONTENT_HEADER_HEIGHT,
	CONTENT_MENU_HEIGHT,
	MOBILE_MENU_HEIGHT,
} from "src/constants"

export const ContentWrapper = ({
	children,
	name,
	type,
	programName,
	courseId,
	estimatedTime,
	learningAssistContext,
	dueAt = "XX/XX",
}: {
	children: ReactNode
	name: string
	type: ModuleItemType
	programName: string
	courseId: string
	estimatedTime?: string
	learningAssistContext?: string
	dueAt?: string
}) => {
	const [showingTutorModal, setShowingTutorModal] = useState(false)
	const { desktopMode } = useAppContext()
	const [_, setSearchParams] = useSearchParams()

	return (
		<Flex
			as="div"
			height={desktopMode ? "100vh" : `calc(100vh - ${MOBILE_MENU_HEIGHT}px)`}
			direction="column"
		>
			<div>
				<View
					as="div"
					background="secondary"
					padding="large medium medium"
					height={CONTENT_HEADER_HEIGHT}
				>
					<View as="div" textAlign="center">
						<Text size="large">{name}</Text>
					</View>
					<Flex
						as="div"
						justifyItems="space-between"
						gap="small"
						margin="small 0 xx-small"
					>
						<Flex.Item>
							<Text size="small">
								<LearningObjectIcon type={type} />{" "}
								<LearningObjectName type={type} />
							</Text>
						</Flex.Item>
						<Flex.Item>
							{estimatedTime != null && (
								<Text size="small">
									<IconClockLine /> {estimatedTime}
								</Text>
							)}
						</Flex.Item>
					</Flex>
					<Text size="small" color="secondary">
						{programName}
					</Text>
					<ProgressBar
						themeOverride={{
							trackColor: canvas.colors.primitives.grey14,
							meterColorBrand: canvas.colors.primitives.grey125,
						}}
						meterColor="brand"
						screenReaderLabel="Not started"
						valueNow={0}
						valueMax={100}
						margin="medium 0 xxx-small"
						size="x-small"
					/>
					<Flex as="div" justifyItems="space-between" gap="small">
						<Flex.Item>
							<Text size="small">Not Started</Text>
						</Flex.Item>
						<Flex.Item>
							<Text size="small">{`Due ${dueAt}`}</Text>
						</Flex.Item>
					</Flex>
				</View>
			</div>

			<div
				style={{
					height: `calc(100% - ${CONTENT_HEADER_HEIGHT + CONTENT_MENU_HEIGHT}px)`,
					overflowY: "auto",
				}}
			>
				{children}
			</div>

			<div
				style={{
					height: CONTENT_MENU_HEIGHT,
					position: "fixed",
					bottom: desktopMode ? 0 : `${MOBILE_MENU_HEIGHT}px`,
					width: desktopMode ? "75%" : "100%",
				}}
			>
				<View as="div" background="primary">
					<Flex as="div" justifyItems="center" padding="medium" gap="small">
						<Flex.Item>
							<IconButton
								shape="circle"
								withBorder={false}
								screenReaderLabel="Listen to this"
								size="large"
							>
								<IconAudioLine width="18px" />
							</IconButton>
						</Flex.Item>
						{learningAssistContext && (
							<Flex.Item>
								<IconButton
									shape="circle"
									withBorder={false}
									screenReaderLabel="AI"
									size="large"
									onClick={() => setShowingTutorModal(true)}
									themeOverride={{
										secondaryBackground: AI_GRADIENT,
										secondaryHoverBackground: AI_GRADIENT,
										secondaryColor: "white",
									}}
								>
									<IconAiLine width="18px" />
								</IconButton>
							</Flex.Item>
						)}
						<Flex.Item>
							<IconButton
								shape="circle"
								withBorder={false}
								screenReaderLabel="Add to notebook"
								size="large"
								onClick={() => {
									setSearchParams({ [SearchParams.ADD_NOTE]: "true" })
								}}
							>
								<IconBookmarkLine width="18px" />
							</IconButton>
						</Flex.Item>
					</Flex>
				</View>
			</div>

			{learningAssistContext && (
				<LearningAssist
					isOpen={showingTutorModal}
					dismiss={() => setShowingTutorModal(false)}
					context={learningAssistContext}
				/>
			)}

			{courseId && (
				<NoteTaking courseId={courseId} institutionName="Dummy institution" />
			)}
		</Flex>
	)
}
