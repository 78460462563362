import {
	generatePillComponentTheme,
	generatePillTextTheme,
} from "@/shared/components/Pill/theme.ts"
import type { PillTheme, TextTheme } from "@instructure/shared-types"
import { Pill, Text } from "@instructure/ui"
import type React from "react"

type PillProps = React.ComponentProps<typeof Pill>

export type CLXPillProps = Omit<PillProps, "children" | "themeOverride"> & {
	children: string
	isUpperCase?: boolean
	isBorderVisible?: boolean
}

export const CLXPill = ({
	children,
	isUpperCase = false,
	isBorderVisible = true,
	statusLabel,
	...props
}: CLXPillProps) => (
	<Pill
		{...props}
		statusLabel={isUpperCase ? statusLabel?.toUpperCase() : statusLabel}
		themeOverride={
			generatePillComponentTheme(isBorderVisible) as () => Partial<PillTheme>
		}
	>
		<Text
			size="x-small"
			themeOverride={
				generatePillTextTheme(isUpperCase) as () => Partial<TextTheme>
			}
		>
			{isUpperCase ? children.toUpperCase() : children}
		</Text>
	</Pill>
)
