import type { UpdateNote } from "@/api/db/db"
import { useCreateNote } from "@/api/mutations/useCreateNote"
import { useUpdateNote } from "@/api/mutations/useUpdateNote"
import { useGetCourseName } from "@/api/queries/useGetCourses"
import { useGetNotebyId } from "@/api/queries/useGetNoteById"
import { useOutsideClick } from "@/shared/hooks/useOutsideClick"
import { FetchingError } from "@/shared/loading/FetchingError"
import { GlobalLoader } from "@/shared/loading/GlobalLoader"
import { SearchParams } from "@/shared/router/index"
import { useCallback, useEffect, useRef, useState } from "react"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { useAppContext } from "src/AppContext"
import { getUserId } from "src/token-storage"
import { NoteEditor } from "./NoteEditor"
import { NoteView } from "./NoteView"

const NoteEdit = ({ edit }: { edit: number }) => {
	const noteQuery = useGetNotebyId(edit)
	const navigate = useNavigate()
	const updateNoteMutation = useUpdateNote()
	useEffect(() => {
		if (updateNoteMutation.isSuccess && noteQuery.data) {
			navigate(`${noteQuery.data?.itemUrl}?${SearchParams.VIEW_NOTE}=${edit}`)
		}
	}, [updateNoteMutation, noteQuery, navigate, edit])

	if (updateNoteMutation.isPending || noteQuery.isLoading) {
		return <GlobalLoader title="Loading..." />
	}

	if (updateNoteMutation.isError || !noteQuery.data) {
		return <FetchingError />
	}

	return (
		<NoteEditor
			key={edit}
			note={noteQuery.data}
			mutation={(note: UpdateNote) =>
				updateNoteMutation.mutate({ id: edit, note })
			}
		/>
	)
}

const NoteAdd = ({
	itemUrl,
	courseId,
	institutionName,
}: {
	itemUrl: string
	courseId: string
	institutionName: string
}) => {
	const courseQuery = useGetCourseName(courseId)
	const addNoteMutation = useCreateNote()
	const navigate = useNavigate()

	useEffect(() => {
		if (addNoteMutation.isSuccess && addNoteMutation.data) {
			navigate(`${itemUrl}?${SearchParams.VIEW_NOTE}=${addNoteMutation.data}`)
		}
	}, [addNoteMutation, navigate, itemUrl])

	if (addNoteMutation.isPending || courseQuery.isLoading) {
		return <GlobalLoader title="Loading..." />
	}

	if (addNoteMutation.isError || !courseQuery.data) {
		return <FetchingError />
	}

	const newNote = {
		userId: getUserId() as string,
		courseId,
		courseName: courseQuery.data.courseName,
		text: "",
		institutionName,
		tags: [],
		itemUrl,
		createdAt: undefined,
	}
	return <NoteEditor note={newNote} mutation={addNoteMutation.mutate} />
}

export const NoteTaking = ({
	courseId,
	institutionName,
}: {
	courseId: string
	institutionName: string
}) => {
	const location = useLocation()
	const [searchParams, setSearchParams] = useSearchParams()
	const ref = useRef<HTMLDivElement>(null)
	const [open, setOpen] = useState<boolean>(false)
	const [edit, setEdit] = useState<number | undefined>()
	const [view, setView] = useState<number | undefined>()
	const { desktopMode } = useAppContext()

	useEffect(() => {
		const isAdd = !!searchParams.get(SearchParams.ADD_NOTE)
		const edit = searchParams.get(SearchParams.EDIT_NOTE)
		const view = searchParams.get(SearchParams.VIEW_NOTE)
		const isEdit = !!edit
		const isView = !!view
		setOpen(isAdd || isEdit || isView)
		if (isEdit) {
			setEdit(Number.parseInt(edit))
		} else {
			setEdit(undefined)
		}
		if (isView) {
			setView(Number.parseInt(view))
		} else {
			setView(undefined)
		}
	}, [searchParams])

	const onClose = useCallback(() => {
		searchParams.delete(SearchParams.ADD_NOTE)
		searchParams.delete(SearchParams.EDIT_NOTE)
		searchParams.delete(SearchParams.VIEW_NOTE)
		setSearchParams(searchParams)
	}, [searchParams, setSearchParams])

	useOutsideClick(ref, onClose)

	if (!open) {
		return null
	}

	return (
		<div
			style={{
				position: "fixed",
				bottom: 0,
				width: desktopMode ? "75%" : "100%",
			}}
			ref={ref}
		>
			{view ? (
				<NoteView id={view} />
			) : edit ? (
				<NoteEdit edit={edit} />
			) : (
				<NoteAdd
					itemUrl={location.pathname}
					courseId={courseId}
					institutionName={institutionName}
				/>
			)}
		</div>
	)
}
