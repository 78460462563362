import i18next from "@/config/i18n"
import { PAGEROUTE, generateRoute } from "@/shared/router"
import {
	Flex,
	IconButton,
	IconCoursesLine,
	IconSearchLine,
	IconUserLine,
	Menu,
	ScreenReaderContent,
	TextInput,
} from "@instructure/ui"
import { Link, useNavigate } from "react-router-dom"
import NotificationIcon from "./NotificationIcon"

export const SearchHeader = () => {
	const navigate = useNavigate()
	return (
		<Flex margin="large medium">
			<Flex.Item shouldGrow={true} shouldShrink={true}>
				<TextInput
					renderLabel={<ScreenReaderContent>Search</ScreenReaderContent>}
					type="search"
					placeholder="Search..."
					renderBeforeInput={IconSearchLine}
				/>
			</Flex.Item>
			<Flex.Item>
				<Link to={PAGEROUTE.NOTEBOOK}>
					<IconButton
						screenReaderLabel="Notebook"
						renderIcon={IconCoursesLine}
						withBackground={false}
						withBorder={false}
						margin="0 0 0 small"
					/>
				</Link>
				<NotificationIcon />
				<Menu
					placement="bottom"
					trigger={
						<IconButton
							screenReaderLabel="User"
							renderIcon={IconUserLine}
							withBackground={false}
							withBorder={false}
						/>
					}
				>
					<Menu.Item
						onClick={() => navigate(generateRoute(PAGEROUTE.LOGOUT, {}))}
					>
						Logout
					</Menu.Item>
					<Menu.Item
						onClick={() =>
							i18next.changeLanguage(i18next.language === "en" ? "fr" : "en")
						}
					>
						Change Language
					</Menu.Item>
				</Menu>
			</Flex.Item>
		</Flex>
	)
}
