import { useGetCourseOverview } from "@/api/queries/useGetCourses.ts"
import { FetchingError } from "@/shared/loading/FetchingError.tsx"
import { GlobalLoader } from "@/shared/loading/GlobalLoader.tsx"
import { View } from "@instructure/ui"
import "./styles.css"
import { createMarkup } from "@/shared/utils"

export const CourseOverview = ({ courseId }: { courseId: string }) => {
	const overviewQuery = useGetCourseOverview(courseId)

	if (overviewQuery.isLoading) {
		return <GlobalLoader title="Loading..." />
	}

	if (overviewQuery.isError || !overviewQuery.data) {
		return <FetchingError />
	}

	return (
		<View as="div">
			{overviewQuery.data.courseOverview && (
				<div
					// biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
					dangerouslySetInnerHTML={createMarkup(
						overviewQuery.data.courseOverview,
					)}
				/>
			)}
		</View>
	)
}
