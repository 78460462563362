import { formatNumberToPercentage } from "@/shared/utils"
import { ProgressBar as InstUIProgressBar, canvas } from "@instructure/ui"

type Props = {
	readonly valueNow: number
	readonly valueMax: number
	readonly completionPercentage: number
	readonly renderValue?: () => JSX.Element
}

function ProgressBar({
	valueMax,
	valueNow,
	completionPercentage,
	renderValue,
}: Props) {
	return (
		<InstUIProgressBar
			themeOverride={{
				trackColor: canvas.colors.primitives.grey14,
				meterColorBrand: canvas.colors.primitives.grey125,
			}}
			meterColor="brand"
			screenReaderLabel={`${formatNumberToPercentage(
				completionPercentage,
			)} Complete`}
			margin="small 0"
			valueNow={valueNow}
			valueMax={valueMax || 100}
			renderValue={renderValue ?? renderValue}
		/>
	)
}

export default ProgressBar
