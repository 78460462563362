import { z } from "zod"

const TOKEN_DATA_KEY = "access_token_data"
const ACCESS_TOKEN_EXPIRATION_MS = 60 * 60 * 1000
const REFRESH_TOKEN_EXPIRATION_MS = 2 * 60 * 60 * 1000
const TOKEN_EXPIRATION_OFFSET_MS = 61 * 1000

const ZTokenData = z
	.object({
		userId: z.string(),
		isMasquerading: z.boolean(),
		isTestStudent: z.boolean(),
		access: z.object({
			token: z.string(),
			expirationDate: z.coerce.date(),
		}),
		refresh: z.object({
			token: z.string(),
			expirationDate: z.coerce.date(),
		}),
	})
	.optional()

type TokenData = z.infer<typeof ZTokenData>

const getTokenData = (): TokenData => {
	const data = localStorage.getItem(TOKEN_DATA_KEY)
	if (!data) {
		return undefined
	}
	try {
		const tokenData = JSON.parse(data)
		ZTokenData.parse(tokenData)
		return tokenData
	} catch (e) {
		console.error(e)
		return undefined
	}
}

export const setTokenData = ({
	userId,
	accessToken,
	refreshToken,
	isMasquerading = false,
	isTestStudent = false,
}: {
	userId: string
	accessToken: string
	refreshToken: string
	isMasquerading?: boolean
	isTestStudent?: boolean
}) => {
	localStorage.setItem(
		TOKEN_DATA_KEY,
		JSON.stringify({
			userId,
			isMasquerading: isMasquerading,
			isTestStudent: isTestStudent,
			access: {
				token: accessToken,
				expirationDate: new Date(
					new Date().getTime() +
						ACCESS_TOKEN_EXPIRATION_MS -
						TOKEN_EXPIRATION_OFFSET_MS,
				),
			},
			refresh: {
				token: refreshToken,
				expirationDate: new Date(
					new Date().getTime() +
						REFRESH_TOKEN_EXPIRATION_MS -
						TOKEN_EXPIRATION_OFFSET_MS,
				),
			},
		}),
	)
}

export const clearTokenData = () => {
	localStorage.removeItem(TOKEN_DATA_KEY)
}

export const getUserId = () => {
	const tokenData = getTokenData()
	return tokenData?.userId
}

export const getIsMasquerading = () => {
	const tokenData = getTokenData()
	return !!tokenData?.isMasquerading
}

export const getIsTestStudent = () => {
	const tokenData = getTokenData()
	return !!tokenData?.isTestStudent
}

export const getAccessToken = () => {
	const tokenData = getTokenData()
	if (tokenData && new Date(tokenData.access.expirationDate) > new Date()) {
		return tokenData.access.token
	}
	return undefined
}

export const getRefreshToken = () => {
	const tokenData = getTokenData()
	if (tokenData && new Date(tokenData.refresh.expirationDate) > new Date()) {
		return tokenData.refresh.token
	}
	return undefined
}

export const subscribeOnceToChanges = (callback: () => void) => {
	const storageListener = (event: StorageEvent) => {
		if (event.key === TOKEN_DATA_KEY) {
			callback()
			window.removeEventListener("storage", storageListener)
		}
	}
	window.addEventListener("storage", storageListener)
}
