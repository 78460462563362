import { useCreateSubmission } from "@/api/mutations/useCreateSubmission.ts"
import { AssignmentSubmissionControls } from "@/features/learning_content/AssignmentSubmissionControls.tsx"
import { View } from "@instructure/ui"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { RCEInput } from "src/shared/components/RCE/RichContentInput/RCEInput.tsx"

export const TextEntrySubmissionForm = () => {
	const { assignmentId = "" } = useParams()
	const [textEntry, setTextEntry] = useState("")
	const createSubmissionMutation = useCreateSubmission()

	return (
		<View as="div" margin="small 0">
			<RCEInput
				label="Text entry submission"
				defaultContent={textEntry}
				onChange={(editorContent) => {
					setTextEntry(editorContent)
				}}
				textareaId={"Submission"}
			/>
			<AssignmentSubmissionControls
				entryValid={textEntry.length > 0}
				onSubmit={() =>
					createSubmissionMutation.mutate({
						assignmentLid: assignmentId,
						submissionType: "online_text_entry",
						body: textEntry,
					})
				}
				isPending={createSubmissionMutation.isPending}
				isSuccess={createSubmissionMutation.isSuccess}
				errorMessage={createSubmissionMutation.error?.message}
			/>
		</View>
	)
}
