import { useGetCourses } from "@/api/queries/useGetCourses.ts"
import { SearchHeader } from "@/shared/header/SearchHeader.tsx"
import { FetchingError } from "@/shared/loading/FetchingError.tsx"
import { GlobalLoader } from "@/shared/loading/GlobalLoader.tsx"
import ProgressBar from "@/shared/progress_bar/ProgressBar.tsx"
import { PAGEROUTE, generateRoute } from "@/shared/router"
import {
	formatNumberToPercentage,
	getCourseProgressionValues,
} from "@/shared/utils"
import { Flex, Text, View } from "@instructure/ui"
import { Link } from "react-router-dom"
import { getUserId } from "../../token-storage.ts"

export const Programs = () => {
	const coursesQuery = useGetCourses(getUserId())

	if (coursesQuery.isLoading) {
		return <GlobalLoader title="Loading..." renderInContent={true} />
	}

	if (coursesQuery.isError || !coursesQuery.data) {
		return <FetchingError />
	}

	return (
		<>
			<SearchHeader />
			<View as="div" margin="medium">
				{coursesQuery.data.map((course) => {
					const { completed, total, completionPercentage } =
						getCourseProgressionValues(
							course.course.usersConnection.nodes[0].courseProgression,
						)
					return (
						<Link
							key={course.course._id}
							to={generateRoute(PAGEROUTE.COURSE, {
								courseId: course.course._id,
							})}
							style={{
								textDecoration: "none",
							}}
						>
							<View
								as="div"
								background="secondary"
								margin="medium 0"
								padding="medium"
							>
								<Text size="small">{course.course.account.name}</Text>
								<View as="div" margin="small 0">
									<Text size="large">{course.course.name}</Text>
								</View>
								<ProgressBar
									valueNow={completed}
									valueMax={total}
									completionPercentage={completionPercentage}
								/>
								<Flex as="div" justifyItems="space-between" margin="xx-small 0">
									<Flex.Item>
										<Text size="small">
											{formatNumberToPercentage(
												course.course.usersConnection.nodes[0].courseProgression
													?.requirements.completionPercentage ?? 0,
											)}
										</Text>
									</Flex.Item>
									<Flex.Item>
										<Text size="small">
											{getProgressText(
												course.course.usersConnection.nodes[0].courseProgression
													?.requirements.completionPercentage ?? 0,
											)}
										</Text>
									</Flex.Item>
								</Flex>
							</View>
						</Link>
					)
				})}
			</View>
		</>
	)
}

function getProgressText(progress: number) {
	switch (progress) {
		case 0:
			return "Not Started"
		case 100:
			return "Completed"
		default:
			return "On Track"
	}
}
