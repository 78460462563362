import { useGetAssignment } from "@/api/queries/useGetAssignment.ts"
import {
	AssignmentSubmissionTypeSelector,
	SUBMISSION_TYPE_LABELS,
} from "@/features/learning_content/AssignmentSubmissionTypeSelector.tsx"
import { ContentWrapper } from "@/features/learning_content/ContentWrapper.tsx"
import { ExternalToolAssignment } from "@/features/learning_content/ExternalToolAssignment.tsx"
import { FetchingError } from "@/shared/loading/FetchingError.tsx"
import { GlobalLoader } from "@/shared/loading/GlobalLoader.tsx"
import { createMarkup, formatDate } from "@/shared/utils"
import { Button, Flex, Text, View } from "@instructure/ui"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { Submissions } from "./Submissions"
import { ViewSubmission } from "./ViewSubmission"

export const Assignment = () => {
	const { courseId = "", assignmentId = "" } = useParams()
	const [showingSubmissionForm, setShowingSubmissionForm] = useState(false)
	const [showingAllSubmissions, setShowingAllSubmissions] = useState(false)
	const assignmentQuery = useGetAssignment({
		courseId,
		assignmentId,
	})

	if (assignmentQuery.isLoading) {
		return <GlobalLoader title="Loading..." />
	}

	if (assignmentQuery.isError || !assignmentQuery.data) {
		return <FetchingError />
	}

	const {
		submissionTypes,
		allowedAttempts,
		submissionsConnection,
		name,
		courseName,
		dueAt,
		pointsPossible,
		description,
	} = assignmentQuery.data

	const isExternalToolAssignment = submissionTypes.includes("external_tool")
	const attemptsString = allowedAttempts || "Unlimited"
	const potentialSubmissionTypes = submissionTypes.filter((type) =>
		Object.keys(SUBMISSION_TYPE_LABELS).includes(type),
	)
	const hasSubmissions = submissionsConnection.nodes.length > 0

	const attemptButtonLabel = () => {
		if (potentialSubmissionTypes.length === 0) {
			return "Submission Type Not Supported"
		}
		if (hasSubmissions) {
			return "Reattempt Assignment"
		}
		return "Start Assignment"
	}

	return (
		<ContentWrapper
			name={name}
			type="Assignment"
			programName={courseName}
			estimatedTime="20 Mins"
			courseId={courseId}
			dueAt={dueAt ? formatDate(dueAt) : undefined}
		>
			{showingAllSubmissions ? (
				<Submissions
					submissionId={submissionsConnection.nodes[0].id}
					pointsPossible={pointsPossible}
					onClick={() => setShowingAllSubmissions(false)}
				/>
			) : (
				<Flex direction="column" padding="medium" height="100%">
					{pointsPossible !== null && (
						<View as="div" margin="x-small 0">
							<Text weight="light">{`${pointsPossible} points`}</Text>
						</View>
					)}
					<View as="div" margin="x-small 0">
						<Text weight="light">{`${attemptsString} Attempts Allowed`}</Text>
					</View>

					<div
						// biome-ignore lint/security/noDangerouslySetInnerHtml: sanitized by createMarkup
						dangerouslySetInnerHTML={createMarkup(description || "")}
					/>

					{isExternalToolAssignment ? (
						<ExternalToolAssignment title={name} />
					) : (
						<>
							{hasSubmissions && (
								<ViewSubmission
									{...submissionsConnection.nodes[0]}
									isLastSubmission
									pointsPossible={pointsPossible}
								/>
							)}
							{!showingSubmissionForm && (
								<View as="div" margin="small 0">
									<Button
										display="block"
										interaction={
											potentialSubmissionTypes.length > 0
												? "enabled"
												: "disabled"
										}
										onClick={() => setShowingSubmissionForm(true)}
									>
										{attemptButtonLabel()}
									</Button>
								</View>
							)}
							{hasSubmissions && (
								<View as="div" margin="small 0">
									<Button
										display="block"
										color="secondary"
										onClick={() => setShowingAllSubmissions(true)}
									>
										View All Attempts
									</Button>
								</View>
							)}

							{showingSubmissionForm && (
								<View as="div" margin="medium 0">
									<AssignmentSubmissionTypeSelector
										submissionTypes={potentialSubmissionTypes}
									/>
								</View>
							)}
						</>
					)}
				</Flex>
			)}
		</ContentWrapper>
	)
}
