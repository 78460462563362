import type { NotificationsResponse } from "@/api/queries/useGetNotification"
import { getDay } from "@/shared/utils"
import { Flex, Text, View } from "@instructure/ui"

type Props = {
	notifications: NotificationsResponse
}

function NotificationList({ notifications }: Props) {
	return (
		<Flex direction="column" gap="medium">
			{notifications.map((notification) => (
				<Flex.Item key={notification.plannable_id}>
					<View
						as="div"
						minHeight="10rem"
						borderRadius="large"
						padding="medium"
						background="secondary"
					>
						<Flex justifyItems="space-between">
							<Text as="p">{notification.plannable_type}</Text>
							<Text>{getDay(notification.plannable_date)}</Text>
						</Flex>
						<Text as="p">{notification.plannable.title}</Text>
					</View>
				</Flex.Item>
			))}
		</Flex>
	)
}

export default NotificationList
