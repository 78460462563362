// return element where flash screenreader messages go.
// create if necessary

function getLiveRegion(): HTMLElement {
	let liveRegion = document.getElementById("flash_screenreader_holder")
	if (!liveRegion) {
		liveRegion = document.createElement("div")
		liveRegion.id = "flash_screenreader_holder"
		liveRegion.setAttribute("role", "alert")
		document.body.appendChild(liveRegion)
	}
	return liveRegion
}

export default getLiveRegion
