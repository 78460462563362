import { useState } from "react"
import "./Flashcard.css"
import { createMarkup } from "@/shared/utils"

export interface FlashcardStyle {
	readonly frontCardStyle?: React.CSSProperties
	readonly frontContentStyle?: React.CSSProperties
	readonly backCardStyle?: React.CSSProperties
	readonly backContentStyle?: React.CSSProperties
}

export interface FlashcardProps extends FlashcardStyle {
	readonly id: string
	readonly frontHTML: string | JSX.Element
	readonly backHTML: string | JSX.Element
	readonly className?: string
	readonly height?: string
	readonly borderRadius?: string
	readonly width?: string
	readonly style?: React.CSSProperties
}

function Flashcard({
	frontHTML,
	frontCardStyle,
	frontContentStyle,
	backHTML,
	backCardStyle,
	backContentStyle,
	className = "",
	style,
	borderRadius = "1rem",
	width,
}: FlashcardProps) {
	const [isFlipped, setIsFlipped] = useState(false)

	return (
		<div
			className={`FlashcardWrapper ${className}`}
			style={{
				width: width,
				...style,
			}}
		>
			<div
				className={`FlashcardWrapper__item ${
					isFlipped ? "FlashcardWrapper__item--flip" : ""
				}`}
				style={{
					borderRadius: borderRadius,
				}}
				onClick={() => {
					setIsFlipped(!isFlipped)
				}}
				onKeyUp={(e) => {
					if (e.key === "Enter") {
						setIsFlipped(!isFlipped)
					}
				}}
			>
				<div
					className="FlashcardWrapper__item--front"
					style={{
						...frontCardStyle,
						cursor: "pointer",
					}}
				>
					{typeof frontHTML !== "string" ? (
						<div
							className="FlashcardWrapper__item--content"
							style={frontContentStyle}
						>
							{frontHTML}
						</div>
					) : (
						<div
							className="FlashcardWrapper__item--content"
							// biome-ignore lint/security/noDangerouslySetInnerHtml: createMarkup function sanitize the input
							dangerouslySetInnerHTML={createMarkup(frontHTML)}
							style={frontContentStyle}
						/>
					)}
				</div>
				<div
					className="FlashcardWrapper__item--back"
					style={{
						...backCardStyle,
						cursor: "pointer",
					}}
				>
					{typeof backHTML !== "string" ? (
						<div
							className="FlashcardWrapper__item--content"
							style={backContentStyle}
						>
							{backHTML}
						</div>
					) : (
						<div
							className="FlashcardWrapper__item--content"
							// biome-ignore lint/security/noDangerouslySetInnerHtml: createMarkup function sanitize the input
							dangerouslySetInnerHTML={createMarkup(backHTML)}
							style={backContentStyle}
						/>
					)}
				</div>
			</div>
		</div>
	)
}

export default Flashcard
