import { useCreateFileUploadUrl } from "@/api/mutations/useCreateFileUploadUrl.ts"
import { useCreateSubmission } from "@/api/mutations/useCreateSubmission.ts"
import { useFinalizeFileUpload } from "@/api/mutations/useFinalizeFileUpload.ts"
import { useUploadFileSubmission } from "@/api/mutations/useUploadFileSubmission.ts"
import { AssignmentSubmissionControls } from "@/features/learning_content/AssignmentSubmissionControls.tsx"
import {
	FileDrop,
	Flex,
	IconDocumentLine,
	IconUploadLine,
	Spinner,
	Text,
	View,
} from "@instructure/ui"
import { useState } from "react"
import { useParams } from "react-router-dom"

type UploadedFile = {
	id: number
	filename: string
	size: number
}

export const OnlineUploadSubmissionForm = () => {
	const { courseId = "", assignmentId = "" } = useParams()
	const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([])
	const createFileUploadUrlMutation = useCreateFileUploadUrl()
	const uploadFileSubmissionMutation = useUploadFileSubmission()
	const finalizeFileUploadMutation = useFinalizeFileUpload()
	const createSubmissionMutation = useCreateSubmission()

	const uploadFile = (file: File) => {
		createFileUploadUrlMutation.mutate(
			{
				courseId,
				assignmentId,
				name: file.name,
			},
			{
				onSuccess: (createUrlResponse) => {
					uploadFileSubmissionMutation.mutate(
						{
							uploadUrl: createUrlResponse.upload_url,
							uploadParams: createUrlResponse.upload_params,
							file,
						},
						{
							onSuccess: () => {
								finalizeFileUploadMutation.mutate(
									{
										finalizeUploadUrl:
											createUrlResponse.upload_params.success_url,
									},
									{
										onSuccess: (finalizeResponse) => {
											setUploadedFiles([...uploadedFiles, finalizeResponse])
										},
									},
								)
							},
						},
					)
				},
			},
		)
	}

	const isUploadPending =
		createFileUploadUrlMutation.isPending ||
		uploadFileSubmissionMutation.isPending ||
		finalizeFileUploadMutation.isPending
	const isUploadError =
		createFileUploadUrlMutation.isError ||
		uploadFileSubmissionMutation.isError ||
		finalizeFileUploadMutation.isError
	const hasUploadedFile = uploadedFiles.length > 0 && !isUploadError

	return (
		<View as="div" margin="small 0">
			{isUploadPending && (
				<View as="div" margin="medium 0" textAlign="center">
					<Spinner renderTitle="Uploading file" size="small" />
				</View>
			)}

			{!isUploadPending && hasUploadedFile && (
				<Flex as="div" margin="large 0" justifyItems="space-between">
					<Flex.Item>
						<IconDocumentLine /> <Text>{uploadedFiles[0].filename}</Text>
					</Flex.Item>
					<Flex.Item>
						<Text color="secondary">{`${uploadedFiles[0].size} bytes`}</Text>
					</Flex.Item>
				</Flex>
			)}

			{isUploadError && (
				<View as="div" margin="medium 0">
					<Text color="danger">Failed to upload file</Text>
				</View>
			)}

			{!isUploadPending && !hasUploadedFile && (
				<FileDrop
					renderLabel={
						<View as="div" margin="medium">
							<IconUploadLine size="medium" />
							<View as="div" margin="medium 0">
								<Text>Drop a file here or click to browse</Text>
							</View>
						</View>
					}
					shouldAllowMultiple={false}
					shouldAllowRepeats={false}
					onDropAccepted={(acceptedObjects) => {
						uploadFile(acceptedObjects[0] as File)
					}}
				/>
			)}

			<AssignmentSubmissionControls
				entryValid={hasUploadedFile}
				onSubmit={() => {
					createSubmissionMutation.mutate({
						assignmentLid: assignmentId,
						submissionType: "online_upload",
						fileIds: uploadedFiles.map((file) => file.id),
					})
				}}
				isPending={createSubmissionMutation.isPending}
				isSuccess={createSubmissionMutation.isSuccess}
				errorMessage={createSubmissionMutation.error?.message}
			/>
		</View>
	)
}
