import { useAppContext } from "@/AppContext.tsx"
import { DesktopNavigationItem } from "@/features/navigation/DesktopNavigationItem.tsx"
import { MobileNavigationItem } from "@/features/navigation/MobileNavigationItem.tsx"

export const NavigationItem = (props: {
	route?: string
	title?: string
	icon?: string
	customElement?: boolean
	overrideActive?: boolean
}) => {
	const { desktopMode } = useAppContext()

	if (!desktopMode) {
		return <MobileNavigationItem {...props} />
	}

	return <DesktopNavigationItem {...props} />
}
