import { restClient } from "@/api/api.ts"
import { QUERY_KEYS } from "@/api/queryKeys.ts"
import useValidateResponse from "@/api/useValidateResponse.ts"
import { APIROUTE, generateRoute } from "@/shared/router"
import { useQuery } from "@tanstack/react-query"
import { z } from "zod"
import { ZMimeClasses, ZMimeTypes } from "../types"

const ZFileResponse = z.object({
	"content-type": ZMimeTypes,
	display_name: z.string(),
	url: z.string(),
	mime_class: ZMimeClasses,
})

const ZFileContentResponse = z.object({
	content_type: ZMimeTypes,
	display_name: z.string(),
	url: z.string(),
	mime_class: ZMimeClasses,
})

type FileResponse = z.infer<typeof ZFileResponse>

export type FileContentProperties = { courseId: string; fileId: string }

export const useGetFileContent = (props: FileContentProperties) => {
	const queryKey = QUERY_KEYS.FILE_CONTENT.byProperties(props)
	const queryResult = useQuery({
		queryKey,
		queryFn: async (): Promise<FileResponse> => {
			const fileRoute = generateRoute(APIROUTE.FILE, props)
			return restClient(fileRoute)
		},
		select: (data) => ({
			display_name: data.display_name,
			url: data.url,
			mime_class: data.mime_class,
			content_type: data["content-type"],
		}),
	})

	useValidateResponse(queryKey.toString(), queryResult, ZFileContentResponse)
	return queryResult
}
