export const supportedFonts = [
	{
		name: "manrope",
		weights: [400, 600, 700],
	},
	{
		name: "figtree",
		weights: [400, 600, 700],
	},
]

if (typeof window !== "undefined") {
	for (const font of supportedFonts) {
		for (const weight of font.weights) {
			import(`@fontsource/${font.name}/${weight}.css`)
		}
	}
}

export const typography = {
	fontSources: supportedFonts.flatMap((font) =>
		font.weights.map((weight) => `fonts/${font.name}-${weight}.css`),
	),

	fontFamilyHeading: "Manrope, sans-serif",
	fontFamily: "Figtree, sans-serif",

	fontSizeXSmall: "0.75rem", // 12px
	fontSizeSmall: "0.875rem", // 14px
	fontSizeMedium: "1rem", // 16px
	fontSizeLarge: "1.25rem", // 20px
	fontSizeXLarge: "1.5rem", // 24px
	fontSizeXXLarge: "1.75rem", // 28px

	fontWeightNormal: 400,
	fontWeightSemiBold: 600,
	fontWeightBold: 700,

	lineHeight: 1.4,

	letterSpacingNormal: 0,
	letterSpacingSemiExpanded: "0.015625rem", // 0.25px
	letterSpacingExpanded: "0.03125rem", // 0.5px
}

// default typography removed: fontFamilyMonospace, lineHeightFit, lineHeightCondensed, lineHeightDouble, fontWeightLight, letterSpacingCondensed
// TODO: actually import those fonts
