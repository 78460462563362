import type { ConversationConnection } from "@/api/queries/useGetConversations.ts"
import { CLXTruncateText } from "@/shared/components/TruncateText/TruncateText.tsx"
import { formatDateForUser } from "@/shared/utils"
import { Badge, Flex, FlexItem, Text, View } from "@instructure/ui"

type Props = {
	conversations: ConversationConnection[]
	selectedId?: number
	setSelectedConversation: (conversation: ConversationConnection) => void
}

function ConversationList({
	conversations,
	setSelectedConversation,
	selectedId,
}: Props) {
	return (
		<Flex direction="column" height="100%">
			{conversations.map((conv) => {
				const { conversation } = conv
				const messages = conversation.conversationMessagesConnection.nodes
				const mostRecentDate = new Date(
					Math.max(
						...messages.map((node) => new Date(node.createdAt).getTime()),
					),
				)
				const mostRecentMessage = messages.find((message) => {
					const d = new Date(message.createdAt)
					return d.getTime() === mostRecentDate.getTime()
				})
				return (
					<Flex.Item key={conversation.id}>
						<View
							as="div"
							padding="medium"
							background={
								selectedId === conversation.id ? "secondary" : "transparent"
							}
							borderWidth="small"
							onClick={() => setSelectedConversation(conv)}
						>
							<Flex justifyItems="space-between" gap="small">
								<Flex.Item shouldShrink>
									<CLXTruncateText
										size="small"
										text={conversation.conversationParticipantsConnection.nodes
											.map((user) => user.user.name)
											.join(",")}
										shouldShowTooltip={false}
									/>
								</Flex.Item>
								<Flex.Item>
									<Text size="small">{formatDateForUser(mostRecentDate)}</Text>
								</Flex.Item>
							</Flex>
							<CLXTruncateText
								as="h1"
								size="medium"
								text={conversation.subject}
								shouldShowTooltip={false}
							/>
							<Flex justifyItems="space-between" gap="small">
								<FlexItem shouldShrink>
									<CLXTruncateText
										size="small"
										text={mostRecentMessage?.body ?? ""}
										shouldShowTooltip={false}
									/>
								</FlexItem>
								<FlexItem>
									<Badge
										standalone
										variant="primary"
										count={conversation.conversationMessagesCount}
										margin="0 small 0 0"
									/>
								</FlexItem>
							</Flex>
						</View>
					</Flex.Item>
				)
			})}
		</Flex>
	)
}

export default ConversationList
