import type { NoteTag } from "@/api/db/db"
import type { AddressBookRecipientsProps } from "@/api/queries/useGetAddressBookRecipients.ts"
import type { AssignmentProperties } from "@/api/queries/useGetAssignment.ts"
import type {
	BedrockKBProperties,
	BedrockProperties,
} from "@/api/queries/useGetBedrockResponse.ts"
import type { GetConversationsProps } from "@/api/queries/useGetConversations.ts"
import type { FileContentProperties } from "@/api/queries/useGetFileContent.ts"
import type {
	ModuleByUserProperties,
	ModuleItemProperties,
} from "@/api/queries/useGetModules.ts"
import type { ModuleItemsProperties } from "@/api/queries/useGetModules.ts"
import type { PageProperties } from "@/api/queries/useGetPage.ts"
import type { QuizProperties } from "@/api/queries/useGetQuiz.ts"
import type { LaunchUrlProperties } from "@/api/queries/useGetSessionlessLaunchUrl.ts"

export const QUERY_KEYS = {
	ASSIGNMENT: {
		all: ["assignment"],
		item: (props: AssignmentProperties) => [
			...QUERY_KEYS.ASSIGNMENT.all,
			"item",
			props,
		],
	},
	BEDROCK: {
		all: ["bedrock"],
		kbResponse: (props: BedrockKBProperties) => [
			...QUERY_KEYS.BEDROCK.all,
			props,
		],
		response: (props: BedrockProperties) => [...QUERY_KEYS.BEDROCK.all, props],
	},
	COURSE: {
		all: ["course"],
		names: () => [...QUERY_KEYS.COURSE.all, "names"],
		name: (courseId: string) => [...QUERY_KEYS.COURSE.names(), { courseId }],
		byUser: (userId: string) => [...QUERY_KEYS.COURSE.all, { userId }],
		overviews: () => [...QUERY_KEYS.COURSE.all, "overviews"],
		overview: (courseId: string) => [
			...QUERY_KEYS.COURSE.overviews(),
			{ courseId },
		],
	},
	DOC: {
		all: ["doc"],
		byUrl: (url: string) => [...QUERY_KEYS.DOC.all, { url }],
	},
	FILE_CONTENT: {
		all: ["fileContent"],
		byProperties: (props: FileContentProperties) => [
			...QUERY_KEYS.FILE_CONTENT.all,
			props,
		],
	},
	MODULE: {
		all: ["module"],
		byUser: (props: ModuleByUserProperties) => [
			...QUERY_KEYS.MODULE.all,
			props,
		],
		items: (props: ModuleItemsProperties) => [
			...QUERY_KEYS.MODULE.all,
			"items",
			props,
		],
		item: ({ courseId, moduleId, itemId }: ModuleItemProperties) => [
			...QUERY_KEYS.MODULE.items({ courseId, moduleId }),
			{ itemId },
		],
	},
	NOTE: {
		all: ["notes"],
		byUserId: (userId: string) => [...QUERY_KEYS.NOTE.all, { userId }],
		bySearchCourse: (userId: string, courseSearchText: string) => [
			...QUERY_KEYS.NOTE.all,
			{ userId, courseSearchText },
		],
		bySearchTagAndText: (
			userId: string,
			courseId: string,
			tags: NoteTag[],
			searchText: string,
		) => [...QUERY_KEYS.NOTE.all, { userId, courseId, tags, searchText }],
	},
	NOTIFICATION: {
		all: ["notification"],
		byUser: (userId: string) => [...QUERY_KEYS.NOTIFICATION.all, { userId }],
	},
	PAGE: {
		all: ["page"],
		details: (props: PageProperties) => [
			...QUERY_KEYS.PAGE.all,
			"details",
			props,
		],
		content: (props: PageProperties) => [
			...QUERY_KEYS.PAGE.all,
			"content",
			props,
		],
	},
	QUIZ: {
		all: ["quiz"],
		assignment: (props: QuizProperties) => [...QUERY_KEYS.QUIZ.all, props],
	},
	SESSIONLESS_LAUNCH_URL: {
		all: ["sessionlessLaunchUrl"],
		byProperties: (props: LaunchUrlProperties) => [
			...QUERY_KEYS.SESSIONLESS_LAUNCH_URL.all,
			props,
		],
	},
	SUBMISSION: {
		all: ["submission"],
		byId: (id: string) => [...QUERY_KEYS.SUBMISSION.all, { id }],
	},
	USER: {
		all: ["user"],
		byId: (id: string) => [...QUERY_KEYS.USER.all, { id }],
	},
	ADDRESS_BOOK_RECIPIENTS: {
		all: ["addressBookRecipients"],
		byProperties: (props: AddressBookRecipientsProps) => [
			...QUERY_KEYS.ADDRESS_BOOK_RECIPIENTS.all,
			props,
		],
	},
	CONVERSATION: {
		all: ["conversation"],
		byProperties: (props: GetConversationsProps) => [
			...QUERY_KEYS.CONVERSATION.all,
			props,
		],
	},
}
