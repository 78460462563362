import type React from "react"
import {
	type ReactNode,
	createContext,
	useCallback,
	useContext,
	useState,
} from "react"
import "./Toast.css"
import getLiveRegion from "@/shared/utils/liveRegion.ts"
import { Alert } from "@instructure/ui"

type AlertProps = React.ComponentProps<typeof Alert>

const ToastContext = createContext((_props: ToastProperties) => {})

export const useToast = () => useContext(ToastContext)

export type ToastProperties = Omit<
	AlertProps,
	"children" | "themeOverride" | "hasShadow" | "liveRegion" | "onDismiss"
> & {
	message: string
	hasShadow?: boolean
}
type ToastPropertiesWithId = ToastProperties & { id: number }

export const ToastProvider = ({ children }: { children: ReactNode }) => {
	const [toastsProperties, setToastsProperties] = useState<
		ToastPropertiesWithId[]
	>([])

	const showToast = useCallback((props: ToastProperties) => {
		const id = Date.now()
		setToastsProperties((prev) => [
			...prev,
			{
				...props,
				id,
				hasShadow: props.hasShadow ?? true,
				variant: props.variant ?? "info",
			},
		])
		setTimeout(() => {
			setToastsProperties((prev) => prev.filter((toast) => toast.id !== id))
		}, props.timeout ?? 3000)
	}, [])

	const closeAlert = (id: number) => {
		setToastsProperties((prev) => prev.filter((toast) => toast.id !== id))
	}

	return (
		<ToastContext.Provider value={showToast}>
			{children}
			<div className="toast-container">
				{toastsProperties.map(({ message, id, ...rest }) => (
					<Alert
						key={id}
						{...rest}
						liveRegion={getLiveRegion}
						renderCloseButtonLabel={rest.renderCloseButtonLabel ?? "Close"}
						onDismiss={() => closeAlert(id)}
					>
						{message}
					</Alert>
				))}
			</div>
		</ToastContext.Provider>
	)
}
