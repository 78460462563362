import { gqlClient } from "@/api/api.ts"
import { QUERY_KEYS } from "@/api/queryKeys.ts"
import useValidateResponse from "@/api/useValidateResponse.ts"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { gql } from "graphql-request"
import { z } from "zod"

const ZDeleteConversationMessagesResponse = z
	.object({
		deleteConversationMessages: z.object({
			conversationMessageIds: z.string(),
			errors: z
				.array(
					z
						.object({
							message: z.string(),
						})
						.strict(),
				)
				.nullable(),
		}),
	})
	.strict()

type DeleteConversationMessagesResponse = z.infer<
	typeof ZDeleteConversationMessagesResponse
>

export const DELETE_CONVERSATIONS_MESSAGES = gql`
    mutation DeleteConversationMessages($ids: [ID!]!) {
        deleteConversationMessages(input: {ids: $ids}) {
            conversationMessageIds
            errors {
                message
            }
        }
    }
`
export const useDeleteConversationMessages = () => {
	const queryClient = useQueryClient()
	const mutationResult = useMutation({
		mutationFn: async ({
			ids,
		}: { ids: string[] }): Promise<DeleteConversationMessagesResponse> =>
			(await gqlClient()).request(DELETE_CONVERSATIONS_MESSAGES, {
				ids,
			}),
		onSuccess: async () => {
			await queryClient.invalidateQueries({
				queryKey: QUERY_KEYS.CONVERSATION.all,
			})
		},
	})

	useValidateResponse(
		"useDeleteConversationMessages",
		mutationResult,
		ZDeleteConversationMessagesResponse,
	)
	return mutationResult
}
