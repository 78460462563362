import type { CLXTheme, ThemeObjectValue } from "@/themes/types.ts"

export const generatePillComponentTheme =
	(isBorderVisible: boolean) => (_: unknown, currentTheme: CLXTheme) => {
		const { borders } = currentTheme
		const baseComponentTheme = {
			...(currentTheme.componentOverrides.Pill as ThemeObjectValue),
		}

		if (!isBorderVisible) {
			return {
				...baseComponentTheme,
				borderStyle: borders.none,
			}
		}

		return baseComponentTheme
	}

export const generatePillTextTheme =
	(isUpperCase: boolean) => (_: unknown, currentTheme: CLXTheme) => {
		const { typography } = currentTheme
		const baseComponentTheme = {
			...(currentTheme.componentOverrides.Text as ThemeObjectValue),
		}

		return {
			...baseComponentTheme,
			letterSpacingNormal: isUpperCase
				? typography.letterSpacingExpanded
				: typography.letterSpacingSemiExpanded,
			fontFamily: isUpperCase
				? typography.fontFamilyHeading
				: typography.fontFamily,
		}
	}
