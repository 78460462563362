import { useGetModules } from "@/api/queries/useGetModules.ts"
import { Module } from "@/features/programs/Module.tsx"
import { FetchingError } from "@/shared/loading/FetchingError.tsx"
import { GlobalLoader } from "@/shared/loading/GlobalLoader.tsx"
import ProgressBar from "@/shared/progress_bar/ProgressBar.tsx"
import {
	formatNumberToPercentage,
	getCourseProgressionValues,
} from "@/shared/utils"
import { getUserId } from "@/token-storage.ts"
import { Tabs, Text, View, canvas } from "@instructure/ui"
import { useState } from "react"
import { useParams } from "react-router-dom"
import "./styles.css"
import { CourseOverview } from "@/features/programs/CourseOverview.tsx"

enum CourseTab {
	Progress = "progress",
	Overview = "overview",
	Grades = "grades",
	QuickLinks = "quick-links",
}

export const Program = () => {
	const { courseId = "" } = useParams()
	const modulesQuery = useGetModules({ courseId, userId: getUserId() })
	const [selectedTab, setSelectedTab] = useState(CourseTab.Progress)

	if (modulesQuery.isLoading) {
		return <GlobalLoader title="Loading..." renderInContent={true} />
	}

	if (modulesQuery.isError || !modulesQuery.data) {
		return <FetchingError />
	}

	const { completed, total, completionPercentage } = getCourseProgressionValues(
		modulesQuery.data.courseProgression,
	)

	return (
		<>
			<View as="div" margin="large medium medium">
				<Text size="large">{modulesQuery.data.courseName}</Text>
				<View as="div" margin="small 0">
					<Text size="small">Target Completion: XX/XX</Text>
				</View>
				<ProgressBar
					valueNow={completed}
					valueMax={total}
					completionPercentage={completionPercentage}
					renderValue={() => (
						<Text>{formatNumberToPercentage(completionPercentage)}</Text>
					)}
				/>
			</View>

			<div className="tabs-view-wrapper">
				<Tabs
					tabOverflow="scroll"
					onRequestTabChange={(_, { id }) =>
						id && setSelectedTab(id as CourseTab)
					}
					themeOverride={{
						defaultBackground: canvas.colors.primitives.grey11,
						scrollOverlayWidthDefault: "0",
					}}
				>
					<Tabs.Panel
						id={CourseTab.Progress}
						renderTitle="My Progress"
						isSelected={selectedTab === CourseTab.Progress}
						padding="0"
					>
						<View as="div" background="secondary" padding="xxx-small 0">
							{modulesQuery.data.modules.map((module, index) => (
								<Module
									key={module._id}
									module={module}
									defaultExpanded={index === 0}
								/>
							))}
						</View>
					</Tabs.Panel>
					<Tabs.Panel
						id={CourseTab.Overview}
						renderTitle="Overview"
						isSelected={selectedTab === CourseTab.Overview}
					>
						<View as="div" background="secondary" padding="medium">
							<CourseOverview courseId={courseId} />
						</View>
					</Tabs.Panel>
					<Tabs.Panel
						id={CourseTab.Grades}
						renderTitle="Grades"
						isSelected={selectedTab === CourseTab.Grades}
					>
						<View as="div" background="secondary" padding="medium">
							<Text>Grades</Text>
						</View>
					</Tabs.Panel>
					<Tabs.Panel
						id={CourseTab.QuickLinks}
						renderTitle="Quick Links"
						isSelected={selectedTab === CourseTab.QuickLinks}
					>
						<View as="div" background="secondary" padding="medium">
							<Text>Quick Links</Text>
						</View>
					</Tabs.Panel>
				</Tabs>
			</div>
		</>
	)
}
