import { useDeleteNote } from "@/api/mutations/useDeleteNote"
import { useGetNotebyId } from "@/api/queries/useGetNoteById"
import { CLXPill } from "@/shared/components/Pill/Pill.tsx"
import { FetchingError } from "@/shared/loading/FetchingError"
import { GlobalLoader } from "@/shared/loading/GlobalLoader"
import { SearchParams } from "@/shared/router"
import { Button, Flex, Text, View } from "@instructure/ui"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { tagIconMap } from "./TagBlock"

export const NoteView = ({ id }: { id: number }) => {
	const navigate = useNavigate()
	const noteQuery = useGetNotebyId(id)
	const deleteNoteMutation = useDeleteNote()

	useEffect(() => {
		if (deleteNoteMutation.isSuccess && noteQuery.data) {
			navigate(`${noteQuery.data.itemUrl}?${SearchParams.ADD_NOTE}=true`)
		}
	}, [deleteNoteMutation, noteQuery, navigate])

	if (noteQuery.isLoading) {
		return <GlobalLoader title="Loading..." />
	}

	if (!noteQuery.data) {
		return <FetchingError />
	}

	return (
		<View
			as="div"
			background="success"
			borderRadius="large large 0 0"
			padding="medium"
			themeOverride={{
				backgroundSuccess: "#626262",
				borderRadiusLarge: "2rem",
			}}
		>
			<Flex justifyItems="start" gap="x-small">
				{noteQuery.data.tags.map((tag) => (
					<CLXPill
						key={tag}
						renderIcon={tagIconMap.find((t) => t.tag === tag)?.renderIcon()}
					>
						{tag}
					</CLXPill>
				))}
			</Flex>
			<View as="div" margin="small 0" minHeight={"10rem"}>
				<Text>{noteQuery.data.text}</Text>
			</View>
			<Flex justifyItems="space-between" gap="small">
				<Flex.Item shouldGrow margin="small 0 0 0">
					<Button
						themeOverride={{
							borderRadius: "1rem",
						}}
						display="block"
						onClick={() => {
							deleteNoteMutation.mutate(id)
						}}
					>
						Delete
					</Button>
				</Flex.Item>
				<Flex.Item shouldGrow margin="small 0 0 0">
					<Button
						themeOverride={{
							borderRadius: "1rem",
						}}
						display="block"
						onClick={() => {
							navigate(
								`${noteQuery.data.itemUrl}?${SearchParams.EDIT_NOTE}=${id}`,
							)
						}}
					>
						Edit
					</Button>
				</Flex.Item>
			</Flex>
		</View>
	)
}
