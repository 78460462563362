import { ExpiredTokenError } from "@/api/api.ts"
import {
	MutationCache,
	QueryCache,
	QueryClient,
	QueryClientProvider,
} from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import type { ReactNode } from "react"
import { actions, useAppContextDispatch } from "./AppContext.tsx"

export const WrappedQueryClientProvider = ({
	children,
}: { children: ReactNode }) => {
	const dispatch = useAppContextDispatch()

	const onError = (error: Error) => {
		if (error instanceof ExpiredTokenError) {
			dispatch({ type: actions.SET_SHOW_REFRESH_MODAL, payload: true })
		} else {
			throw error
		}
	}

	const queryClient = new QueryClient({
		queryCache: new QueryCache({
			onError,
		}),
		mutationCache: new MutationCache({
			onError,
		}),
		defaultOptions: {
			queries: {
				retry: false,
				staleTime: 1000 * 60 * 60 * 24, // 1 day
				gcTime: 1000 * 60 * 60 * 24 * 7, // 7 days
			},
		},
	})

	return (
		<QueryClientProvider client={queryClient}>
			{children}
			{import.meta.env.MODE === "development" && (
				<ReactQueryDevtools initialIsOpen={false} />
			)}
		</QueryClientProvider>
	)
}
