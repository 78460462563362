import type { EffectCallback } from "react"
import { useEffect } from "react"

export function useOnMount(effect: EffectCallback) {
	// biome-ignore lint/correctness/useExhaustiveDependencies: runs once on mount
	useEffect(() => {
		// Avoids mutation running twice on development + StrictMode
		// See: https://github.com/TanStack/query/issues/5341
		const timeout = setTimeout(() => effect(), 0)
		return () => clearTimeout(timeout)
	}, [])
}
