import { CLXIconButton } from "@/shared/components/IconButton/IconButton.tsx"
import { LearningAssist } from "@/shared/learning_assist/LearningAssist.tsx"
import { Flex, IconAiLine } from "@instructure/ui"
import { useState } from "react"

export const AiStarNavigationItem = () => {
	const [isShowingAssistModal, setShowingAssistModal] = useState(false)

	return (
		<>
			<Flex.Item height="44px" width="44px">
				<CLXIconButton
					size="medium"
					level="primary"
					variant="ai"
					shape="circle"
					screenReaderLabel="Learning Assist"
					onClick={() => setShowingAssistModal(true)}
				>
					<IconAiLine />
				</CLXIconButton>
			</Flex.Item>
			<LearningAssist
				isOpen={isShowingAssistModal}
				dismiss={() => setShowingAssistModal(false)}
			/>
		</>
	)
}
