import {
	type ButtonLevel,
	type ButtonVariant,
	generateButtonTheme,
} from "@/shared/themes/generateButtonTheme.ts"
import type { CLXTheme } from "@/themes/types.ts"

export const generateComponentTheme =
	(level: ButtonLevel, variant?: ButtonVariant) =>
	(_: unknown, currentTheme: CLXTheme) => {
		return generateButtonTheme(currentTheme, level, variant)
	}
