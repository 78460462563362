import {
	type Dispatch,
	type ReactNode,
	createContext,
	useContext,
	useReducer,
} from "react"
import "./FlexStyles.css"
import { horizonTheme } from "@/themes/horizon"
import type { CLXTheme } from "@/themes/types.ts"

type AppContext = {
	showingRefreshModal: boolean
	desktopMode: boolean
	maxHeight: number
	mobileContentHeight: number
	theme: CLXTheme
}

const initialContext: AppContext = {
	showingRefreshModal: false,
	desktopMode: false,
	maxHeight: 0,
	mobileContentHeight: 0,
	theme: horizonTheme,
}

export enum actions {
	SET_SHOW_REFRESH_MODAL = "SET_SHOW_REFRESH_MODAL",
	SET_DESKTOP_MODE = "SET_DESKTOP_MODE",
	SET_APPLICATION_HEIGHTS = "SET_APPLICATION_HEIGHTS",
	SET_THEME = "SET_THEME",
}

type SetShowRefreshModelAction = {
	type: actions.SET_SHOW_REFRESH_MODAL
	payload: boolean
}
type SetDesktopModeAction = {
	type: actions.SET_DESKTOP_MODE
	payload: boolean
}
type SetMaxHeightAction = {
	type: actions.SET_APPLICATION_HEIGHTS
	payload: {
		maxHeight: number
		mobileContentHeight: number
	}
}
type SetThemeAction = {
	type: actions.SET_THEME
	payload: CLXTheme
}

// Union of all actions
type AppContextAction =
	| SetShowRefreshModelAction
	| SetDesktopModeAction
	| SetMaxHeightAction
	| SetThemeAction

const reducer = (state: AppContext, action: AppContextAction): AppContext => {
	switch (action.type) {
		case actions.SET_SHOW_REFRESH_MODAL:
			return { ...state, showingRefreshModal: action.payload }
		case actions.SET_DESKTOP_MODE:
			return { ...state, desktopMode: action.payload }
		case actions.SET_APPLICATION_HEIGHTS:
			return {
				...state,
				maxHeight: action.payload.maxHeight,
				mobileContentHeight: action.payload.mobileContentHeight,
			}
		case actions.SET_THEME:
			return { ...state, theme: action.payload }
		default:
			return state
	}
}

const AppContext = createContext<AppContext>(initialContext)
const AppContextDispatch = createContext<Dispatch<AppContextAction>>(() => {
	throw new Error(
		"AppContextDispatch must be used within an AppContextProvider",
	)
})

export const AppContextProvider = ({ children }: { children: ReactNode }) => {
	const [appContext, dispatch] = useReducer(reducer, initialContext)
	return (
		<AppContext.Provider value={appContext}>
			<AppContextDispatch.Provider value={dispatch}>
				{children}
			</AppContextDispatch.Provider>
		</AppContext.Provider>
	)
}

export const useAppContext = () => {
	return useContext(AppContext)
}

export const useAppContextDispatch = () => {
	return useContext(AppContextDispatch)
}
