import {
	type ZMimeClassesType,
	type ZMimeTypesType,
	mimeTypeToClassMap,
} from "@/api/types"
import { Img } from "@instructure/ui"
import { AudioContent } from "./AudioContent"
import { DocContent } from "./DocContent"
import { PDFContent } from "./PDFContent"
import { VideoContent } from "./VideoContent"

type FileViewProps = {
	mimeClass: ZMimeClassesType
	contentType: ZMimeTypesType
	url: string
	displayName: string
}

export const FileView = ({
	mimeClass,
	contentType,
	url,
	displayName,
}: FileViewProps) => {
	return (
		<>
			{mimeTypeToClassMap[contentType] === "video" && (
				<VideoContent url={url} content_type={contentType} />
			)}
			{mimeTypeToClassMap[contentType] === "audio" && (
				<AudioContent url={url} content_type={contentType} />
			)}
			{mimeClass === "pdf" && <PDFContent url={url} />}
			{mimeClass === "doc" && <DocContent url={url} />}
			{mimeClass === "image" && <Img src={url} alt={displayName} />}
		</>
	)
}
