import { Button, Text, View } from "@instructure/ui"

export const AssignmentSubmissionControls = ({
	entryValid,
	onSubmit,
	isPending,
	isSuccess,
	errorMessage,
}: {
	entryValid: boolean
	onSubmit: () => void
	isPending: boolean
	isSuccess: boolean
	errorMessage?: string
}) => {
	const submitButtonLabel = () => {
		if (isPending) {
			return "Submitting..."
		}
		if (isSuccess) {
			return "Submitted!"
		}
		return "Submit Assignment"
	}

	return (
		<View as="div">
			<Button
				display="block"
				margin="small 0"
				color="primary"
				interaction={
					entryValid && !isPending && !isSuccess ? "enabled" : "disabled"
				}
				onClick={onSubmit}
			>
				{submitButtonLabel()}
			</Button>
			{errorMessage && <Text color="danger">{errorMessage}</Text>}
		</View>
	)
}
