import { MOBILE_MENU_HEIGHT } from "@/constants.ts"
import { Flex, View } from "@instructure/ui"
import { Outlet, ScrollRestoration } from "react-router-dom"

export const MobileNavigationWrapper = ({
	maxHeight,
	children,
}: {
	maxHeight: number
	children: React.ReactNode
}) => (
	<div
		style={{
			display: "flex",
			flexDirection: "column",
			height: maxHeight,
		}}
	>
		<ScrollRestoration getKey={(location, _matches) => location.pathname} />
		<View as="div" height={maxHeight - MOBILE_MENU_HEIGHT} overflowX="auto">
			<Outlet />
		</View>
		<View
			as="nav"
			width="100%"
			height={MOBILE_MENU_HEIGHT}
			padding="small 0 medium 0"
			background="primary"
			borderColor="primary"
			borderWidth="small 0 0 0"
		>
			<Flex width="100%" height="100%" justifyItems="center" gap="medium">
				{children}
			</Flex>
		</View>
	</div>
)
