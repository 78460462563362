import liveRegion from "@/shared/utils/liveRegion"
import type { SyntheticEvent } from "react"
import type { Editor } from "tinymce"

type MapPropsToRCEType = {
	autoFocus?: boolean
	textareaId: string
	defaultContent: string
	stem?: string
	height?: number | null
	actsAsInput?: boolean
	readOnly?: boolean
	placeholder?: string
	label: React.ReactNode
	messages?: {
		type: string
	}[]
	onFocus?: (event: SyntheticEvent, content: { editorContent: string }) => void
	onBlur?: (event: SyntheticEvent, content: { editorContent: string }) => void
	editorOptions?: {
		spellCheck: boolean
		wordCount: boolean
	}
	onContentChange: (content: string) => void
	onInit: (
		tinyEditorInstance: Editor & { rceWrapper: { iframe: { title: string } } },
	) => void
}

export const mapQuizRcePropsToCanvasRceProps = (props: MapPropsToRCEType) => {
	const {
		textareaId,
		defaultContent,
		stem,
		readOnly,
		onFocus,
		autoFocus,
		height,
		onBlur,
		onContentChange,
		onInit,
	} = props

	const spellCheck = props.editorOptions?.spellCheck
	const showWordCount = props.editorOptions?.wordCount

	const editorOptions = () => ({
		body_class: "",
		selector: `#${textareaId}`,
		directionality:
			document.documentElement.getAttribute("dir") ||
			getComputedStyle(document.documentElement, null).direction,
		content_css: [],
		show_media_upload: false,
		auto_focus: autoFocus,
		browser_spellcheck: spellCheck !== false,
		plugins: showWordCount === false ? ["-instructure_wordcount"] : [],
	})

	const rceProps = {
		textareaId,
		defaultContent,
		onContentChange,
		onInit,
		liveRegion,
		editorOptions,
		features: {},
		height,
		readOnly,
		onBlur,
		onFocus,
		language: "en-US",
		stem,
	}
	return rceProps
}
