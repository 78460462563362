import { useAI } from "@/api/mutations/useAI"
import type { FlashcardProps } from "@/shared/flashcard/Flashcard"
import FlashcardArray from "@/shared/flashcard/FlashcardArray"
import { useOnMount } from "@/shared/hooks/useOnMount"
import { FetchingError } from "@/shared/loading/FetchingError"
import { GlobalLoader } from "@/shared/loading/GlobalLoader"
import { Flex, View } from "@instructure/ui"
import { useMemo, useState } from "react"
import { v4 as uuidv4 } from "uuid"

type Props = {
	context: string
}

function AssistFlashCardsInteraction({ context }: Props) {
	const { mutate, isPending, isError, isIdle } = useAI(true)
	const [data, setData] = useState<
		{ answer: string; question: string }[] | null
	>(null)

	const cards: FlashcardProps[] = useMemo(() => {
		return (
			data?.map((card) => {
				return {
					id: uuidv4(),
					frontHTML: `<div>Question</div><div>${card.question}</div><div>Tap to Flip</div>`,
					frontContentStyle: {
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						height: "100%",
					},
					backHTML: `<div>Answer</div><div>${card.answer}</div><div>Tap to Flip</div>`,
					backContentStyle: {
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						height: "100%",
					},
					backCardStyle: {
						background: "lightgrey",
					},
				}
			}) ?? []
		)
	}, [data])

	useOnMount(() => {
		mutate(
			`here is a content from a course in html format: "${context}", i need 7 questions with answers based on the content, give back in json format like: {data: [{question: '', answer: ''}, {question: '', answer: ''}, ...] }`,
			{
				onSuccess: (data) => {
					try {
						const parsedContent = JSON.parse(data.message.content)
						setData(parsedContent.data)
					} catch (error) {
						console.error(error)
					}
				},
				onError: (error) => {
					console.error(error)
				},
			},
		)
	})

	if (isPending || isIdle) {
		return (
			<Flex as="div" justifyItems="center">
				<GlobalLoader title="Loading..." renderInContent={true} />
			</Flex>
		)
	}

	if (isError) {
		return (
			<View padding="medium" as="div">
				<FetchingError />
			</View>
		)
	}

	return (
		<div
			style={{
				display: "flex",
				height: "100%",
				flexDirection: "column",
				flexGrow: 1,
			}}
		>
			<FlashcardArray cards={cards} cycle />
		</div>
	)
}

export default AssistFlashCardsInteraction
