export const breakpoints = {
	xxSmall: "8em", // 128px
	xSmall: "16em", // 256px
	small: "30em", // 480px
	medium: "48em", // 768px
	tablet: "48em", // 768px
	large: "62em", // 992px
	desktop: "64em", // 1024px
	xLarge: "75em", // 1200px
	maxWidth: "61.9375em", // 991px
}

// copied default canvas theme breakpoints (not addressed in CLX design system)
