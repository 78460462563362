// @ts-ignore
import { Mathml } from "@instructure/canvas-rce"
import { debounce } from "@instructure/debounce"
import { Locale } from "@instructure/ui-i18n"

const features = {
	new_math_equation_handling: true,
	explicit_latex_typesetting: false,
}
const mathRendererConfig = { locale: Locale.browserLocale() }
const renderer = new Mathml(features, mathRendererConfig)

export const processNewMathInElem = (elem: HTMLElement) => {
	renderer.processNewMathInElem(elem || document.body)
}

/*
Rendering the document.body is much more faster because MathJax can optimise
one single big element better than smaller ones where the rendering process always
build up from scratch everytime.

Also debounce needed because of the images based equations trigger re-render and make
the rendering process very slow. This debouncing stuff is for mainly to support our print feature
*/
export default {
	debouncedDocumentRendering: debounce(
		() => renderer.processNewMathInElem(document.body),
		500,
	),
}
