import { gqlClient } from "@/api/api.ts"
import { QUERY_KEYS } from "@/api/queryKeys.ts"
import { ZUser } from "@/api/types.ts"
import useValidateResponse from "@/api/useValidateResponse.ts"
import { type UseQueryResult, useQuery } from "@tanstack/react-query"
import { gql } from "graphql-request"
import { z } from "zod"

export type AddressBookRecipientsProps = {
	userId: string
	search: string
	afterUser: string
}

export const ZPageInfo = z
	.object({
		endCursor: z.string(),
		hasNextPage: z.boolean(),
	})
	.strict()

export const ZUsersConnections = z
	.object({
		nodes: z.array(ZUser),
		pageInfo: ZPageInfo,
	})
	.strict()

const ZAddressBookRecipientsResponse = z.object({
	legacyNode: z.object({
		recipients: z.object({
			usersConnection: ZUsersConnections,
		}),
	}),
})

type AddressBookRecipientsResponse = z.infer<
	typeof ZAddressBookRecipientsResponse
>

const ZAddressBookRecipientsQueryResult = z.object({
	users: z.array(ZUser),
	pageInfo: ZPageInfo,
})

type AddressBookRecipientsQueryResult = z.infer<
	typeof ZAddressBookRecipientsQueryResult
>

export const ADDRESS_BOOK_RECIPIENTS = gql`
    query GetAddressBookRecipients(
        $userId: ID!
        $search: String
        $afterUser: String
    ) {
        legacyNode(_id: $userId, type: User) {
            ... on User {
                recipients(search: $search) {
                    usersConnection(first: 20, after: $afterUser) {
                        nodes {
                            _id
                            id
                            name
                        }
                        pageInfo {
                            endCursor
                            hasNextPage
                        }
                    }
                }
            }
        }
    }
`

export const useGetAddressBookRecipients = (
	props: AddressBookRecipientsProps,
): UseQueryResult<AddressBookRecipientsQueryResult, Error> => {
	const queryKey = QUERY_KEYS.ADDRESS_BOOK_RECIPIENTS.byProperties(props)
	const queryResult = useQuery({
		queryKey,
		queryFn: async (): Promise<AddressBookRecipientsResponse> => {
			return (await gqlClient()).request(ADDRESS_BOOK_RECIPIENTS, props)
		},
		select: (data) => ({
			users: data.legacyNode.recipients.usersConnection.nodes,
			pageInfo: data.legacyNode.recipients.usersConnection.pageInfo,
		}),
	})

	useValidateResponse(
		queryKey.toString(),
		queryResult,
		ZAddressBookRecipientsQueryResult,
	)
	return queryResult
}
