import { PAGEROUTE } from "@/shared/router"
import { Heading, View } from "@instructure/ui"
import { Link } from "react-router-dom"

const NotFound = () => (
	<View as="div" padding="medium">
		<Heading as="h1" margin="small 0">
			Not Found
		</Heading>
		<Link to={PAGEROUTE.HOME}>Home</Link>
	</View>
)

export default NotFound
