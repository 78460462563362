import { APIROUTE, generateRoute } from "@/shared/router"
import { useMutation } from "@tanstack/react-query"
import { restClient } from "../api.ts"

const mutationFn = (): Promise<void> => {
	const apiRoute = generateRoute(APIROUTE.LOGIN, {})

	return restClient(apiRoute, {
		method: "DELETE",
	})
}

export const useLogout = ({ onSettled }: { onSettled: () => void }) => {
	return useMutation({
		mutationFn,
		onSettled,
	})
}
