// This is intended for types shared between multiple queries/mutations.
// For types specific to a single query/mutation, consider defining them in the same file.

import { z } from "zod"

export const ZModuleItemType = z.enum([
	"File",
	"Page",
	"Discussion",
	"Assignment",
	"Quiz",
	"SubHeader",
	"ExternalUrl",
	"ExternalTool",
])

export const ZModuleItemContent = z.object({
	__typename: ZModuleItemType,
	_id: z.string().optional(),
	name: z.string(),
	dueAt: z.string().optional().nullable(),
})

export type ModuleItemType = z.infer<typeof ZModuleItemType>

export const ZModuleItem = z
	.object({
		id: z.number(),
		module_id: z.number(),
		position: z.number(),
		title: z.string(),
		indent: z.number(),
		type: ZModuleItemType,
		html_url: z.string().optional(),
		url: z.string().optional(),
		page_url: z.string().optional(),
		external_url: z.string().optional(),
		new_tab: z.boolean().optional(),
		completion_requirement: z
			.object({
				type: z.string(),
				min_score: z.number().optional(),
				completed: z.boolean(),
			})
			.optional(),
		content_id: z.number().optional(),
		quiz_lti: z.boolean(),
		publish_at: z.string().nullable().optional(),
	})
	.strict()

export type ModuleItem = z.infer<typeof ZModuleItem>

const mimeClasses: Record<string, string[]> = {
	audio: [
		"audio/x-mpegurl",
		"audio/x-pn-realaudio",
		"audio/x-aiff",
		"audio/3gpp",
		"audio/mid",
		"audio/x-wav",
		"audio/basic",
		"audio/mpeg",
		"application/ogg",
	],
	code: [
		"text/xml",
		"text/css",
		"text/x-yaml",
		"application/xml",
		"application/javascript",
		"text/x-csharp",
	],
	doc: [
		"application/x-docx",
		"text/rtf",
		"application/msword",
		"application/rtf",
		"application/vnd.oasis.opendocument.text",
		"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
		"application/vnd.apple.pages",
	],
	flash: ["application/x-shockwave-flash"],
	html: ["text/html", "application/xhtml+xml"],
	image: [
		"image/png",
		"image/x-psd",
		"image/gif",
		"image/pjpeg",
		"image/jpeg",
		"image/webp",
	],
	ppt: [
		"application/vnd.openxmlformats-officedocument.presentationml.presentation",
		"application/vnd.ms-powerpoint",
		"application/vnd.apple.keynote",
	],
	pdf: ["application/pdf"],
	text: ["text", "text/plain"],
	video: [
		"video/mp4",
		"video/x-ms-asf",
		"video/x-msvideo",
		"video/x-sgi-movie",
		"video/mpeg",
		"video/quicktime",
		"video/x-la-asf",
		"video/3gpp",
		"video/webm",
		"video/avi",
	],
	xls: [
		"application/vnd.oasis.opendocument.spreadsheet",
		"text/csv",
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		"application/vnd.ms-excel",
		"application/vnd.apple.numbers",
	],
	zip: [
		"application/x-rar-compressed",
		"application/x-zip-compressed",
		"application/zip",
		"application/x-zip",
		"application/x-rar",
	],
}

export const mimeTypeToClassMap: Record<string, string> = {}
for (const key in mimeClasses) {
	for (const mimetype of mimeClasses[key]) {
		mimeTypeToClassMap[mimetype] = key
	}
}

export const ZMimeClasses = z.enum([
	"audio",
	"code",
	"doc",
	"flash",
	"html",
	"image",
	"ppt",
	"pdf",
	"text",
	"video",
	"xls",
	"zip",
	"file",
])

export type ZMimeClassesType = z.infer<typeof ZMimeClasses>

export const ZMimeTypes = z.enum([
	"audio/x-mpegurl",
	"audio/x-pn-realaudio",
	"audio/x-aiff",
	"audio/3gpp",
	"audio/mid",
	"audio/x-wav",
	"audio/basic",
	"audio/mpeg",
	"application/ogg",

	"text/xml",
	"text/css",
	"text/x-yaml",
	"application/xml",
	"application/javascript",
	"text/x-csharp",

	"application/x-docx",
	"text/rtf",
	"application/msword",
	"application/rtf",
	"application/vnd.oasis.opendocument.text",
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	"application/vnd.apple.pages",

	"application/x-shockwave-flash",

	"text/html",
	"application/xhtml+xml",

	"image/png",
	"image/x-psd",
	"image/gif",
	"image/pjpeg",
	"image/jpeg",
	"image/webp",

	"application/vnd.openxmlformats-officedocument.presentationml.presentation",
	"application/vnd.ms-powerpoint",
	"application/vnd.apple.keynote",

	"application/pdf",

	"text",
	"text/plain",

	"video/mp4",
	"video/x-ms-asf",
	"video/x-msvideo",
	"video/x-sgi-movie",
	"video/mpeg",
	"video/quicktime",
	"video/x-la-asf",
	"video/3gpp",
	"video/webm",
	"video/avi",

	"application/vnd.oasis.opendocument.spreadsheet",
	"text/csv",
	"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	"application/vnd.ms-excel",
	"application/vnd.apple.numbers",

	"application/x-rar-compressed",
	"application/x-zip-compressed",
	"application/zip",
	"application/x-zip",
	"application/x-rar",
])

export type ZMimeTypesType = z.infer<typeof ZMimeTypes>

export const ZSubmissionTypes = z.enum([
	"attendance",
	"basic_lti_launch",
	"discussion_topic",
	"external_tool",
	"media_recording",
	"none",
	"not_graded",
	"on_paper",
	"online_quiz",
	"online_text_entry",
	"online_upload",
	"online_url",
	"student_annotation",
	"wiki_page",
])

export type SubmissionType = z.infer<typeof ZSubmissionTypes>

export const ZAttachment = z.object({
	displayName: z.string(),
	id: z.string(),
	mimeClass: ZMimeClasses,
	url: z.string(),
	contentType: ZMimeTypes,
})

export const ZSubmission = z.object({
	submittedAt: z.string(),
	body: z.string().nullable(),
	grade: z.string().nullable(),
	score: z.number().nullable(),
	submissionType: ZSubmissionTypes,
	attempt: z.number(),
	attachments: z.array(ZAttachment).nullable(),
	attachment: ZAttachment.nullable(),
})

export const ZSubmissions = z.array(ZSubmission)
export type ZAttachmentType = z.infer<typeof ZAttachment>
export type ZSubmissionType = z.infer<typeof ZSubmission>
export type ZSubmissionsType = z.infer<typeof ZSubmissions>

export const ZUser = z
	.object({
		_id: z.string(),
		name: z.string(),
	})
	.strict()

export const ZUserResponse = z
	.object({
		legacyNode: ZUser.nullable(),
	})
	.strict()

export type UserResponse = z.infer<typeof ZUserResponse>
export type User = z.infer<typeof ZUser>
