export const SCOPE_SELECTOR_HEIGHT = 70
export const INBOX_HEADER_HEIGHT = 70
export const MESSAGE_HEADER_HEIGHT = 40
export enum CONVERSATION_SCOPES {
	INBOX = "inbox",
	SENT = "sent",
	UNREAD = "unread",
	// STARRED = "starred",	add back when feature is added
	// ARCHIVED = "archived", add back when feature is added
}
