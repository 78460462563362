import type { TextAreaTheme } from "@instructure/shared-types"
import { borders } from "../borders"
import { forms } from "../forms"
import { spacing } from "../spacing"
import { typography } from "../typography"
import { ui } from "../ui"

const TextAreaThemeOverrides: Partial<TextAreaTheme> = {
	fontWeight: typography.fontWeightNormal,

	borderRadius: borders.radiusSmall,
	borderWidth: borders.widthSmall,
	borderStyle: borders.style,
	borderBottomColor: ui.containerStroke,
	borderLeftColor: ui.containerStroke,
	borderRightColor: ui.containerStroke,
	borderTopColor: ui.containerStroke,
	padding: spacing.small,
	focusOutlineColor: ui.surfaceInversePrimary,

	color: ui.textBody,
	placeholderColor: ui.textPlaceholder,
	background: ui.surfaceCardPrimary,

	errorBorderColor: ui.surfaceError,
	errorOutlineColor: ui.textError,

	smallFontSize: typography.fontSizeMedium,
	mediumFontSize: typography.fontSizeMedium,
	largeFontSize: typography.fontSizeMedium,

	smallHeight: forms.inputHeightSmall,
	mediumHeight: forms.inputHeightMedium,
	largeHeight: forms.inputHeightMedium,
}

export default {
	TextArea: TextAreaThemeOverrides,
}
