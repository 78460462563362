import { gqlClient } from "@/api/api.ts"
import { QUERY_KEYS } from "@/api/queryKeys.ts"
import type { SubmissionType } from "@/api/types.ts"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { gql } from "graphql-request"
import { z } from "zod"
import useValidateResponse from "../useValidateResponse.ts"

const ZCreateSubmissionResponse = z
	.object({
		createSubmission: z.object({
			submission: z
				.object({
					_id: z.string(),
					createdAt: z.string(),
				})
				.strict()
				.nullable(),
			errors: z
				.array(
					z
						.object({
							attribute: z.string(),
							message: z.string(),
						})
						.strict(),
				)
				.nullable(),
		}),
	})
	.strict()

type CreateSubmissionResponse = z.infer<typeof ZCreateSubmissionResponse>

const mutationFn = async ({
	assignmentLid,
	body,
	submissionType,
	fileIds,
}: {
	assignmentLid: string
	submissionType: SubmissionType
	body?: string
	fileIds?: number[]
}): Promise<CreateSubmissionResponse> => {
	const mutation = gql`
		mutation CreateSubmission($assignmentLid: ID!, $submissionType: OnlineSubmissionType!, $body: String, $fileIds: [ID!]) {
			createSubmission(
				input: {
					assignmentId: $assignmentLid,
					submissionType: $submissionType,
					body: $body,
					fileIds: $fileIds
				}
			) {
				submission {
					_id
					createdAt
				}
				errors {
					attribute
					message
				}
			}
		}`
	return (await gqlClient()).request(mutation, {
		assignmentLid,
		submissionType,
		body,
		fileIds,
	})
}

export const useCreateSubmission = () => {
	const queryClient = useQueryClient()
	const mutationResult = useMutation({
		mutationFn,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: QUERY_KEYS.ASSIGNMENT.all })
			queryClient.invalidateQueries({ queryKey: QUERY_KEYS.SUBMISSION.all })
		},
	})

	useValidateResponse(
		"useCreateSubmission",
		mutationResult,
		ZCreateSubmissionResponse,
	)
	return mutationResult
}
