import useValidateResponse from "@/api/useValidateResponse.ts"
import { APIROUTE, generateRoute } from "@/shared/router"
import { useMutation } from "@tanstack/react-query"
import { z } from "zod"
import { restClient } from "../api.ts"

const ZTokenData = z
	.object({
		access_token: z.string(),
		refresh_token: z.string(),
		user: z.object({
			id: z.number(),
			name: z.string(),
			global_id: z.string(),
			effective_locale: z.string(),
			fake_student: z.boolean().optional(),
		}),
		real_user: z
			.object({
				id: z.number(),
				name: z.string(),
				global_id: z.string(),
			})
			.optional(),
		token_type: z.string(),
		expires_in: z.number(),
		canvas_region: z.string(),
	})
	.strict()

type TokenData = z.infer<typeof ZTokenData>

const mutationFn = ({
	code,
	verifier,
}: {
	code: string
	verifier: string
}): Promise<TokenData> => {
	const apiRoute = generateRoute(APIROUTE.LOGIN, {})

	return restClient(
		apiRoute,
		{
			method: "POST",
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
			body: new URLSearchParams({
				client_id: import.meta.env.PUBLIC_CANVAS_CLIENT_ID,
				redirect_uri: `${import.meta.env.PUBLIC_HORIZON_HOST}/oauth2response`,
				grant_type: "authorization_code",
				code,
				code_verifier: verifier,
			}),
		},
		true,
	)
}

export const useExchangeOAuthCode = ({
	onSuccess,
	onError,
}: {
	onSuccess: (data: TokenData) => void
	onError: (error: Error) => void
}) => {
	const mutationResult = useMutation({
		mutationFn,
		onSuccess,
		onError,
	})

	useValidateResponse("useExchangeOAuthCode", mutationResult, ZTokenData)
	return mutationResult
}
