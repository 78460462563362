import { Flex, View } from "@instructure/ui"
import { Outlet } from "react-router-dom"

export const DesktopNavigationWrapper = ({
	children,
}: {
	children: React.ReactNode
}) => (
	<View
		as="div"
		overflowY="hidden"
		className="flex-container flex-align-item-start"
		height="100%"
	>
		<View as="nav" className="flex-item-25">
			<View
				as="div"
				padding="medium"
				background="secondary"
				borderColor="primary"
				borderWidth="small 0 0 0"
			>
				<Flex
					height="100vh"
					justifyItems="start"
					gap="small 0"
					direction="column"
				>
					{children}
				</Flex>
			</View>
		</View>
		<View as="div" className="flex-item-75" height="100vh" overflowY="hidden">
			<Outlet />
		</View>
	</View>
)
