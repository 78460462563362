import { QUERY_KEYS } from "@/api/queryKeys.ts"
import type { UserResponse } from "@/api/types.ts"
import { ZUser } from "@/api/types.ts"
import { type UseQueryResult, useQuery } from "@tanstack/react-query"
import { gql } from "graphql-request"
import type { z } from "zod"
import { gqlClient } from "../api.ts"
import useValidateResponse from "../useValidateResponse.ts"

export type UserQueryResponse = z.infer<typeof ZUser>

export const useGetUser = (
	id?: string,
): UseQueryResult<UserQueryResponse | null, Error> => {
	const queryKey = QUERY_KEYS.USER.byId(id || "")
	const queryResult = useQuery({
		queryKey,
		queryFn: async (): Promise<UserResponse> => {
			const query = gql`
				query GetUser($id: ID!) {
					legacyNode(_id: $id, type: User) {
						... on User {
							_id
							name
						}
					}
				}`
			return (await gqlClient()).request(query, { id })
		},
		enabled: !!id,
		select: (data) => data.legacyNode,
	})

	useValidateResponse(queryKey.toString(), queryResult, ZUser)
	return queryResult
}
