import { View } from "@instructure/ui"
import { LOREM } from "../../constants.ts"

export const Journey = () => {
	return (
		<View as="div" padding="small">
			{LOREM.split("\n").map((text) => (
				<p key={text.substring(0, 15)}>{text}</p>
			))}
		</View>
	)
}
