import type { SubmissionType } from "@/api/types.ts"
import { OnlineUploadSubmissionForm } from "@/features/learning_content/OnlineUploadSubmissionForm.tsx"
import { TextEntrySubmissionForm } from "@/features/learning_content/TextEntrySubmissionForm.tsx"
import {
	Button,
	IconTextLine,
	IconUploadLine,
	Text,
	View,
} from "@instructure/ui"
import { type FC, createElement, useState } from "react"

export const SUBMISSION_TYPE_LABELS: Partial<
	Record<SubmissionType, { label: string; icon: JSX.Element; component: FC }>
> = {
	online_text_entry: {
		label: "Text Entry",
		icon: <IconTextLine />,
		component: TextEntrySubmissionForm,
	},
	online_upload: {
		label: "File Upload",
		icon: <IconUploadLine />,
		component: OnlineUploadSubmissionForm,
	},
}

export const AssignmentSubmissionTypeSelector = ({
	submissionTypes,
}: { submissionTypes: SubmissionType[] }) => {
	const [selectedSubmissionType, setSelectedSubmissionType] =
		useState<SubmissionType | null>(null)

	return (
		<View as="div">
			{submissionTypes.length === 0 ? (
				<Text>No online submission</Text>
			) : (
				<>
					<View as="div">
						<Text>Choose a submission type</Text>
					</View>
					{submissionTypes.map((type) => {
						if (!SUBMISSION_TYPE_LABELS[type]) {
							return null
						}

						return (
							<Button
								key={type}
								renderIcon={SUBMISSION_TYPE_LABELS[type].icon}
								onClick={() => setSelectedSubmissionType(type)}
								display="block"
								margin="x-small 0"
								color={
									selectedSubmissionType === type ? "primary" : "secondary"
								}
							>
								{SUBMISSION_TYPE_LABELS[type].label}
							</Button>
						)
					})}
				</>
			)}

			{selectedSubmissionType &&
				SUBMISSION_TYPE_LABELS[selectedSubmissionType] &&
				createElement(SUBMISSION_TYPE_LABELS[selectedSubmissionType].component)}
		</View>
	)
}
