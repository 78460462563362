import { useGetModuleItem } from "@/api/queries/useGetModules.ts"
import { ContentWrapper } from "@/features/learning_content/ContentWrapper.tsx"
import { FetchingError } from "@/shared/loading/FetchingError.tsx"
import { GlobalLoader } from "@/shared/loading/GlobalLoader.tsx"
import { Button, View } from "@instructure/ui"
import { useParams } from "react-router-dom"

export const ExternalItem = () => {
	const { courseId = "", moduleId = "", itemId = "" } = useParams()
	const itemQuery = useGetModuleItem({
		courseId,
		moduleId,
		itemId,
	})

	if (itemQuery.isLoading) {
		return <GlobalLoader title="Loading..." />
	}

	if (
		itemQuery.isError ||
		!itemQuery.data ||
		!["ExternalUrl", "ExternalTool"].includes(itemQuery.data.type)
	) {
		return <FetchingError />
	}

	return (
		<ContentWrapper
			name={itemQuery.data.title}
			type={itemQuery.data.type}
			programName="Program Name"
			estimatedTime="20 Mins"
			courseId={courseId}
		>
			{itemQuery.data.new_tab ? (
				<View as="div" margin="medium">
					<Button
						href={itemQuery.data.external_url}
						target="_blank"
						display="block"
					>
						{`Open ${itemQuery.data.type === "ExternalUrl" ? "link" : "tool"} in new tab`}
					</Button>
				</View>
			) : (
				<View as="div" height="100%" overflowY="hidden">
					<iframe
						title={itemQuery.data.title}
						src={itemQuery.data.external_url}
						style={{
							border: "none",
							width: "100%",
							height: "100%",
						}}
					/>
				</View>
			)}
		</ContentWrapper>
	)
}
