import type { CLXTheme, ThemeObjectValue } from "@/themes/types.ts"

export type ButtonSize = "small" | "medium"

export type ButtonLevel = "primary" | "secondary"

export type ButtonVariant = "default" | "inverse" | "ai" | "beige"

export interface BaseButtonProps {
	level: ButtonLevel
	variant?: ButtonVariant
	size?: ButtonSize
	interaction?: "enabled" | "disabled" | "readonly"
	// biome-ignore lint/suspicious/noExplicitAny: allows to pass any prop to the component
	[key: string]: any
}

export const generateButtonTheme = (
	currentTheme: CLXTheme,
	level: ButtonLevel,
	variant?: ButtonVariant,
) => {
	const { ui, gradients } = currentTheme
	const baseComponentTheme = {
		...(currentTheme.componentOverrides.Button as ThemeObjectValue),
	}

	if (level === "primary") {
		switch (variant) {
			case "inverse":
				return {
					...baseComponentTheme,
					primaryColor: ui.textTitle,
					primaryBorderColor: "transparent",
					primaryBackground: ui.surfaceCardPrimary,
					primaryHoverBackground: ui.surfaceCardPrimary,
					primaryActiveBackground: ui.surfaceCardPrimary,
					primaryActiveBoxShadow: null,
				}
			case "ai":
				return {
					...baseComponentTheme,
					primaryColor: ui.textSurfaceColored,
					primaryBorderColor: "transparent",
					primaryBackground: gradients.blue,
					primaryHoverBackground: gradients.blue,
					primaryActiveBackground: gradients.blue,
					primaryActiveBoxShadow: null,
				}
		}
	}

	if (level === "secondary") {
		switch (variant) {
			case "beige":
				return {
					...baseComponentTheme,
					secondaryColor: ui.textTitle,
					secondaryBorderColor: "transparent",
					secondaryBackground: ui.surfacePagePrimary,
					secondaryHoverBackground: ui.surfacePageSecondary,
					secondaryActiveBackground: ui.surfacePageSecondary,
					secondaryActiveBoxShadow: null,
				}
		}
	}

	return baseComponentTheme
}
