import { gqlClient } from "@/api/api.ts"
import { QUERY_KEYS } from "@/api/queryKeys.ts"
import useValidateResponse from "@/api/useValidateResponse.ts"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { gql } from "graphql-request"
import { z } from "zod"

const ZAddConversationMessageResponse = z
	.object({
		addConversationMessage: z.object({
			conversationMessage: z
				.object({
					_id: z.string(),
					createdAt: z.string(),
				})
				.strict()
				.nullable(),
			errors: z
				.array(
					z
						.object({
							attribute: z.string(),
							message: z.string(),
						})
						.strict(),
				)
				.nullable(),
		}),
	})
	.strict()

type AddConversationMessageResponse = z.infer<
	typeof ZAddConversationMessageResponse
>

type AddConversationMessageProperties = {
	body: string
	conversationId: string
	includedMessages: string[]
	recipients: string[]
}

export const ADD_CONVERSATION_MESSAGE = gql`
    mutation AddConversationMessage(
		$attachmentIds: [ID!]
        $body: String!
        $conversationId: ID!
		$includedMessages: [ID!]
        $recipients: [String!]!
    ) {
        addConversationMessage(
            input: {
				attachmentIds: $attachmentIds
                body: $body
                conversationId: $conversationId
				includedMessages: $includedMessages
                recipients: $recipients
            }
        ) {
            conversationMessage {
                _id
                createdAt
            }
            errors {
                attribute
                message
            }
        }
    }
`

export const useAddConversationMessage = () => {
	const queryClient = useQueryClient()
	const mutationResult = useMutation({
		mutationFn: async (
			props: AddConversationMessageProperties,
		): Promise<AddConversationMessageResponse> =>
			(await gqlClient()).request(ADD_CONVERSATION_MESSAGE, {
				...props,
				attachmentIds: [],
			}),
		onSuccess: async () => {
			await queryClient.invalidateQueries({
				queryKey: QUERY_KEYS.CONVERSATION.all,
			})
		},
	})

	useValidateResponse(
		"useAddConversationMessage",
		mutationResult,
		ZAddConversationMessageResponse,
	)
	return mutationResult
}
