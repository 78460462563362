import { AiStarNavigationItem } from "@/features/navigation/AiNavigationItem.tsx"
import { Icon } from "@/icons/Icon.tsx"
import { primitives } from "@/themes/horizon/src/primitives.ts"
import { Flex, Text, View } from "@instructure/ui"
import { useRef } from "react"
import { NavLink } from "react-router-dom"

export const MobileNavigationItem = ({
	route,
	title,
	overrideActive,
	icon,
	customElement,
}: {
	route?: string
	title?: string
	icon?: string
	customElement?: boolean
	overrideActive?: boolean
}) => {
	const itemColorRef = useRef(primitives.grey57)
	const setItemColor = (isActive?: boolean) => {
		itemColorRef.current = isActive ? primitives.grey125 : primitives.grey57
	}

	if (customElement) {
		switch (icon) {
			case "ai:instui":
				return <AiStarNavigationItem />
			default:
				return null
		}
	}

	if (!route) {
		return null
	}

	return (
		<Flex.Item height="50px" width="50px">
			<NavLink
				to={route}
				style={{
					textDecoration: "none",
					color: itemColorRef.current,
				}}
			>
				{({ isActive }) => {
					const isItemActive = isActive || overrideActive

					setItemColor(isItemActive)

					return (
						<View as="div" width="100%" height="100%">
							<Flex
								direction="column"
								alignItems="center"
								justifyItems="center"
								padding="0"
								height="100%"
								width="100%"
								gap="xx-small"
							>
								<Icon name={icon} width={30} height={30} />
								{title && (
									<Text
										size="x-small"
										weight={isItemActive ? "bold" : "normal"}
									>
										{title}
									</Text>
								)}
							</Flex>
						</View>
					)
				}}
			</NavLink>
		</Flex.Item>
	)
}
