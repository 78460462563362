import { useAppContext } from "@/AppContext.tsx"
import { useGetUser } from "@/api/queries/useGetUser.ts"
import {
	getIsMasquerading,
	getIsTestStudent,
	getUserId,
} from "@/token-storage.ts"
import { Button, Flex, View } from "@instructure/ui"

const Masquerade = ({ children }: { children: React.ReactNode }) => {
	const { desktopMode } = useAppContext()
	const isMasquerading = getIsMasquerading()
	const isTestStudent = getIsTestStudent()
	const userQuery = useGetUser(getUserId())
	const username = userQuery.data?.name || "..."

	if (!isMasquerading) {
		return <>{children}</>
	}

	return (
		<Flex height="100vh" direction="column">
			<Flex.Item shouldGrow shouldShrink>
				<View
					as="div"
					borderWidth="small small none"
					borderColor="warning"
					themeOverride={{
						borderColorWarning: "#BF32A4",
						borderWidthSmall: "3px",
					}}
				>
					{children}
				</View>
			</Flex.Item>
			<Flex.Item>
				<View
					as="div"
					background="warning"
					themeOverride={{ backgroundWarning: "#BF32A4" }}
				>
					<Flex padding="xx-small x-small" gap="xx-small">
						<Flex.Item shouldGrow shouldShrink>
							{isTestStudent
								? "You are currently logged into Learner View"
								: `You are currently acting as ${username}`}
						</Flex.Item>
						<Flex.Item shouldShrink textAlign="end">
							{isTestStudent ? (
								<>
									<Button>Reset Learner</Button>
									<Button
										margin={desktopMode ? "0 0 0 xx-small" : "xx-small 0 0 0"}
									>
										Leave Learner View
									</Button>
								</>
							) : (
								<Button>Stop Acting as User</Button>
							)}
						</Flex.Item>
					</Flex>
				</View>
			</Flex.Item>
		</Flex>
	)
}

export default Masquerade
