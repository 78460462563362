import { PAGEROUTE } from "@/shared/router"

interface NavigationItem {
	icon: string
	title?: string
	route?: string
	overrideActive?: (location: Location) => boolean
	customElement?: boolean
	mobileOnly?: boolean
}

export const HOME: NavigationItem = {
	title: "Home",
	route: "/",
	icon: "home",
	overrideActive: (location: Location) =>
		[PAGEROUTE.NOTEBOOK].includes(location.pathname as PAGEROUTE),
}

export const LEARN: NavigationItem = {
	title: "Learn",
	route: PAGEROUTE.LEARN,
	icon: "format_list_bulleted",
}

const CAREER: NavigationItem = {
	title: "Career",
	route: PAGEROUTE.CAREER,
	icon: "conversion_path",
}

const INBOX: NavigationItem = {
	title: "Inbox",
	route: PAGEROUTE.INBOX,
	icon: "mail",
}

const AI: NavigationItem = {
	icon: "ai:instui",
	customElement: true,
	mobileOnly: true,
}

export const NAVIGATION_ITEMS_WITH_AI: NavigationItem[] = [
	HOME,
	LEARN,
	AI,
	CAREER,
	INBOX,
]

export const NAVIGATION_ITEMS: NavigationItem[] = [HOME, LEARN, CAREER, INBOX]
