import type { ZAttachmentType, ZSubmissionType } from "@/api/types"
import { RichContentRenderer } from "@/shared/components/RCE/RichContentRenderer/RichContentRenderer"
import { formatDate } from "@/shared/utils"
import { Flex, Text, View } from "@instructure/ui"
import { FileView } from "./FileView"
import "./SubmissionStyles.css"

type ViewSubmissionProps = ZSubmissionType & {
	isLastSubmission: boolean
	pointsPossible?: number | null
}

export const ViewSubmission = ({
	submittedAt,
	body,
	submissionType,
	attachment,
	attachments,
	grade,
	score,
	pointsPossible,
	attempt,
	isLastSubmission,
}: ViewSubmissionProps) => {
	const isTextEntry = submissionType === "online_text_entry"
	const isFileUpload = submissionType === "online_upload"

	return (
		<>
			<View as="div" margin="small 0" className="view-submission">
				<Flex direction="column">
					<Text size="large">
						{isLastSubmission ? "Last submission" : `Attempt ${attempt}`}
					</Text>
					{grade && <Text>Grade: {grade}</Text>}
					{score !== null && (
						<Text>
							Score: {score}
							{pointsPossible !== null && ` out of ${pointsPossible}`}
						</Text>
					)}
					<Text>{`Submitted: ${formatDate(submittedAt)}`}</Text>
					{isTextEntry && <RichContentRenderer content={body ?? ""} />}
					{isFileUpload &&
						(
							[attachment, ...(attachments ?? [])].filter(
								(e) => e,
							) as ZAttachmentType[]
						).map(({ id, ...rest }) => {
							return <FileView key={id} {...rest} />
						})}
				</Flex>
			</View>
		</>
	)
}
