import type React from "react"
import { Navigate } from "react-router-dom"
import { getUserId } from "../../token-storage.ts"

const ProtectedRoute = ({ element }: { element: React.ReactNode }) => {
	const isLoggedIn = getUserId()
	return isLoggedIn ? element : <Navigate to="/login" />
}

export default ProtectedRoute
