import type { FormFieldLabelTheme } from "@instructure/shared-types"
import { typography } from "../typography"
import { ui } from "../ui"

const FormFieldLabelOverrides: Partial<FormFieldLabelTheme> = {
	color: ui.textBody,
	fontWeight: typography.fontWeightNormal,
}

export default {
	FormFieldLabel: FormFieldLabelOverrides,
}
