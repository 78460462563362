// @ts-ignore
import { enhanceUserContent } from "@instructure/canvas-rce"
import { useEffect, useRef } from "react"
import { createMarkup } from "src/shared/utils/index"
import mathRenderer from "./mathRenderer.ts"

type RichContentRendererProps = {
	content: string
	ariaHidden?: boolean
}
export const RichContentRenderer = (props: RichContentRendererProps) => {
	const { content, ariaHidden } = props
	const contentRef = useRef(null)

	useEffect(() => {
		content && contentRef?.current && enhanceUserContent(contentRef)
		mathRenderer.debouncedDocumentRendering()
	}, [content])

	return (
		<div ref={contentRef}>
			<div
				tabIndex={ariaHidden ? -1 : undefined}
				className="user_content"
				// biome-ignore lint/security/noDangerouslySetInnerHtml: createMarkup function sanitize the input
				dangerouslySetInnerHTML={createMarkup(content)}
			/>
		</div>
	)
}
