import { Button, Modal, Text, View } from "@instructure/ui"
import {
	actions,
	useAppContext,
	useAppContextDispatch,
} from "../../AppContext.tsx"
import { subscribeOnceToChanges } from "../../token-storage.ts"

export const RefreshCredentialsModal = () => {
	const appContext = useAppContext()
	const dispatch = useAppContextDispatch()
	const isOpen = appContext.showingRefreshModal

	const loginHandler = () => {
		const popup = window.open("/login", "Horizon Login")
		subscribeOnceToChanges(() => {
			popup?.close()
			dispatch({ type: actions.SET_SHOW_REFRESH_MODAL, payload: false })
		})
	}

	return (
		<Modal
			open={isOpen}
			shouldCloseOnDocumentClick={false}
			size="large"
			variant="inverse"
			label="Log in again"
		>
			<Modal.Body padding="large">
				<View as="div" margin="small 0">
					<Text size="large">You've been logged out</Text>
				</View>
				<Text>Log in again in a new tab, and we'll save your place here.</Text>
				<View as="div" margin="small 0">
					<Button color="primary-inverse" onClick={loginHandler}>
						Log in again
					</Button>
				</View>
			</Modal.Body>
		</Modal>
	)
}
