import type {
	CourseProgression,
	UserCourse,
} from "@/api/queries/useGetCourses.ts"
import { getWeek, isToday, isYesterday } from "date-fns"
import { sanitize } from "dompurify"

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>

export const createMarkup = (dirty: string) => ({ __html: sanitize(dirty) })

export const formatDate = (date: string) => new Date(date).toLocaleString()

export const getDay = (date: string, locale = "en-US") => {
	const dateVal = new Date(date)
	if (isToday(dateVal)) {
		return "Today"
	}
	if (isYesterday(dateVal)) {
		return "Yesterday"
	}
	return dateVal.toLocaleString(locale, {
		year: "numeric",
		month: "numeric",
		day: "numeric",
	})
}

export function formatDateToMMDD(dateString: string, locale = "en-US"): string {
	return new Date(dateString).toLocaleString(locale, {
		month: "numeric",
		day: "numeric",
	})
}

export function formatDateForNotes(val: string | number) {
	return new Date(val).toLocaleString("en-US", {
		year: "numeric",
		month: "short",
		day: "numeric",
	})
}

export function formatNumberToPercentage(
	number: number | undefined,
	precision = 2,
) {
	if (number === undefined) {
		return "0%"
	}
	return `${number.toFixed(precision)}%`
}

export const formatDateForUser = (value: string | Date) => {
	const now = new Date()
	const inputDate = typeof value === "string" ? new Date(value) : value

	const diffInMs = now.getTime() - inputDate.getTime()
	const diffInMinutes = Math.floor(diffInMs / (1000 * 60))

	if (diffInMinutes === 0) {
		return "Now"
	}

	if (diffInMinutes < 60) {
		return `${diffInMinutes} min${diffInMinutes !== 1 ? "s" : ""} ago`
	}

	if (isToday(inputDate)) {
		inputDate.toLocaleDateString([], {
			hour: "numeric",
			minute: "numeric",
		})
	}
	if (isYesterday(inputDate)) {
		return "Yesterday"
	}
	if (getWeek(inputDate) === getWeek(now)) {
		return inputDate.toLocaleDateString([], { weekday: "long" })
	}

	if (inputDate.getFullYear() === now.getFullYear()) {
		return inputDate.toLocaleDateString([], {
			weekday: "long",
			day: "numeric",
			month: "numeric",
		})
	}

	return inputDate.toLocaleDateString([], {
		year: "numeric",
		day: "numeric",
		month: "numeric",
		weekday: "long",
	})
}

export function getCourseProgressionValues(
	courseProgression: CourseProgression | null,
) {
	if (!courseProgression) {
		return {
			completed: 0,
			total: 0,
			completionPercentage: 0,
		}
	}

	return {
		completed: courseProgression?.requirements.completed ?? 0,
		total: courseProgression?.requirements.total ?? 0,
		completionPercentage:
			courseProgression?.requirements.completionPercentage ?? 0,
	}
}

export function getModuleItemNameAndType(course: UserCourse) {
	const defaultObj = {
		name: undefined,
		__typename: undefined,
		dueAt: undefined,
	}
	const { name, __typename, dueAt } =
		course.course.usersConnection?.nodes[0].courseProgression
			?.incompleteModulesConnection.nodes[0]?.incompleteItemsConnection.nodes[0]
			?.nextItemsConnection.nodes[0]?.content ?? defaultObj

	const moduleItemName =
		name ??
		course.course.modulesConnection.nodes[0]?.moduleItems[0]?.content.name
	const moduleItemType =
		__typename ??
		course.course.modulesConnection.nodes[0]?.moduleItems[0]?.content.__typename

	return {
		moduleItemName,
		moduleItemType,
		dueAt,
	}
}

export type IconProperties = {
	remoteName: string
	localName?: string
	size?: 20 | 24 | 40 | 48
	fill?: boolean
	grad?: "200" | "N25"
	wght?: 100 | 200 | 300 | 500 | 600 | 700
	color?: string
}

export const getIconFileName = (
	iconProps: IconProperties,
	useOriginalName?: boolean,
): string => {
	const icon = {
		size: 24,
		weight: 300,
		...iconProps,
	}
	const options = []

	if (icon.weight !== 400) {
		options.push(`wght${icon.weight}`)
	}

	if (icon.grad) {
		options.push(`grad${icon.grad}`)
	}

	if (icon.fill) {
		options.push("fill1")
	}

	const size = `${options.length ? "_" : ""}${icon.size}px`
	const iconName = useOriginalName
		? icon.remoteName
		: icon.localName || icon.remoteName

	return `${iconName}_${options.join("")}${size}.svg`
}
