import { compile } from "path-to-regexp"

// Utility type to extract dynamic variables and map them to an object with optional properties
type ExtractParamsAsObject<Route extends string> =
	Route extends `${string}:${infer Param}/${infer Rest}`
		? { [K in Param | keyof ExtractParamsAsObject<`/${Rest}`>]: string }
		: Route extends `${string}:${infer Param}`
			? { [K in Param]: string }
			: object

export enum PAGEROUTE {
	HOME = "/",

	LEARN = "/learn",
	COURSE = "/learn/:courseId",
	PRECOURSESURVEY = "/learn/:courseId/pre-course-survey",
	ASSIGNMENT = "/learn/:courseId/assignments/:assignmentId",
	QUIZ = "/learn/:courseId/quizzes/:assignmentId",
	PAGE = "/learn/:courseId/pages/:pageId",
	EXTERNALITEM = "/learn/:courseId/modules/:moduleId/items/:itemId",
	FILECONTENT = "/learn/:courseId/files/:fileId",

	CAREER = "/career",
	INBOX = "/inbox",
	NOTEBOOK = "/notebook",
	NOTIFICATION = "/notification",

	LOGIN = "/login",
	LOGOUT = "/logout",
	UNAUTHORIZED = "/unauthorized",
	OAUTH2RESPONSE = "/oauth2response",
}

export enum APIROUTE {
	LOGIN = "login/oauth2/token",
	FILE = "api/v1/courses/:courseId/files/:fileId",
	ITEMS = "api/v1/courses/:courseId/modules/:moduleId/items",
	ITEM = "api/v1/courses/:courseId/modules/:moduleId/items/:itemId",
	PAGE = "api/v1/courses/:courseId/pages/:pageId",
	ASSIGNMENT = "api/v1/courses/:courseId/assignments/:assignmentId",
	SESSIONLESSLAUNCH = "api/v1/courses/:courseId/external_tools/sessionless_launch",
	NOTIFICATION = "api/v1/users/:userId/planner/items",
}

type RouteParams = {
	[K in APIROUTE | PAGEROUTE]: ExtractParamsAsObject<K>
}

export const generateRoute = <T extends APIROUTE | PAGEROUTE>(
	path: T,
	params: RouteParams[T],
	queryParam?: Record<string, string | number>[],
) => {
	const toPath = compile(path)
	const route = toPath(params)

	if (queryParam?.length) {
		return `${route}?${queryParam.map((q) => `${Object.keys(q)[0]}=${Object.values(q)[0]}`).join("&")}`
	}

	return route
}

export enum SearchParams {
	ADD_NOTE = "add_note",
	EDIT_NOTE = "edit_note",
	VIEW_NOTE = "view_note",
}
