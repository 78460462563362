import type { ZMimeTypesType } from "@/api/types"
import { Flex } from "@instructure/ui"

export const VideoContent = ({
	url,
	content_type,
}: { url: string; content_type: ZMimeTypesType }) => {
	const contentType =
		content_type === "video/quicktime" ? "video/mp4" : content_type
	return (
		<Flex as="div" margin="medium auto" justifyItems="center">
			{/* biome-ignore lint/a11y/useMediaCaption: */}
			<video controls>
				<source src={url} type={contentType} />
			</video>
		</Flex>
	)
}
