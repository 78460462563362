import type { PillTheme } from "@instructure/shared-types"
import { primitives } from "../primitives"
import { spacing } from "../spacing.ts"
import { typography } from "../typography.ts"

const PillThemeThemeOverrides: Partial<PillTheme> = {
	fontFamily: typography.fontFamily,
	primaryColor: primitives.grey125,
	dangerColor: primitives.red70,
	padding: `${spacing.xSmall} ${spacing.small}`,
}

export default {
	Pill: PillThemeThemeOverrides,
}
