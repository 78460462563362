import { QUERY_KEYS } from "@/api/queryKeys.ts"
import { APIROUTE, generateRoute } from "@/shared/router"
import { useQuery } from "@tanstack/react-query"
import { gql } from "graphql-request"
import { z } from "zod"
import { gqlClient, restClient } from "../api.ts"
import useValidateResponse from "../useValidateResponse.ts"

const ZPage = z.object({
	_id: z.string(),
	title: z.string(),
	courseName: z.string(),
})

const ZPageResponse = z
	.object({
		legacyNode: ZPage,
		course: z.object({
			name: z.string(),
		}),
	})
	.strict()

type PageResponse = z.infer<typeof ZPageResponse>

export type PageProperties = {
	courseId: string
	pageId: string
}

const ZPageContentResponse = z.object({
	title: z.string(),
	url: z.string(),
	page_id: z.number(),
	published: z.boolean(),
	body: z.string(),
})

type PageContentResponse = z.infer<typeof ZPageContentResponse>

export const useGetPage = (props: PageProperties) => {
	const queryKey = QUERY_KEYS.PAGE.details(props)
	const queryResult = useQuery({
		queryKey,
		queryFn: async (): Promise<PageResponse> => {
			const query = gql`
				query GetPage($courseId: ID!, $pageId: ID!) {
					legacyNode(_id: $pageId, type: Page) {
						... on Page {
							_id
							title
						}
					}
					course(id: $courseId) {
						name
					}
				}`
			return (await gqlClient()).request(query, props)
		},
		select: (data) => ({
			...data.legacyNode,
			courseName: data.course.name,
		}),
	})

	useValidateResponse(queryKey.toString(), queryResult, ZPage)
	return queryResult
}

export const useGetPageContent = (props: PageProperties) => {
	const queryKey = QUERY_KEYS.PAGE.content(props)
	const queryResult = useQuery({
		queryKey,
		queryFn: async (): Promise<PageContentResponse> => {
			const pageRoute = generateRoute(APIROUTE.PAGE, props)
			return restClient(pageRoute)
		},
	})

	useValidateResponse(queryKey.toString(), queryResult, ZPageContentResponse)
	return queryResult
}
