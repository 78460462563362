import { setCodeVerifier } from "@/features/login/pkce-verifier-storage.ts"
import pkceChallenge from "pkce-challenge"

export const createRedirectUrl = async () => {
	const challenge = await pkceChallenge()
	setCodeVerifier(challenge.code_verifier)
	const url = new URL(`${import.meta.env.PUBLIC_CANVAS_HOST}/login/oauth2/auth`)
	url.searchParams.append("client_id", import.meta.env.PUBLIC_CANVAS_CLIENT_ID)
	url.searchParams.append(
		"redirect_uri",
		`${import.meta.env.PUBLIC_HORIZON_HOST}/oauth2response`,
	)
	url.searchParams.append("response_type", "code")
	url.searchParams.append("code_challenge", challenge.code_challenge)
	url.searchParams.append("code_challenge_method", "S256")
	return url.toString()
}
