import { Flex, Text, View } from "@instructure/ui"
import { NavLink } from "react-router-dom"

export const DesktopNavigationItem = ({
	route,
	title,
	overrideActive,
}: {
	route?: string
	title?: string
	overrideActive?: boolean
}) => {
	if (!route) {
		return null
	}

	return (
		<Flex.Item height="4em" width="100%">
			<NavLink
				to={route}
				style={{
					textDecoration: "none",
				}}
			>
				{({ isActive }) => (
					<View
						as="div"
						background={
							isActive || overrideActive ? "primary-inverse" : "primary"
						}
						width="100%"
						height="100%"
					>
						<Flex
							alignItems="center"
							justifyItems="start"
							padding="0 0 0 medium"
							height="100%"
						>
							<Text size="small">{title}</Text>
						</Flex>
					</View>
				)}
			</NavLink>
		</Flex.Item>
	)
}
