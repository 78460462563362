import { type NoteTag, searchNotesByTextAndTag } from "@/api/db/db"
import { QUERY_KEYS } from "@/api/queryKeys"
import { useQuery } from "@tanstack/react-query"
import { useState } from "react"

export const useSearchNotesByTextAndTag = (
	userId: string,
	courseId: string,
) => {
	const [searchText, setSearchText] = useState<string>("")
	const [tags, setTags] = useState<Set<NoteTag>>(new Set())
	const queryKey = QUERY_KEYS.NOTE.bySearchTagAndText(
		userId,
		courseId,
		Array.from(tags),
		searchText,
	)
	return {
		setSearchText,
		tags,
		setTags,
		noteSearchQuery: useQuery({
			enabled: !!userId && !!courseId,
			queryKey,
			queryFn: async () => {
				return searchNotesByTextAndTag(
					userId,
					courseId,
					searchText,
					new Set(tags),
				)
			},
		}),
	}
}
