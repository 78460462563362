import { deleteNote } from "@/api/db/db"
import { QUERY_KEYS } from "@/api/queryKeys"
import { useMutation, useQueryClient } from "@tanstack/react-query"

export const useDeleteNote = () => {
	const queryClient = useQueryClient()
	const mutationResult = useMutation({
		mutationFn: (id: number) => deleteNote(id),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: QUERY_KEYS.NOTE.all })
		},
	})

	return mutationResult
}
