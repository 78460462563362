import { type ClientError, GraphQLClient } from "graphql-request"
import { Ollama } from "ollama/browser"
import * as TokenStorage from "../token-storage.ts"
export class ExpiredTokenError extends Error {
	constructor() {
		super("Could not obtain a valid access token.")
		Object.setPrototypeOf(this, ExpiredTokenError.prototype)
	}
}

const getCurrentAccessToken = async () => {
	const accessToken = TokenStorage.getAccessToken()
	if (accessToken) {
		return accessToken
	}

	const refreshToken = TokenStorage.getRefreshToken()
	if (refreshToken) {
		return await refreshAccessToken(refreshToken)
	}

	// Both the access and refresh tokens are expired!
	throw new ExpiredTokenError()
}

const refreshAccessToken = async (refreshToken: string) => {
	try {
		const json = await restClient(
			"login/oauth2/token",
			{
				method: "POST",
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
				body: new URLSearchParams({
					grant_type: "refresh_token",
					client_id: import.meta.env.PUBLIC_CANVAS_CLIENT_ID,
					refresh_token: refreshToken,
				}),
			},
			true,
		)

		TokenStorage.setTokenData({
			userId: json.user.id.toString(),
			accessToken: json.access_token,
			refreshToken: json.refresh_token,
		})

		return json.access_token
	} catch (_error) {
		// If refreshing the token fails for any reason, we don't want to mess up any existing state, so we'll
		// show the modal to login again.
		throw new ExpiredTokenError()
	}
}

export const gqlClient = async () => {
	const client = new GraphQLClient(
		`${import.meta.env.PUBLIC_HORIZON_HOST}/canvas/api/graphql`,
		{
			method: "POST",
			headers: {
				Authorization: `Bearer ${await getCurrentAccessToken()}`,
			},
		},
	)

	const request = async <T>(query: string, variables?: object): Promise<T> => {
		try {
			return await client.request(query, variables)
		} catch (error) {
			// Check if the token has been revoked before we know it expired
			if ((error as ClientError).response?.status === 401) {
				throw new ExpiredTokenError()
			}
			return Promise.reject(error)
		}
	}

	return { request }
}

export const restClient = async (
	endpoint: string,
	options: RequestInit = {},
	skipAuthorizationHeader = false,
) => {
	const defaultOptions: RequestInit = {
		method: "GET",
		headers: {
			Authorization: skipAuthorizationHeader
				? ""
				: `Bearer ${await getCurrentAccessToken()}`,
			"Content-Type": "application/json",
		},
	}

	const response = await fetch(
		`${import.meta.env.PUBLIC_HORIZON_HOST}/canvas/${endpoint}`,
		{ ...defaultOptions, ...options },
	)

	if (!response.ok) {
		// Check if the token has been revoked before we know it expired
		if (response.status === 401) {
			throw new ExpiredTokenError()
		}
		const errorBody = await response.json().catch(() => ({}))
		throw new Error(
			`restClient received a bad response: ${response.status} ${response.statusText}: ${errorBody.message}`,
		)
	}
	return await response.json()
}

export const aiClient = new Ollama({ host: import.meta.env.PUBLIC_AI_HOST })
