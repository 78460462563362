import { spacing } from "../spacing.ts"
import { typography } from "../typography.ts"

export default {
	IconButton: {
		smallPaddingHorizontal: spacing.xSmall,
		smallPaddingTop: spacing.xSmall,
		smallPaddingBottom: spacing.xSmall,

		mediumPaddingHorizontal: spacing.smallMedium,
		mediumPaddingTop: spacing.smallMedium,
		mediumPaddingBottom: spacing.smallMedium,

		iconSizeSmall: typography.fontSizeMedium,
		iconSizeMedium: typography.fontSizeXLarge,
	},
}
