import { borders } from "../borders.ts"
import { primitives } from "../primitives.ts"
import { ui } from "../ui"

export default {
	FileDrop: {
		backgroundColor: ui.surfaceCardPrimary,
		borderRadius: borders.radiusMedium,
		borderWidth: borders.widthMedium,
		borderColor: primitives.grey14,
		acceptedColor: primitives.grey14,
		rejectedColor: primitives.grey14,
		hoverBorderColor: ui.surfaceAttention,
		/*
        acceptedColor: colors?.contrasts?.blue4570,
        rejectedColor: colors?.contrasts?.red4570*/
	},
}
