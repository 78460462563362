import { useGetCourseName } from "@/api/queries/useGetCourses"
import { useGetFileContent } from "@/api/queries/useGetFileContent"
import { FetchingError } from "@/shared/loading/FetchingError"
import { GlobalLoader } from "@/shared/loading/GlobalLoader"
import { useParams } from "react-router-dom"
import { ContentWrapper } from "./ContentWrapper"
import { FileView } from "./FileView"

export const FileContent = () => {
	const { courseId = "", fileId = "" } = useParams()
	const courseNameQuery = useGetCourseName(courseId)
	const fileQuery = useGetFileContent({ courseId, fileId })

	if (fileQuery.isLoading || courseNameQuery.isLoading) {
		return <GlobalLoader title="Loading..." />
	}

	if (
		fileQuery.isError ||
		!fileQuery.data ||
		courseNameQuery.isError ||
		!courseNameQuery.data
	) {
		return <FetchingError />
	}

	const { content_type, url, mime_class, display_name } = fileQuery.data

	return (
		<ContentWrapper
			name={fileQuery.data.display_name}
			type="Page"
			programName={courseNameQuery.data.courseName}
			estimatedTime="20 Mins"
			courseId={courseId}
		>
			<FileView
				mimeClass={mime_class}
				url={url}
				displayName={display_name}
				contentType={content_type}
			/>
		</ContentWrapper>
	)
}
