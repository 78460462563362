// @ts-ignore
import CanvasRce from "@instructure/canvas-rce/es/rce/RCE"
import { FormField } from "@instructure/ui"
import classNames from "classnames"
import { type SyntheticEvent, useCallback, useEffect, useState } from "react"
import type { Editor } from "tinymce"
import { mapQuizRcePropsToCanvasRceProps } from "./rce5utils"
import "./input.css"

const errorMessageType = "error"

type RCEInputProps = {
	autoFocus?: boolean
	textareaId: string
	defaultContent: string
	stem?: string
	height?: number | null
	actsAsInput?: boolean
	readOnly?: boolean
	placeholder?: string
	label: React.ReactNode
	messages?: {
		type: "hint" | "success" | "error" | "screenreader-only"
		text: string
	}[]
	onChange?: (editorContent: string) => void
	onFocus?: (event: SyntheticEvent, content: { editorContent: string }) => void
	onBlur?: (event: SyntheticEvent, content: { editorContent: string }) => void
	editorOptions?: {
		spellCheck: boolean
		wordCount: boolean
	}
}

export function RCEInput(props: RCEInputProps) {
	const {
		defaultContent = "",
		readOnly = false,
		onChange = () => {},
		onBlur = () => {},
		messages = [],
		textareaId,
		label,
	} = props

	const [editorContent, setEditorContent] = useState(defaultContent)

	useEffect(() => {
		onChange(editorContent)
	}, [editorContent, onChange])

	const invalid = () => {
		return (messages ?? []).some((message) => message.type === errorMessageType)
	}

	const handleContentChange = (content: string) => {
		setEditorContent(content)
	}

	const handleOnBlur = (e: SyntheticEvent) => {
		if (editorContent) {
			onBlur(e, { editorContent })
		}
	}

	const handleEditorInit = useCallback(
		(
			tinyEditorInstance: Editor & {
				rceWrapper: { iframe: { title: string } }
			},
		) => {
			if (tinyEditorInstance?.selection) {
				tinyEditorInstance.selection.select(tinyEditorInstance.getBody(), true)
				tinyEditorInstance.selection.collapse(false)
			}
			const screenreaderText = tinyEditorInstance.rceWrapper.iframe.title
			tinyEditorInstance.dom.setAttrib(
				tinyEditorInstance.dom.select("body"),
				"aria-label",
				screenreaderText,
			)
		},
		[],
	)

	return (
		<FormField messages={messages} label={label} id={textareaId}>
			<div
				className={classNames({
					"invalid-rce": invalid(),
					"readonly-rce": readOnly,
				})}
				data-automation="sdk-rce-opened"
				data-testid="RichContentInput"
				aria-invalid={invalid() ? "true" : undefined}
			>
				<CanvasRce
					{...mapQuizRcePropsToCanvasRceProps({
						...props,
						onBlur: handleOnBlur,
						onContentChange: handleContentChange,
						onInit: handleEditorInit,
					})}
				/>
			</div>
		</FormField>
	)
}
