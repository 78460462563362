import type { UserCourse } from "@/api/queries/useGetCourses"
import type { UserQueryResponse } from "@/api/queries/useGetUser"
import { SearchHeader } from "@/shared/header/SearchHeader"
import { LearningObjectIcon } from "@/shared/learning_object_metadata/LearningObjectIcon"
import ProgressBar from "@/shared/progress_bar/ProgressBar"
import { PAGEROUTE, generateRoute } from "@/shared/router"
import {
	formatDateToMMDD,
	formatNumberToPercentage,
	getCourseProgressionValues,
	getModuleItemNameAndType,
} from "@/shared/utils"
import {
	Button,
	Flex,
	Heading,
	IconClockLine,
	Img,
	Text,
	View,
	canvas,
} from "@instructure/ui"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Alert } from "./Alert"

export const MobileHome = ({
	userData,
	coursesData,
}: { userData: UserQueryResponse; coursesData: UserCourse[] }) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	return (
		<View as="div">
			<SearchHeader />

			<View as="div" margin="medium">
				<Text size="large">{`${t("home.hi")}, ${userData.name}`}</Text>
			</View>

			<Alert type="info">
				Important educator- or institution-triggered notification links to the
				inbox...
			</Alert>

			{coursesData.map((course, index) => {
				const { completed, total, completionPercentage } =
					getCourseProgressionValues(
						course.course.usersConnection?.nodes[0].courseProgression,
					)
				const isCourseStarted = completionPercentage > 0

				const { moduleItemName, moduleItemType, dueAt } =
					getModuleItemNameAndType(course)
				return (
					<View
						key={course.course._id}
						as="div"
						background="secondary"
						margin="medium 0"
						padding="medium"
					>
						{index === 0 && (
							<View as="div" margin="0 0 medium 0">
								<Text>Jump back in</Text>
							</View>
						)}
						<Heading level="h3">{course.course.name}</Heading>
						<ProgressBar
							valueNow={completed}
							valueMax={total}
							completionPercentage={completionPercentage}
							renderValue={() => (
								<Text>{formatNumberToPercentage(completionPercentage)}</Text>
							)}
						/>
						<View as="div" background="primary" padding="medium">
							<div style={{ width: "100%", aspectRatio: "1.795" }}>
								<View
									themeOverride={{
										backgroundSecondary: canvas.colors.primitives.grey14,
									}}
									as="div"
									background="secondary"
									width="100%"
									height="100%"
									overflowX="hidden"
									overflowY="hidden"
								>
									{course.course.imageUrl && (
										<Img
											src={course.course.imageUrl}
											display="block"
											loading="lazy"
											constrain="cover"
										/>
									)}
								</View>
							</div>

							<View as="div" margin="small 0">
								<Text>
									{course.course.modulesConnection.nodes[0]?.name ||
										"Module Name"}
								</Text>
							</View>
							<View as="div">
								<Text size="large">{moduleItemName}</Text>
							</View>
							<Flex as="div" margin="small 0" direction="column" gap="small">
								<Flex.Item shouldGrow={true} shouldShrink={true}>
									<Text color="secondary">
										<LearningObjectIcon type={moduleItemType} />{" "}
										{moduleItemType}
									</Text>
								</Flex.Item>

								{dueAt && (
									<Flex.Item>
										<Text color="secondary">
											<IconClockLine /> {`Due Date ${formatDateToMMDD(dueAt)}`}
										</Text>
									</Flex.Item>
								)}
							</Flex>
							<Button
								color="secondary"
								display="block"
								margin="medium 0 0"
								onClick={() => {
									const coursePage = generateRoute(PAGEROUTE.COURSE, {
										courseId: course.course._id,
									})
									navigate(coursePage)
								}}
							>
								{isCourseStarted ? "Continue" : "Start"}
							</Button>
						</View>
					</View>
				)
			})}
		</View>
	)
}
