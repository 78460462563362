import { useMutation } from "@tanstack/react-query"

const mutationFn = async ({
	uploadUrl,
	uploadParams,
	file,
}: {
	uploadUrl: string
	uploadParams: Record<string, string>
	file: File
}): Promise<undefined> => {
	const formData = new FormData()
	for (const key in uploadParams) {
		formData.append(key, uploadParams[key])
	}
	formData.append("file", file)

	// Calling fetch directly since this is not a Canvas url
	const response = await fetch(uploadUrl, {
		method: "POST",
		body: formData,
	})
	if (!response.ok) {
		throw new Error("Failed to upload file")
	}
	// The response is not useful; a 2xx is confirmation that we can move on
}

export const useUploadFileSubmission = () => {
	return useMutation({
		mutationFn,
	})
}
