import type { UserCourse } from "@/api/queries/useGetCourses"
import type { UserQueryResponse } from "@/api/queries/useGetUser"
import { CLXPill } from "@/shared/components/Pill/Pill.tsx"
import { SearchHeader } from "@/shared/header/SearchHeader"
import { LearningObjectIcon } from "@/shared/learning_object_metadata/LearningObjectIcon"
import ProgressBar from "@/shared/progress_bar/ProgressBar"
import { PAGEROUTE, generateRoute } from "@/shared/router"
import {
	formatDateToMMDD,
	formatNumberToPercentage,
	getCourseProgressionValues,
	getModuleItemNameAndType,
} from "@/shared/utils"
import {
	Button,
	Flex,
	Heading,
	IconClockLine,
	Img,
	Text,
	View,
	canvas,
} from "@instructure/ui"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Alert } from "./Alert"

export const DesktopHome = ({
	userData,
	coursesData,
}: { userData: UserQueryResponse; coursesData: UserCourse[] }) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	return (
		<View as="div">
			<Alert type="info">
				Important educator- or institution-triggered notification links to the
				inbox...
			</Alert>
			<Flex as="div" justifyItems="space-between">
				<View as="div" margin="medium">
					<Text size="large">{`${t("home.hi")}, ${userData.name}`}</Text>
				</View>
				<Flex.Item shouldGrow>
					<SearchHeader />
				</Flex.Item>
			</Flex>
			<Flex direction="column" gap="large">
				{coursesData.map((course, index) => {
					const { completed, total, completionPercentage } =
						getCourseProgressionValues(
							course.course.usersConnection?.nodes[0].courseProgression,
						)
					const isCourseStarted = completionPercentage > 0

					const { moduleItemName, moduleItemType, dueAt } =
						getModuleItemNameAndType(course)

					return (
						<Flex
							key={course.course._id}
							justifyItems="space-between"
							margin="0 medium 0 0"
							alignItems="start"
						>
							<Flex.Item size="59%">
								<View as="div" background="secondary" padding="medium">
									<View as="div" margin="small 0">
										<Text>Jump back in</Text>
									</View>
									<Flex
										justifyItems="space-between"
										gap="large"
										margin="0 0 large 0"
										direction="column"
									>
										<Flex.Item>
											<Heading level="h3">{course.course.name}</Heading>
										</Flex.Item>

										<Flex.Item>
											<ProgressBar
												valueNow={completed}
												valueMax={total}
												completionPercentage={completionPercentage}
												renderValue={() => (
													<Text>
														{formatNumberToPercentage(completionPercentage)}
													</Text>
												)}
											/>
										</Flex.Item>
									</Flex>
									<Flex justifyItems="space-between">
										{course.course.modulesConnection.nodes.map((module) => {
											return (
												<View
													key={module._id}
													as="div"
													padding="medium"
													background="primary"
													shadow="resting"
												>
													<Flex as="div" margin="0" direction="column">
														<Img
															src="https://fastly.picsum.photos/id/4/216/171.jpg?hmac=GPSXuc_1nLgNcouXpzENVnsmbbGMbF2ZTsv4n4T3Ha8"
															width={216}
															height={171}
														/>
														<View as="div" margin="small 0">
															<Text>
																{course.course.modulesConnection.nodes[0]
																	?.name || "Module Name"}
															</Text>
														</View>
														<View as="div">
															<Text size="large">{moduleItemName}</Text>
														</View>
														<Flex
															as="div"
															margin="small 0"
															direction="column"
															gap="small"
														>
															<Flex.Item shouldGrow={true} shouldShrink={true}>
																<Text color="secondary">
																	<LearningObjectIcon type={moduleItemType} />{" "}
																	{moduleItemType}
																</Text>
															</Flex.Item>

															{dueAt && (
																<Flex.Item>
																	<Text color="secondary">
																		<IconClockLine />{" "}
																		{`Due Date ${formatDateToMMDD(dueAt)}`}
																	</Text>
																</Flex.Item>
															)}
														</Flex>
														<CLXPill
															margin="small 0 0 0"
															isBorderVisible={false}
														>
															In Progress
														</CLXPill>
														<Button
															color="primary-inverse"
															display="block"
															margin="medium 0 0"
															themeOverride={{
																borderRadius: "1.5rem",
															}}
															onClick={() =>
																navigate(`/programs/${course.course._id}`)
															}
														>
															{isCourseStarted ? "Continue" : "Start"}
														</Button>
													</Flex>
												</View>
											)
										})}
									</Flex>
								</View>
							</Flex.Item>
							<Flex.Item size="39%">
								{index === 0 && (
									<View as="div" background="secondary" padding="medium">
										<View as="div" margin="0 0 medium 0">
											<Text>Inbox</Text>
										</View>
										{["first", "second", "third", "fourth"].map((val) => {
											return (
												<View
													key={val}
													as="div"
													background="primary"
													padding="medium"
													margin="mediumSmall 0"
												>
													<Flex
														justifyItems="space-between"
														margin="0 0 small 0"
													>
														<Text size="small">Sender Name</Text>
														<Text size="small">
															{course.course.account.name}
														</Text>
													</Flex>
													<Text>
														Preview of notification lorem ipsum color sit
													</Text>
												</View>
											)
										})}
										<Button
											color="secondary"
											display="block"
											margin="medium 0 0"
											themeOverride={{
												borderRadius: "1.5rem",
												secondaryBorderColor: canvas.colors.primitives.grey100,
											}}
											onClick={() => {
												const coursePage = generateRoute(PAGEROUTE.COURSE, {
													courseId: course.course._id,
												})
												navigate(coursePage)
											}}
										>
											Start
										</Button>
									</View>
								)}
								{index === 1 && (
									<View as="div" background="secondary" padding="medium">
										<Text>Notebook</Text>
									</View>
								)}
							</Flex.Item>
						</Flex>
					)
				})}
			</Flex>
		</View>
	)
}
