import { QUERY_KEYS } from "@/api/queryKeys.ts"
import { APIROUTE, generateRoute } from "@/shared/router"
import { useQuery } from "@tanstack/react-query"
import { z } from "zod"
import { restClient } from "../api.ts"
import useValidateResponse from "../useValidateResponse.ts"

const ZSessionlessLaunchUrlResponse = z.object({
	id: z.number(),
	name: z.string(),
	url: z.string(),
})

type SessionlessLaunchUrlResponse = z.infer<
	typeof ZSessionlessLaunchUrlResponse
>

export type LaunchUrlProperties = { courseId: string; assignmentId: string }

export const useGetSessionlessLaunchUrl = (props: LaunchUrlProperties) => {
	const queryKey = QUERY_KEYS.SESSIONLESS_LAUNCH_URL.byProperties(props)
	const queryResult = useQuery({
		queryKey,
		queryFn: async (): Promise<SessionlessLaunchUrlResponse> => {
			const apiRoute = generateRoute(
				APIROUTE.SESSIONLESSLAUNCH,
				{ courseId: props.courseId },
				[{ launch_type: "assessment" }, { assignment_id: props.assignmentId }],
			)

			return restClient(apiRoute)
		},
		// The url is ephemeral, so this should be fetched once each time the component is
		// mounted, but not in any other circumstances.
		staleTime: Number.POSITIVE_INFINITY,
		gcTime: 0,
		refetchOnMount: "always",
	})

	useValidateResponse(
		queryKey.toString(),
		queryResult,
		ZSessionlessLaunchUrlResponse,
	)
	return queryResult
}
