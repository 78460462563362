import * as Sentry from "@sentry/react"
import React from "react"
import {
	createBrowserRouter,
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from "react-router-dom"

if (import.meta.env.MODE === "production") {
	Sentry.init({
		dsn: import.meta.env.PUBLIC_SENTRY_DSN,
		integrations: [
			Sentry.reactRouterV6BrowserTracingIntegration({
				useEffect: React.useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes,
			}),
			Sentry.replayIntegration(),
		],
		tracesSampleRate:
			Number.parseFloat(import.meta.env.PUBLIC_SENTRY_TRACES_SAMPLE_RATE) ||
			0.0,
		replaysSessionSampleRate:
			Number.parseFloat(import.meta.env.PUBLIC_SENTRY_REPLAYS_SAMPLE_RATE) ||
			0.0,
	})
}

export const sentryCreateBrowserRouter =
	Sentry.wrapCreateBrowserRouter(createBrowserRouter)
