import { useGetPage, useGetPageContent } from "@/api/queries/useGetPage.ts"
import { ContentWrapper } from "@/features/learning_content/ContentWrapper.tsx"
import { FetchingError } from "@/shared/loading/FetchingError.tsx"
import { GlobalLoader } from "@/shared/loading/GlobalLoader.tsx"
import { createMarkup } from "@/shared/utils/index.ts"
import { View } from "@instructure/ui"
import { sanitize } from "dompurify"
import { useParams } from "react-router-dom"

export const Page = () => {
	const { courseId = "", pageId = "" } = useParams()

	const pageQuery = useGetPage({ courseId, pageId })
	const pageContentQuery = useGetPageContent({
		courseId,
		pageId,
	})

	if (pageQuery.isLoading || pageContentQuery.isLoading) {
		return <GlobalLoader title="Loading..." />
	}

	if (
		pageQuery.isError ||
		!pageQuery.data ||
		pageContentQuery.isError ||
		!pageContentQuery.data
	) {
		return <FetchingError />
	}

	const markup = createMarkup(pageContentQuery.data.body)

	return (
		<ContentWrapper
			name={pageQuery.data.title}
			type="Page"
			programName={pageQuery.data.courseName}
			estimatedTime="20 Mins"
			courseId={courseId}
			learningAssistContext={sanitize(pageContentQuery.data.body)}
		>
			<View as="div" margin="medium">
				{/* biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation> */}
				<div dangerouslySetInnerHTML={markup} />
			</View>
		</ContentWrapper>
	)
}
