import debounce from "@instructure/debounce"
import { IconSearchLine, ScreenReaderContent, TextInput } from "@instructure/ui"
import { useCallback, useEffect, useState } from "react"

type DebouncedTextFieldProps = {
	value: string
	setValue: (value: string) => void
	wait?: number
	leading?: boolean
	trailing?: boolean
	placeholder?: string
	type?: "text" | "email" | "url" | "tel" | "search" | "password"
}

export const DebouncedTextField = ({
	value,
	setValue,
	wait = 350,
	leading = false,
	trailing = true,
	placeholder = "Search...",
	type = "search",
}: DebouncedTextFieldProps) => {
	const [textFieldValue, setTextFieldValue] = useState<string>(value)
	const updateValue: (query: string) => void = useCallback(
		debounce(
			(value: string) => {
				setValue(value)
			},
			wait,
			{ leading, trailing },
		),
		[],
	)

	useEffect(() => {
		updateValue(textFieldValue)
	}, [textFieldValue, updateValue])

	return (
		<TextInput
			renderLabel={<ScreenReaderContent>Search</ScreenReaderContent>}
			type={type}
			placeholder={placeholder}
			shouldNotWrap
			value={textFieldValue}
			renderBeforeInput={IconSearchLine}
			onChange={(e) => setTextFieldValue(e.target.value)}
		/>
	)
}
