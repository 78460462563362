import {
	type Dispatch,
	type ReactNode,
	createContext,
	useContext,
	useReducer,
} from "react"

export interface SelectContext<T, K> {
	inputValue: string
	isShowingOptions: boolean
	filteredOptionIds: Set<K>
	highlightedOptionId: K | null
	selectedOptions: T[]
}

export enum actions {
	SET_IS_SHOWING_OPTIONS = "SET_IS_SHOWING_OPTIONS",
	SET_HIGHLIGHTED_OPTION = "SET_HIGHLIGHTED_OPTION",
	OPTIONS_CHANGED = "OPTIONS_CHANGED",
	SELECT_OPTION = "SELECT_OPTION",
	REMOVE_SELECTED = "REMOVE_SELECTED",
	INPUT_CHANGED = "INPUT_CHANGED",
}

type SetIsShowingOptionsAction = {
	type: actions.SET_IS_SHOWING_OPTIONS
	payload: boolean
}
type SetHighlightedOptionAction<K> = {
	type: actions.SET_HIGHLIGHTED_OPTION
	payload: K | null
}

type OptionsChangedAction<K> = {
	type: actions.OPTIONS_CHANGED
	payload: Set<K>
}

type SelectOptionAction<T, K> = {
	type: actions.SELECT_OPTION
	payload: Pick<
		SelectContext<T, K>,
		"inputValue" | "selectedOptions" | "filteredOptionIds"
	>
}

type RemoveSelectedAction<T> = {
	type: actions.REMOVE_SELECTED
	payload: T[]
}

type InputChangedAction<T, K> = {
	type: actions.INPUT_CHANGED
	payload: Pick<
		SelectContext<T, K>,
		| "inputValue"
		| "highlightedOptionId"
		| "filteredOptionIds"
		| "isShowingOptions"
	>
}

// Union of all actions
type SelectContextAction<T, K> =
	| SetIsShowingOptionsAction
	| SetHighlightedOptionAction<K>
	| OptionsChangedAction<K>
	| SelectOptionAction<T, K>
	| RemoveSelectedAction<T>
	| InputChangedAction<T, K>

const reducer = <T, K>(
	state: SelectContext<T, K>,
	action: SelectContextAction<T, K>,
): SelectContext<T, K> => {
	switch (action.type) {
		case actions.SET_IS_SHOWING_OPTIONS:
			return { ...state, isShowingOptions: action.payload }
		case actions.SET_HIGHLIGHTED_OPTION:
			return { ...state, highlightedOptionId: action.payload }
		case actions.OPTIONS_CHANGED:
			return { ...state, filteredOptionIds: action.payload }
		case actions.SELECT_OPTION:
			return {
				...state,
				isShowingOptions: false,
				highlightedOptionId: null,
				...action.payload,
			}
		case actions.INPUT_CHANGED:
			return {
				...state,
				...action.payload,
			}
		case actions.REMOVE_SELECTED:
			return {
				...state,
				selectedOptions: action.payload,
				highlightedOptionId: null,
			}
		default:
			return state
	}
}

// biome-ignore lint/suspicious/noExplicitAny:
const SelectContext = createContext<SelectContext<any, any>>({
	inputValue: "",
	isShowingOptions: false,
	filteredOptionIds: new Set(),
	highlightedOptionId: null,
	selectedOptions: [],
})

const SelectContextDispatch = createContext<
	// biome-ignore lint/suspicious/noExplicitAny:
	Dispatch<SelectContextAction<any, any>>
>(() => {
	throw new Error(
		"SelectContextDispatch must be used within an SelectContextProvider",
	)
})

export const SelectContextProvider = <T, _K>({
	children,
	inputValue,
	initialSelectedOptions = [],
}: {
	children: ReactNode
	initialSelectedOptions: T[]
	inputValue: string
}) => {
	const [selectContext, dispatch] = useReducer(reducer, {
		inputValue,
		isShowingOptions: false,
		filteredOptionIds: new Set(),
		highlightedOptionId: null,
		selectedOptions: initialSelectedOptions,
	})
	return (
		<SelectContext.Provider value={selectContext}>
			<SelectContextDispatch.Provider value={dispatch}>
				{children}
			</SelectContextDispatch.Provider>
		</SelectContext.Provider>
	)
}

export const useSelectContext = () => {
	return useContext(SelectContext)
}

export const useSelectContextDispatch = () => {
	return useContext(SelectContextDispatch)
}
