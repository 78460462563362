import { useGetQuiz } from "@/api/queries/useGetQuiz.ts"
import { useGetSessionlessLaunchUrl } from "@/api/queries/useGetSessionlessLaunchUrl.ts"
import { ContentWrapper } from "@/features/learning_content/ContentWrapper.tsx"
import { FetchingError } from "@/shared/loading/FetchingError.tsx"
import { GlobalLoader } from "@/shared/loading/GlobalLoader.tsx"
import { formatDate } from "@/shared/utils"
import { View } from "@instructure/ui"
import { useParams } from "react-router-dom"

export const Quiz = () => {
	const { courseId = "", assignmentId = "" } = useParams()
	const quizQuery = useGetQuiz({ courseId, assignmentId })
	const sessionlessLaunchUrlQuery = useGetSessionlessLaunchUrl({
		courseId,
		assignmentId,
	})

	if (quizQuery.isLoading || sessionlessLaunchUrlQuery.isLoading) {
		return <GlobalLoader title="Loading..." />
	}

	if (
		quizQuery.isError ||
		!quizQuery.data ||
		sessionlessLaunchUrlQuery.isError ||
		!sessionlessLaunchUrlQuery.data
	) {
		return <FetchingError />
	}

	if (!quizQuery.data.is_quiz_lti_assignment) {
		console.error("This is not a new quiz")
		return <FetchingError />
	}

	return (
		<ContentWrapper
			name={quizQuery.data.name}
			type="Quiz"
			programName="Program Name"
			estimatedTime="20 Mins"
			courseId={courseId}
			dueAt={
				quizQuery.data.due_at ? formatDate(quizQuery.data.due_at) : undefined
			}
		>
			<View as="div" height="100%" overflowY="hidden">
				<iframe
					title={quizQuery.data.name}
					src={sessionlessLaunchUrlQuery.data.url}
					style={{
						border: "none",
						width: "100%",
						height: "100%",
					}}
				/>
			</View>
		</ContentWrapper>
	)
}
