import { MOBILE_MENU_HEIGHT } from "@/constants.ts"
import { DesktopNavigationWrapper } from "@/features/navigation/DesktopNavigationWrapper.tsx"
import { MobileNavigationWrapper } from "@/features/navigation/MobileNavigationWrapper.tsx"
import { NavigationItem } from "@/features/navigation/NavigationItem.tsx"
import {
	HOME,
	LEARN,
	NAVIGATION_ITEMS,
	NAVIGATION_ITEMS_WITH_AI,
} from "@/features/navigation/constants.ts"
import { useLayoutEffect, useRef } from "react"
import { useLocation } from "react-router-dom"
import {
	actions,
	useAppContext,
	useAppContextDispatch,
} from "../../AppContext.tsx"

const getLocationBasedNavItems = (location: Location) => {
	if (
		location.pathname === HOME.route ||
		location.pathname.includes(LEARN.route as string)
	) {
		return NAVIGATION_ITEMS_WITH_AI
	}

	return NAVIGATION_ITEMS
}

const NavBlock = ({
	isDesktop,
}: {
	isDesktop?: boolean
}) => {
	const location = useLocation()
	const locationBasedItems = getLocationBasedNavItems(
		location as unknown as Location,
	)
	const currentNavItems = locationBasedItems.filter(
		(item) => (isDesktop && !item.mobileOnly) || !isDesktop,
	)

	return currentNavItems.map((item) => (
		<NavigationItem
			key={item.icon}
			{...item}
			overrideActive={item.overrideActive?.(location as unknown as Location)}
		/>
	))
}

export const MainNavigation = () => {
	const dispatch = useAppContextDispatch()
	const { desktopMode, maxHeight } = useAppContext()

	const ref = useRef<HTMLDivElement>(null)

	const updateView = () => {
		const clientHeight = ref?.current?.clientHeight ?? 0
		dispatch({
			type: actions.SET_DESKTOP_MODE,
			payload: (ref?.current?.clientWidth ?? 0) >= 1440,
		})
		dispatch({
			type: actions.SET_APPLICATION_HEIGHTS,
			payload: {
				maxHeight: clientHeight,
				mobileContentHeight: clientHeight - MOBILE_MENU_HEIGHT,
			},
		})
	}
	// biome-ignore lint/correctness/useExhaustiveDependencies: run once on mount
	useLayoutEffect(() => {
		updateView()
		window.addEventListener("resize", updateView)
		return () => {
			window.removeEventListener("resize", updateView)
		}
	}, [])

	return (
		<div ref={ref} style={{ height: "100vh" }}>
			{desktopMode && (
				<DesktopNavigationWrapper>
					<NavBlock isDesktop={true} />
				</DesktopNavigationWrapper>
			)}
			{!desktopMode && (
				<MobileNavigationWrapper maxHeight={maxHeight}>
					<NavBlock isDesktop={false} />
				</MobileNavigationWrapper>
			)}
		</div>
	)
}
